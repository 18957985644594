import React, { useEffect, useState } from 'react'
import {
  Button,
  Select,
  Upload,
  message,
  Row,
  Col,
  Divider,
  Tabs,
  Tag
} from 'antd'
import {
  UploadOutlined,
  FormOutlined,
  PictureOutlined,
  DatabaseOutlined
} from '@ant-design/icons'

import './CharacterSelection.scss'
import _ from 'lodash'

const { Option } = Select
const { Dragger } = Upload
const { TabPane } = Tabs

// Mock data for existing characters - replace with your actual character data
const existingCharacters = [
  {
    id: 1,
    name: 'Warrior Princess',
    image: 'https://via.placeholder.com/300x400',
    tags: ['strong', 'leader', 'medieval'],
    characterType: 'predefined',
    prompt:
      'A brave warrior princess in medieval armor, standing confidently on a battlefield.'
  },
  {
    id: 2,
    name: 'Cyber Hacker',
    image: 'https://via.placeholder.com/300x400',
    tags: ['tech', 'urban', 'mysterious'],
    characterType: 'predefined',
    prompt:
      'A skilled hacker in a neon-lit cyberpunk city, working on advanced technology.'
  }
]

const SelectCharacter = ({ formData, setFormData, onValidationChange }) => {
  const [activeTab, setActiveTab] = useState('customize')
  const [selectedCharacter, setSelectedCharacter] = useState(null)
  const [image, setImage] = useState(null)

  useEffect(() => {
    const { characters } = formData

    if (!_.isEmpty(characters)) {
      const { type, data } = characters

      if (type === 'existing') {
        setSelectedCharacter(data)
      } else if (type === 'upload') {
        setImage(data.image)
      } else if (type === 'customize') {
        setCharacterParams(data.params)
        setGeneratedCharacterTags(data.tags)
      }

      setActiveTab(type)
    }
  }, [formData])

  const initialCharacterParams = {
    gender: '',
    age: '',
    appearance: '',
    attire: '',
    height: '',
    eyeColor: '',
    hairColor: '',
    skinTone: '',
    race: ''
  }

  const [characterParams, setCharacterParams] = useState(initialCharacterParams)
  const [generatedCharacterTags, setGeneratedCharacterTags] = useState([])

  // Handle changes for each parameter
  const handleParamChange = (param, value) => {
    setCharacterParams(prevState => ({
      ...prevState,
      [param]: value
    }))

    // Generate tags based on selections
    const newTags = generateCharacterTags(param, value)
    setGeneratedCharacterTags(prev => {
      // Remove existing tag for this parameter and add new one
      const filteredTags = prev.filter(tag => tag.param !== param)
      return [...filteredTags, ...newTags]
    })
  }

  const generateCharacterTags = (param, value) => {
    const tagMappings = {
      gender: { color: 'purple' },
      age: { color: 'blue' },
      appearance: { color: 'green' },
      attire: { color: 'orange' },
      height: { color: 'magenta' },
      eyeColor: { color: 'cyan' },
      hairColor: { color: 'geekblue' },
      skinTone: { color: 'volcano' },
      race: { color: 'lime' }
    }

    return tagMappings[param]
      ? [
          {
            label: value,
            color: tagMappings[param].color,
            param: param
          }
        ]
      : []
  }

  const handleImageUpload = file => {
    const reader = new FileReader()
    reader.onloadend = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(file)
    return false // Prevent auto upload
  }

  const capitaliseFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const handleSubmit = () => {
    // Check submission based on active tab
    switch (activeTab) {
      case 'customize':
        if (Object.values(characterParams).includes('')) {
          message.error('Please select all character parameters!')
          return
        }
        break
      case 'upload':
        if (!image) {
          message.error('Please upload an image!')
          return
        }
        break
      case 'existing':
        if (!selectedCharacter) {
          message.error('Please select an existing character!')
          return
        }
        break
    }

    const submissionData = {
      type: activeTab,
      data:
        activeTab === 'customize'
          ? {
              params: characterParams,
              tags: generatedCharacterTags.map(tag => tag.label),
              image: image
            }
          : activeTab === 'upload'
          ? { image }
          : selectedCharacter
    }

    setFormData(prevFormData => ({
      ...prevFormData,
      characters: {
        type: submissionData.type,
        data: submissionData.data
      }
    }))

    message.success('Character successfully selected!')
    onValidationChange(prev => ({ ...prev, characterSelection: true }))
  }

  const characterOptions = [
    {
      label: 'Gender',
      name: 'gender',
      options: ['male', 'female']
    },
    {
      label: 'Age',
      name: 'age',
      options: [
        'infant',
        'child',
        'teenager',
        'young adult',
        'middle-aged',
        'elderly'
      ]
    },
    {
      label: 'Appearance',
      name: 'appearance',
      options: [
        'freckled',
        'scarred',
        'tattooed',
        'plain',
        'distinguished',
        'wrinkled',
        'blemished'
      ]
    },
    {
      label: 'Attire',
      name: 'attire',
      options: [
        'casual',
        'formal',
        'sportswear',
        'traditional attire',
        'uniform',
        'costume',
        'vintage'
      ]
    },
    {
      label: 'Height',
      name: 'height',
      options: ['very short', 'short', 'average height', 'tall', 'very tall']
    },
    {
      label: 'Eye Color',
      name: 'eyeColor',
      options: ['blue', 'green', 'brown', 'gray', 'hazel', 'amber', 'violet']
    },
    {
      label: 'Hair Color',
      name: 'hairColor',
      options: [
        'blonde',
        'brown',
        'black',
        'red',
        'gray',
        'white',
        'unnatural (pink, blue, etc.)'
      ]
    },
    {
      label: 'Hair Style',
      name: 'hairStyle',
      options: [
        'buzz cut',
        'bob cut',
        'braided',
        'ponytail',
        'curly',
        'straight',
        'wavy',
        'bald'
      ]
    },
    {
      label: 'Skin Tone',
      name: 'skinTone',
      options: ['light', 'fair', 'medium', 'olive', 'tan', 'brown', 'dark']
    },
    {
      label: 'Race',
      name: 'race',
      options: [
        'indian',
        'african',
        'caucasian',
        'asian',
        'hispanic',
        'native american',
        'mixed heritage'
      ]
    }
  ]

  console.log(
    'characterParams',
    characterParams,
    'activeTab',
    activeTab,
    'image',
    image
  )

  return (
    <div className="select-character-container">
      <div className="header">
        <h1>Select Character</h1>
        <p>Choose how you want to create your character</p>
      </div>

      <Tabs activeKey={activeTab} onChange={setActiveTab} centered>
        <TabPane
          tab={
            <span>
              <FormOutlined />
              Custom Character
            </span>
          }
          key="customize"
        >
          <div className="character-settings">
            <h3>Customize Character</h3>
            <p>Select character parameters to customize your character</p>

            <Divider />
            <Row gutter={[16, 16]}>
              {characterOptions.map(param => (
                <Col key={param.name} xs={24} sm={12} md={8} lg={6}>
                  <div className="select-container">
                    <div className="ant-form-item-label">
                      <label>{param.label}</label>
                    </div>
                    <Select
                      placeholder={`Select ${param.label}`}
                      value={characterParams[param.name]}
                      onChange={value => handleParamChange(param.name, value)}
                      style={{ width: '100%' }}
                    >
                      {param.options.map(option => (
                        <Option key={option} value={option}>
                          {capitaliseFirstLetter(option)}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              ))}
            </Row>

            {generatedCharacterTags.length > 0 && (
              <>
                <h3 className="selected-params">
                  Selected Character Parameters
                </h3>

                {generatedCharacterTags.map((tag, index) => (
                  <Tag
                    key={index}
                    color={tag.color}
                    style={{
                      fontSize: '14px',
                      padding: '5px 10px',
                      margin: '5px',
                      borderRadius: '4px'
                    }}
                  >
                    {capitaliseFirstLetter(tag.param)} -{' '}
                    {capitaliseFirstLetter(tag.label)}
                  </Tag>
                ))}
              </>
            )}
          </div>
        </TabPane>

        <TabPane
          tab={
            <span>
              <UploadOutlined />
              Upload Image
            </span>
          }
          key="upload"
        >
          <div className="upload-image">
            <h3>Upload an Image Reference</h3>
            <div>
              <Dragger
                beforeUpload={handleImageUpload}
                showUploadList={false}
                accept="image/*"
              >
                <div className="upload-image-button">
                  <UploadOutlined />
                  <span>Upload Image</span>
                </div>
              </Dragger>

              {image && (
                <img
                  src={image}
                  alt="Character Preview"
                  className="character-image-preview"
                />
              )}
            </div>
          </div>
        </TabPane>

        <TabPane
          tab={
            <span>
              <DatabaseOutlined />
              Existing Characters
            </span>
          }
          key="existing"
        >
          <div className="existing-characters">
            <h3>Select from Existing Characters</h3>
            <Row gutter={[16, 16]}>
              {existingCharacters.map(character => (
                <Col key={character.id} xs={24} sm={12} md={8}>
                  <div
                    className={`character-card ${
                      selectedCharacter && selectedCharacter.id === character.id
                        ? 'selected'
                        : ''
                    }`}
                    onClick={() => setSelectedCharacter(character)}
                  >
                    <img
                      src={character.image}
                      alt={character.name}
                      className="character-image"
                    />
                    <div className="character-details">
                      <h4>{character.name}</h4>
                      <div className="character-tags">
                        {character.tags.map(tag => (
                          <span key={tag} className="tag">
                            {tag}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </TabPane>
      </Tabs>

      <div className="action-buttons">
        <Button onClick={handleSubmit} type="primary" size="large">
          Confirm Selection
        </Button>
      </div>
    </div>
  )
}

export default SelectCharacter
