import React from 'react'
import { Button, Col, Result, Row } from 'antd'
import { withRouter } from 'react-router-dom'
import NewHeader from '../../../../components/NewHeader'

const Unauthorized = ({ history, header = true, button = true }) => {
  const handleRedirect = () => {
    // Redirect to the /training page
    history.push('/training')
  }

  return (
    <div style={{ padding: '3rem' }}>
      {header && (
        <Row>
          <Col span={16} />
          <Col span={8}>
            <NewHeader />
          </Col>
        </Row>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 'calc(100vh - 23rem)'
        }}
      >
        <Result
          status="403"
          title="Access Denied !"
          subTitle="Sorry, you are not authorized to access the listings on this page. Please contact your organization administrator."
          extra={
            button ? (
              <Button type="primary" onClick={handleRedirect}>
                Go to Training Page
              </Button>
            ) : null
          }
        />
      </div>
    </div>
  )
}

export default withRouter(Unauthorized)
