import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button, Divider, Modal, Result, message } from 'antd'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import actions from '../../../../../../../../store/actions'

import createLayoutData from './layoutData'

const LayoutOptions = props => {
  const [selectedLayout, setSelectedLayout] = useState(null)
  const [showLayoutModal, setShowLayoutModal] = useState({
    visible: false,
    layoutStyle: null
  })

  const { currentPage, pageStructureData, lessonUpdate, isUpdating } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const getCurrentPageTextData = (currentPageData = {}) => {
    const pageData = currentPageData.data

    if (pageData) {
      const totalElements = pageData.filter(
        item =>
          (item.display === 'CONTENT' || item.display === 'SUB_CONTENT') &&
          item.type === 'TEXT'
      )

      if (totalElements.length > 1) {
        const combinedText = totalElements
          .map(item => item.params.content)
          .join(' ')
        return combinedText
      }

      return (totalElements[0] && totalElements[0].params.content) || ''
    }

    return ''
  }

  const getCurrentPageImage = (currentPageData = {}) => {
    const pageData = currentPageData.data

    if (pageData) {
      const imageItem = pageData.filter(
        item =>
          (item.display === 'IMAGE' || item.display === 'CONTENT') &&
          item.type === 'IMAGE'
      )

      return (imageItem[0] && imageItem[0].params.content) || ''
    }

    return ''
  }

  function splitParagraph(paragraph) {
    if (!paragraph) return ['', '']
    const sentences = paragraph.split(/(?<=\.|\?|\!)\s/)
    const midpoint = Math.ceil(sentences.length / 2)
    const firstHalf = sentences.slice(0, midpoint).join(' ') || ''
    const secondHalf = sentences.slice(midpoint).join(' ') || ''
    return [firstHalf, secondHalf]
  }

  useEffect(() => {
    const currentPageData = pageStructureData[currentPage - 1]
    if (!currentPageData || !selectedLayout) return

    const textData = getCurrentPageTextData(currentPageData)
    const [firstPart, secondPart] = splitParagraph(textData)
    const existingHeading = currentPageData.data.find(
      item => item.display === 'HEADING'
    )
    const imageData = getCurrentPageImage(currentPageData)
    const logoData = getCurrentPageLogo(currentPageData)

    const layoutArray = createLayoutData(
      textData,
      firstPart,
      secondPart,
      imageData,
      logoData
    )
    const selectedLayoutData = layoutArray.find(
      layout => layout.layoutStyle === selectedLayout
    )

    if (!selectedLayoutData) return

    let updatedPageData = {
      ...currentPageData,
      ...selectedLayoutData.generateLayout()
    }

    // Update heading content if it exists
    if (existingHeading && updatedPageData.data[0].display === 'HEADING') {
      updatedPageData.data[0].params.content = existingHeading.params.content
    }

    const updatedData = [...pageStructureData]
    updatedData[currentPage - 1] = updatedPageData

    const { handleLessonInput } = actions.lessonBuilder
    dispatch(handleLessonInput('pages_landscape', updatedData))
    lessonUpdate({ pages_landScape: updatedData })
  }, [selectedLayout])

  const handleSelectLayout = layout => {
    if (isUpdating) return

    const currentPageData = pageStructureData[currentPage - 1]
    const pageHasScenario =
      currentPageData &&
      currentPageData.data.some(node => node.type === 'SCENARIO')

    if (pageHasScenario) {
      message.error(
        'You cannot change layout in a scenario page. Please try other pages or remove the scenario from the page first.',
        10
      )
      return
    }

    setShowLayoutModal({
      visible: true,
      layoutStyle: layout
    })
  }

  const onConfirmLayoutChange = () => {
    setSelectedLayout(showLayoutModal.layoutStyle)
    setShowLayoutModal(pre => ({
      ...pre,
      visible: false
    }))
  }

  const getCurrentPageLogo = (currentPageData = {}) => {
    const pageData = currentPageData.data

    if (pageData) {
      const imageItem = pageData.filter(
        item => item.display === 'LOGO' && item.type === 'IMAGE'
      )

      return (imageItem[0] && imageItem[0].params) || {}
    }

    return {}
  }

  const {
    textData,
    firstPart,
    secondPart,
    imageData,
    currentPageLogo
  } = useMemo(() => {
    const textData = getCurrentPageTextData(pageStructureData[currentPage - 1])
    const [firstPart, secondPart] = splitParagraph(textData)
    const imageData = getCurrentPageImage(pageStructureData[currentPage - 1])
    const currentPageLogo = getCurrentPageLogo(
      pageStructureData[currentPage - 1]
    )

    return {
      textData,
      firstPart,
      secondPart,
      imageData,
      currentPageLogo
    }
  }, [pageStructureData, currentPage])

  console.log({
    textData,
    firstPart,
    secondPart,
    imageData
  })
  const layoutData = createLayoutData(
    textData,
    firstPart,
    secondPart,
    imageData,
    currentPageLogo
  )

  return (
    <div className="layout-options-container">
      <Divider orientation="left" className="divider-title">
        {t('v3:layout')}
      </Divider>

      <div className="layout-options">
        {layoutData.map((layout, index) => (
          <img
            key={index}
            className={`${
              selectedLayout === layout.layoutStyle ? 'layout-selected' : ''
            } layout-img`}
            src={layout.img}
            alt=""
            onClick={() => handleSelectLayout(layout.layoutStyle)}
          />
        ))}
      </div>

      <Modal
        visible={showLayoutModal.visible}
        onCancel={() =>
          setShowLayoutModal({ visible: false, layoutStyle: null })
        }
        footer={null}
        centered
      >
        <Result
          status="warning"
          title={t('v4:warning')}
          subTitle={t('v4:change_layout_page')}
          extra={[
            <Button
              key="back"
              onClick={() =>
                setShowLayoutModal({ visible: false, layoutStyle: null })
              }
            >
              {t('buttons:canncel')}
            </Button>,
            <Button type="primary" key="submit" onClick={onConfirmLayoutChange}>
              {t('v3:confirm_change')}
            </Button>
          ]}
        >
          <Alert type="info" message={t('v4:change_layout_message')} />
        </Result>
      </Modal>
    </div>
  )
}

export default LayoutOptions
