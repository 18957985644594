// VideoStyle.js
import React, { useEffect, useState } from 'react'
import { Card } from 'antd'
import CinematicIcon from '../../../../assets/video-cinematic.svg'
import AnimeIcon from '../../../../assets/video-anime.svg'
import ComicBookIcon from '../../../../assets/video-comic.svg'
import OrigamiIcon from '../../../../assets/video-origami.svg'
import SportIcon from '../../../../assets/video-sports.svg'
import StandardIcon from '../../../../assets/video-standard.svg'

import './VideoStyle.scss'

const { Meta } = Card

const videoStyles = [
  {
    key: 'cinematic',
    title: 'Cinematic',
    description: 'Photorealistic style with natural lighting and textures',
    icon: CinematicIcon
  },
  {
    key: 'comicBook',
    title: 'Comic Book',
    description: 'Bold colors, strong outlines, and dramatic shading',
    icon: ComicBookIcon
  },
  {
    key: 'anime',
    title: 'Anime',
    description: 'Japanese animation style with vibrant colors',
    icon: AnimeIcon
  },
  {
    key: 'origami',
    title: 'Origami',
    description: 'Futuristic cyberpunk aesthetic with neon elements',
    icon: OrigamiIcon
  },
  {
    key: 'sport',
    title: 'Sport',
    description: 'Vintage look with grain and classic color palettes',
    icon: SportIcon
  },
  {
    key: 'standard',
    title: 'Standard',
    description: 'Vintage look with grain and classic color palettes',
    icon: StandardIcon
  }
]

const VideoStyle = ({ setFormData, formData, onValidationChange }) => {
  const [selectedStyle, setSelectedStyle] = useState(null)

  useEffect(() => {
    // If formData has a videoStyle, set it as the selected style
    if (formData && formData.videoStyle) {
      setSelectedStyle(formData.videoStyle.key)
    }
  }, [formData])

  const handleStyleSelect = key => {
    setSelectedStyle(key)
    // Update formData with the new video style selection
    const selectedStyleObj = videoStyles.find(style => style.key === key)
    setFormData(prev => ({
      ...prev,
      videoStyle: selectedStyleObj
    }))
    onValidationChange(prev => ({ ...prev, videoStyle: true }))
  }

  return (
    <div className="video-style-selector">
      <div className="header">
        <h1>Video Style</h1>
        <p>Choose the style for your video.</p>
      </div>
      <div className="shot-list-cards">
        {videoStyles.map(style => (
          <Card
            key={style.key}
            className={`shot-list-card ${
              selectedStyle === style.key ? 'selected' : ''
            }`}
            onClick={() => handleStyleSelect(style.key)}
            cover={
              <div
                className="shot-list-card-image"
                style={{ backgroundColor: '#f0f0f0' }}
              >
                <img src={style.icon} alt={style.title} />
              </div>
            }
          >
            <Meta title={style.title} description={style.description} />
          </Card>
        ))}
      </div>
    </div>
  )
}

export default VideoStyle
