import React, { useMemo, useState } from 'react'
import { withRouter } from 'react-router'
import { Item, HelpItem } from './internal'
import { Nav } from './styled'

import {
  courses,
  events,
  reports,
  group,
  organisations,
  users,
  workshop,
  help,
  nowLive,
  courseAiImage,
  nav_trivia,
  category,
  logo,
  logoCollapsed,
  knowledge_center,
  icon,
  iconLeft,
  marketPlace,
  LearningCenter,
  RolesAndPermissionIcon
} from './images'
import { config } from '../../constants'

import BrainIcon from '../../assets/brain.svg'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ReactSVG from 'react-svg'
import './style.scss'
import history from '../../history'

const Navigation = props => {
  const { t, isKnowledgeCenter, match, isUser } = props
  const isManagementMode = match.path.includes('/management')
  const [isSidebarExpanded, setSidebarExpanded] = useState(false)
  const [tooltipVisible, setTooltipVisible] = useState(null)

  const user = useSelector(state => state.user)
  const organizations = useSelector(state => state.organizations.list)

  const { headerLogo, headerColor } = useMemo(() => {
    const activeOrgInfo =
      organizations &&
      organizations.rows &&
      organizations.rows.find(
        org => user && user.info.active_organization_id === org.id
      )
    const isOrgWhiteLabelEnabeld =
      activeOrgInfo && activeOrgInfo.enable_white_label

    const orgHasWhiteLabelData =
      isOrgWhiteLabelEnabeld && activeOrgInfo && activeOrgInfo.white_label_data
    const headerInfo = orgHasWhiteLabelData && orgHasWhiteLabelData.header
    const headerColor = headerInfo && headerInfo.color
    const headerLogo = headerInfo && headerInfo.logo && headerInfo.logo.link

    const languageSelect = document.getElementById('language_select')

    const selectElement =
      languageSelect && languageSelect.querySelector('.ant-select-selection')

    if (selectElement && headerColor)
      selectElement.style.backgroundColor = headerColor
    else if (selectElement && !headerColor)
      selectElement.style.backgroundColor = '#333333'

    return { headerLogo, headerColor }
  }, [user, organizations])

  const toggleSidebar = () => {
    setSidebarExpanded(!isSidebarExpanded)
    const asideElement = document.querySelector('aside')
    if (asideElement) {
      asideElement.classList.toggle('ssl-open-nav')
    }
  }

  const showTooltip = index => {
    setTooltipVisible(index)
  }

  const hideTooltip = () => {
    setTooltipVisible(null)
  }

  const handleNavItemClick = () => {
    setSidebarExpanded(false)
    const asideElement = document.querySelector('aside')
    if (asideElement) {
      asideElement.classList.remove('ssl-open-nav')
    }
  }

  const getAccessInfos = () => {
    const accessKeys = ['refreshToken', 'accessToken', 'idToken']
    let accessObj = {}
    for (let key in accessKeys) {
      const item = accessKeys[key]
      const accessValue = localStorage.getItem(item)
      accessObj[item] = accessValue
    }
    return accessObj
  }

  const goToMarketplace = e => {
    const { accessToken, refreshToken, idToken } = getAccessInfos()
    const marketplaceUrl = `http://${config.cookieDomain}?accessToken=${accessToken}&refreshToken=${refreshToken}&idToken=${idToken}`
    window.open(marketplaceUrl, '_blank').focus()
  }

  const renderNavItems = isManagementMode => {
    const info = user && user.info

    const enable_ai_course =
      info && info.organization && info.organization.enable_ai_course

    const enable_marketplace = info
      ? !info.active_organization_id
        ? false
        : info.id === 630
        ? false
        : info.organization && info.organization.is_marketplace_enabled
      : false

    const enable_sharesphere =
      info &&
      info.active_organization_id &&
      info.organization &&
      info.organization.enable_sharesphere

    let items = [
      {
        url: '/training',
        exact: true,
        icon: courses,
        heading: t('v4:training')
      },
      // {
      //   url: '/share-sphere',
      //   exact: true,
      //   icon: nav_trivia,
      //   heading: t('v3:share_sphere')
      // },
      ...(enable_sharesphere
        ? [
            {
              url: '/share-sphere',
              exact: true,
              icon: nav_trivia,
              heading: t('v3:share_sphere')
            }
          ]
        : []),
      {
        url: '/library/list',
        exact: true,
        icon: events,
        heading: t('buttons:from_library')
      },
      {
        url: '/workshop',
        exact: true,
        icon: workshop,
        heading: t('workshop')
      },
      {
        url: '/reports',
        exact: true,
        icon: reports,
        heading: t('reports')
      },
      {
        url: '/events',
        exact: true,
        icon: nowLive,
        heading: t('nowLive')
      },
      {
        url: '/knowledge-center',
        exact: true,
        icon: knowledge_center,
        heading: t('v3:knowledge_center')
      }
    ]
    if (enable_marketplace) {
      items.push({
        url: '#',
        icon: marketPlace,
        heading: t('v3:go_to_marketplace'),
        onClick: goToMarketplace
      })
    }

    const learningCenterRoute = {
      url: '/training',
      exact: true,
      icon: LearningCenter,
      heading: t('v3:learning_center')
    }

    const knowledgeCenterItems = [
      {
        url: '/knowledge-center',
        exact: true,
        icon: BrainIcon,
        heading: t('v3:knowledge_center')
      },
      ...(enable_ai_course
        ? [
            {
              url: '/knowledge-center/course-ai',
              exact: true,
              icon: courseAiImage,
              heading: t('v4:ai_course')
            }
          ]
        : []),
      {
        url: '/knowledge-center/library/list',
        exact: true,
        icon: events,
        heading: t('buttons:from_library')
      },
      learningCenterRoute
    ]

    const itemsManagement = [
      {
        url: '/management',
        exact: true,
        icon: organisations,
        heading: t('organisations')
      },
      {
        url: '/management/users',
        exact: true,
        icon: users,
        heading: t('users')
      },
      {
        url: '/management/groups',
        exact: true,
        icon: group,
        heading: t('groups')
      },
      {
        url: '/management/category',
        exact: true,
        icon: category,
        heading: t('v3:category')
      },
      {
        url: '/management/roles',
        exact: true,
        icon: RolesAndPermissionIcon,
        heading: t('v4:roles_and_permissions')
      }
    ]

    let navItems = []
    if (isManagementMode) {
      navItems = itemsManagement
    } else if (isKnowledgeCenter) {
      navItems = isUser ? [learningCenterRoute] : knowledgeCenterItems
    } else {
      // Find the index of the report item
      let reportIndex = items.findIndex(item => item.url === '/reports')
      // Find the index of the trivia item
      let index = items.findIndex(item => item.url === '/share-sphere')

      // Remove the reports item if it exists and is not active organization
      if (
        reportIndex !== -1 &&
        !info.active_organization_id &&
        !info.is_super_admin
      ) {
        items.splice(reportIndex, 1)
      }
      // Remove the trivia item if it exists and is not active organization
      if (
        index !== -1 &&
        !info.active_organization_id &&
        !info.is_super_admin
      ) {
        items.splice(index, 1)
      }
      navItems = items
    }

    return (
      <>
        {navItems.map((item, index) => (
          <Nav.Item
            key={index}
            onMouseEnter={() => showTooltip(index)}
            onMouseLeave={hideTooltip}
            onClick={item.onClick ? item.onClick : handleNavItemClick}
          >
            <div className="tooltip-wrapper">
              <Item
                url={item.url}
                icon={item.icon}
                heading={isSidebarExpanded ? item.heading : ''}
                exact={item.exact}
              />
              {tooltipVisible === index && !isSidebarExpanded && (
                <div className="tooltip">{item.heading}</div>
              )}
            </div>
          </Nav.Item>
        ))}
      </>
    )
  }
  const defaultHeaderColorBg = `linear-gradient(180deg, #698BF2 0%, #5057D5 100%)`

  console.log({ headerLogo, headerColor })

  return (
    <Nav>
      <div className="ssl-main-sidebar-wrap">
        <div
          className="sider-close-icon"
          onClick={toggleSidebar}
          style={{ background: headerColor || '#111926' }}
        >
          <ReactSVG src={isSidebarExpanded ? iconLeft : icon} />
        </div>
        <div className="main-header-logo">
          <a
            className="ssl-logo b-logo_white"
            onClick={() => history.push('/training')}
            style={{ cursor: 'pointer' }}
          >
            <div
              className={`ssl-logo-source ${
                isSidebarExpanded ? 'logo-expanded' : ''
              } ${headerLogo ? 'logo-no-gap' : ''} ${
                logoCollapsed && !isSidebarExpanded ? 'fix-width-logo' : ''
              }`}
            >
              {headerLogo ? (
                <img alt="" src={headerLogo} width="644" height="40" />
              ) : (
                <ReactSVG src={isSidebarExpanded ? logoCollapsed : logo} />
              )}
            </div>
          </a>
        </div>
        <Nav.List className="ssl-nav-item-menu">
          {renderNavItems(isManagementMode)}
        </Nav.List>
      </div>
      <div
        onMouseEnter={() => showTooltip('help')}
        onMouseLeave={hideTooltip}
        className="help-tooltip-wrapper"
      >
        <div className="tooltip-wrapper">
          <HelpItem
            url={'/help-center'}
            icon={help}
            heading={isSidebarExpanded ? t('help_center:menu:help_center') : ''}
            exact={'help'}
          />
          {tooltipVisible === 'help' && !isSidebarExpanded && (
            <div className="tooltip">{t('help_center:menu:help_center')}</div>
          )}
        </div>
      </div>
    </Nav>
  )
}

export default withRouter(withTranslation('navigation')(Navigation))
