import React, { useState, useCallback, useEffect } from 'react'
import { Upload, Icon, message, Modal, Button, Input } from 'antd'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import './UploadDocument.scss'
import StoryCreationForm from './StoryCreationForm'

const UploadDocument = ({ formData, onValidationChange, setFormData }) => {
  const [fileList, setFileList] = useState([])
  const [isStoryFormVisible, setIsStoryFormVisible] = useState(false)
  const [showStoryText, setShowStoryText] = useState(false)
  const [isDragActive, setIsDragActive] = useState(false)

  useEffect(() => {
    setFormData(prev => ({ ...prev, file: fileList[0] }))
  }, [fileList])

  useEffect(() => {
    const { file } = formData
    if (file) {
      setFileList([file])
    }
  }, [])

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        const newFileList = acceptedFiles.map(file => ({
          uid: file.name,
          name: file.name,
          status: 'done',
          url: URL.createObjectURL(file)
        }))
        setIsStoryFormVisible(true)
        setFileList(newFileList)
      }
    },
    [onValidationChange]
  )

  const handleRemove = file => {
    setFileList(prev => prev.filter(item => item.uid !== file.uid))
    if (fileList.length <= 1 && !formData.voiceoverText)
      onValidationChange(prev => ({ ...prev, uploadDocument: false }))
  }

  const handleCloseStoryForm = () => {
    setIsStoryFormVisible(false)
  }

  const handleAddStory = story => {
    setIsStoryFormVisible(false)
    setFormData(prev => ({ ...prev, voiceoverText: story }))
    onValidationChange(prev => ({ ...prev, uploadDocument: true }))
  }

  const openPDF = file => {
    if (file && file.url) {
      window.open(file.url, '_blank')
    } else {
      console.error('File URL not available')
    }
  }

  return (
    <div className="upload-document">
      <div className="upload-document__header">
        <h1>Upload Document</h1>
        <p>Please upload a PDF document with your video script.</p>
      </div>

      {fileList.length === 0 ? (
        <Dropzone
          className="upload-document__dropzone-container"
          onDrop={onDrop}
          accept="application/pdf"
          maxFiles={1}
          onDropRejected={() => message.error('Only one PDF file is allowed.')}
          onDragEnter={() => setIsDragActive(true)}
          onDragLeave={() => setIsDragActive(false)}
        >
          {({ getRootProps, getInputProps, isDragActive: isActive }) => (
            <div
              className={`upload-document__dropzone ${
                isActive ? 'active' : ''
              }`}
            >
              <div className="upload-document__upload-content">
                <Icon type="file-pdf" />
                <p className="upload-document__text">
                  {isActive
                    ? 'Drop your PDF here'
                    : 'Drag & drop your document here, or click to select'}
                </p>
                <p className="upload-document__hint">
                  Supported format: PDF (max 10MB)
                </p>
              </div>
            </div>
          )}
        </Dropzone>
      ) : (
        <div className="upload-document__file-preview">
          <div className="file-item">
            <Icon type="file-pdf" className="file-icon" />
            <span className="file-name" onClick={() => openPDF(fileList[0])}>
              {fileList[0].name}
            </span>
            <Button
              type="link"
              className="remove-button"
              onClick={() => handleRemove(fileList[0])}
            >
              <Icon type="delete" />
            </Button>
          </div>
        </div>
      )}

      {fileList.length > 0 && (
        <Button
          className="upload-document__button"
          type="primary"
          onClick={() => setIsStoryFormVisible(true)}
        >
          Generate Story
        </Button>
      )}

      {formData.voiceoverText && (
        <Button
          className="upload-document__button"
          type="primary"
          onClick={() => setShowStoryText(true)}
        >
          Edit Story
        </Button>
      )}

      {isStoryFormVisible && (
        <StoryCreationForm
          uploadedFile={fileList}
          onCancel={handleCloseStoryForm}
          onAddStory={handleAddStory}
        />
      )}

      {showStoryText && (
        <Modal
          title="Story"
          visible
          footer={null}
          onCancel={() => setShowStoryText(false)}
        >
          <Input.TextArea
            value={formData.voiceoverText}
            onChange={e =>
              setFormData(prev => ({ ...prev, voiceoverText: e.target.value }))
            }
            autoSize
          />
        </Modal>
      )}
    </div>
  )
}

UploadDocument.propTypes = {
  onValidationChange: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
}

export default UploadDocument
