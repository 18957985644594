import React, { useState, useEffect } from 'react'
import TriviaGame from './Game/Game'
import TriviaAddEdit from './AddEdit/AddEdit'
import TriviaList from './List/List'
import NewHeader from '../../components/NewHeader'
import './style.scss'
import Unauthorized from '../Training/View/pages/Unauthorized'

const Trivia = props => {
  const currentRoute = window.location.pathname.split('/').pop()
  const isEdit = /^\d+$/.test(currentRoute)

  const [isAccessError, setAccessError] = useState(false)

  const userOrg =
    props.user.info.active_organization_id && props.user.info.organization

  useEffect(() => {
    if (userOrg) {
      const isEnabled = userOrg.enable_share_sphere

      if (!isEnabled) {
        setAccessError(true)
      }
    }
  }, [])

  if (isAccessError) {
    return <Unauthorized />
  }

  return (
    <>
      <div className="share-sphere-head">
        <NewHeader />
      </div>
      <div>
        {currentRoute === 'add' && <TriviaAddEdit {...props} />}
        {isEdit && <TriviaAddEdit id={currentRoute} {...props} />}
        {currentRoute === 'list' && <TriviaList {...props} />}
        {currentRoute === 'share-sphere' && <TriviaGame {...props} />}
      </div>
    </>
  )
}

export default Trivia
