import React, { useEffect, useState } from 'react'
import { Switch, Router, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import actions from './store/actions'
import history from './history'
import { PrivateRoute, PublicRoute } from './components'
import {
  Reports,
  /* ReportsPage,
  SingleUserReport, */
  Management,
  Onboarding,
  Account,
  Workshop,
  Trivia,
  Library,
  Courses,
  Events,
  Assessments,
  Maze,
  Scorm,
  Training,
  Auth,
  Pages,
  NotFound,
  HelpCenter,
  SkillJourney,
  MyCalendar,
  Channel,
  DiscussionRoom,
  VideoAi
} from './views'

import ViewSkillJourney from './views/SkillJourney/View'
import { EventRecording } from './views/EventRecording'
import KnowledgeCenter from './views/KnowledgeCenter'
import MarketPlace from './views/MarketPlace'
import AiCourseCreation from './components/AiCourseCreation/index.'
import DeleteAccount from './components/DeleteAccount'
import { api } from './services'

function App(props) {
  const [marketplace, setMarketPlace] = useState(false)
  useEffect(() => {
    // when redirected from  marketplace then personal account is selected
    const {
      info: { id, active_organization_id }
    } = props.user
    if (marketplace && id) {
      switchAccount(id, active_organization_id)
    }
  }, [props.user])

  useEffect(() => {
    // const token = localStorage.getItem('accessToken')
    const tokens = history.location.search.split('&')
    if (history.location.search && tokens.length >= 3) {
      handleTokens(tokens)
    } else {
      const { authenticate } = props

      if (authenticate) {
        authenticate()
      }

      history.location.pathname === '/register' && history.push('/auth/signup')
      history.location.pathname === '/login' && history.push('/auth/signin')
    }

    handleReload()
  }, [])

  const handleReload = () => {
    const { user, updateUser } = props
    const { id, is_reload } = user.info

    if (is_reload) {
      updateUser(id, null, { is_reload: false }).then(res => {
        setTimeout(() => {
          window.location.reload(true)
        }, 5000)
      })
    }
  }
  const switchAccount = (id, active_organization_id) => {
    setMarketPlace(false)
    api.user.updateDbUser(id, { active_organization_id: null }).then(() => {
      if (active_organization_id) {
        window.location.reload()
      }
    })
  }
  const handleTokens = async tokens => {
    const getTokenByType = type => {
      const item = tokens.find(item => item.includes(type))
      return item && item.split(`${type}=`)[1]
    }

    const accessTokens = [
      'refreshToken',
      'accessToken',
      'idToken',
      'LastAuthUser'
    ]
    for (let i in accessTokens) {
      const item = accessTokens[i]
      await localStorage.setItem(item, getTokenByType(item))
    }
    let marketplace = getTokenByType('id')
    if (marketplace) {
      setMarketPlace(true)
    }
    window.history.replaceState(
      '',
      '',
      history.location.pathname +
        history.location.search.split(
          tokens.length === 3 ? '?' : '&refreshToken'
        )[0]
    )

    if (props.authenticate) {
      props.authenticate()
    }
  }

  const { isAuthenticated } = props

  return (
    isAuthenticated !== null && (
      <Router history={history}>
        <Switch>
          <PrivateRoute path="/management" component={Management} />
          <PrivateRoute
            path="/reports"
            exact
            isUser
            component={Reports.ReportRouting}
          />

          <PrivateRoute
            path="/reports/events/"
            exact
            component={Reports.Events.Details}
          />
          <PrivateRoute
            path="/reports/events/:id"
            component={Reports.Events.Preview}
          />
          <PrivateRoute
            path="/reports/course/:id/lesson/:lesson_id"
            exact
            component={Reports.Lesson.Details}
          />
          <PrivateRoute
            path="/reports/course/"
            exact
            component={Reports.Course.Preview}
          />
          <PrivateRoute
            path="/reports/course/:id"
            component={Reports.Course.Details}
          />
          <PrivateRoute
            path="/reports/maze/"
            exact
            component={Reports.Maze.Details}
          />
          <PrivateRoute
            path="/reports/maze/:id"
            exact
            component={Reports.Maze.Preview}
          />
          <PrivateRoute
            path="/reports/sharesphere/"
            exact
            component={Reports.ShareSphere.Details}
          />
          <PrivateRoute
            path="/reports/skill-journey/"
            exact
            component={Reports.SkillJourney.Details}
          />
          <PrivateRoute
            path="/reports/skill-journey/:id"
            exact
            component={Reports.SkillJourney.Preview}
          />
          <PrivateRoute
            path="/reports/live-assessments/"
            exact
            component={Reports.LiveAssessments.Details}
          />
          <PrivateRoute
            path="/reports/live-assessments/:id"
            exact
            component={Reports.LiveAssessments.Preview}
          />
          <PrivateRoute
            path="/reports/user/:userType/:id"
            component={Reports.User.Detail}
          />
          {/* Onboarding */}
          <PrivateRoute path="/Onboarding" component={Onboarding} />
          {/* Account */}

          <PrivateRoute exact path="/account" component={Account} />

          <PrivateRoute path="/account/delete-user" component={DeleteAccount} />

          {/* Trivia */}

          <PrivateRoute path="/share-sphere" component={Trivia} />
          {/* Workshop */}
          <PrivateRoute path="/workshop" component={Workshop} />
          {/* Library */}
          <PrivateRoute path="/library" component={Library} />
          {/* Dashboard routes */}
          <PrivateRoute path="/" component={Training.View} exact />
          {/* <PrivateRoute path='/' component={Dashboard.View} exact /> */}
          {/* <PrivateRoute path="/" component={Reports.ReportRouting} exact /> */}
          {/* <PrivateRoute
              path='/dashboard/:id'
              component={Dashboard.Details}
              exact
            /> */}
          {/* Courses routes */}
          <PrivateRoute path="/course-create" component={Courses.Create} />
          <PrivateRoute path="/courses/:id/edit" component={Courses.Edit} />
          <PrivateRoute path="/courses/:id" component={Courses.View} exact />
          {/* Scorm Courses routes */}
          <PrivateRoute path="/scorm-create" component={Scorm.Create} />
          <PrivateRoute path="/scorm/:id/edit" component={Scorm.Edit} />
          <PrivateRoute path="/scorm/:id" component={Scorm.Scorm} />
          {/* Events routes */}
          {/* <PrivateRoute path="/events/create" component={Events.Create} exact /> */}
          <PrivateRoute
            path="/events/:id/settings"
            component={Events.Settings}
            exact
          />
          <PrivateRoute
            path="/events/:id/edit"
            component={Events.Create}
            exact
          />
          <PrivateRoute path="/events/:id" component={Events.Preview} exact />
          <PrivateRoute path="/events" component={Events.Create} exact />
          <PublicRoute
            path="/events/:id/recording"
            component={EventRecording}
            exact
          />
          {/* market place */}

          {/* course */}

          <PrivateRoute
            path="/reports/market-place/course/"
            exact
            component={MarketPlace.Course.Preview}
          />

          <PrivateRoute
            path="/reports/market-place/course/:id"
            component={MarketPlace.Course.Details}
          />

          {/* webinar */}
          <PrivateRoute
            path="/reports/market-place/webinar/"
            exact
            component={MarketPlace.Webinar.Preview}
          />
          <PrivateRoute
            path="/reports/market-place/webinar/:id"
            component={MarketPlace.Webinar.Details}
          />

          {/* maze */}
          <PrivateRoute
            path="/reports/market-place/maze/"
            exact
            component={MarketPlace.Maze.Preview}
          />
          <PrivateRoute
            path="/reports/market-place/maze/:id"
            component={MarketPlace.Maze.Details}
          />

          {/* coaching */}
          <PrivateRoute
            path="/reports/market-place/coaching/"
            exact
            component={MarketPlace.Coaching.Preview}
          />
          <PrivateRoute
            path="/reports/market-place/coaching/:id"
            component={MarketPlace.Coaching.Details}
          />

          {/* public conference */}
          <PrivateRoute
            path="/reports/market-place/public-conference/"
            exact
            component={MarketPlace.PublicConference.Preview}
          />
          <PrivateRoute
            path="/reports/market-place/public-conference/:id"
            component={MarketPlace.PublicConference.Details}
          />

          {/* channel */}
          <PrivateRoute
            path="/reports/market-place/channel/"
            exact
            component={MarketPlace.Channel.Preview}
          />
          <PrivateRoute
            path="/reports/market-place/channel/:id"
            component={MarketPlace.Channel.Details}
          />

          {/* Discussion/Podcast Room */}
          <PrivateRoute
            path="/podcast-create"
            component={DiscussionRoom.DiscussionCreateMain}
            exact
          />
          <PrivateRoute
            path="/podcast/:id/edit"
            component={DiscussionRoom.DiscussionCreateMain}
            exact
          />
          <PrivateRoute
            path="/podcast/:id/play"
            component={DiscussionRoom.DiscussionListen}
            exact
          />
          <PrivateRoute
            path="/podcast/:id"
            component={DiscussionRoom.Preview}
            exact
          />
          {/* Assessments routes */}
          <PrivateRoute
            path="/assessments"
            component={Assessments.List}
            exact
          />
          <PrivateRoute
            path="/assessments/create"
            component={Assessments.Edit}
            exact
          />
          <PrivateRoute
            path="/assessments/:id/edit"
            component={Assessments.Edit}
            exact
          />
          <PrivateRoute
            path="/assessments/:id"
            component={Assessments.View}
            exact
          />
          <PublicRoute
            path="/assessments/:id/graph"
            component={Assessments.ViewGraph}
            exact
          />
          <PrivateRoute
            path="/assessments/list/:mode"
            component={Assessments.ListSection}
            exact
          />
          {/* Maze Routes */}
          <PrivateRoute path="/maze/:id/edit" component={Maze.Edit} />
          <PrivateRoute path="/maze" component={Maze.Create} />
          <PrivateRoute path="/maze-detail/:id" component={Maze.Detail} />
          {/* Skill Journey */}
          <PrivateRoute path="/skill-journey" component={SkillJourney} exact />
          <PrivateRoute
            path="/skill-journey/edit/:id"
            component={SkillJourney}
            exact
          />
          <PrivateRoute
            path="/skill-journey/:id"
            component={ViewSkillJourney}
            exact
          />
          {/* Channels */}
          <PrivateRoute
            path="/channel"
            component={Channel.CreateChannel}
            exact
          />
          <PrivateRoute
            path="/channel/:id/"
            component={Channel.ViewChannel}
            exact
          />
          {/* Training Listing Pages */}
          <PrivateRoute
            path="/channel/:id/edit"
            component={Channel.CreateChannel}
            exact
          />
          {/* Training routes */}
          <PrivateRoute path="/training" component={Training.View} exact />
          <PrivateRoute
            path="/training/search/:text"
            component={Training.Search}
            exact
          />
          <PrivateRoute path="/training" component={Training.View} exact />
          <PrivateRoute
            path="/training/search/:text"
            component={Training.Search}
            exact
          />
          {/* Training Listing Pages */}
          <PrivateRoute path="/training" component={Training.pages} />

          <PrivateRoute
            path="/knowledge-center"
            component={KnowledgeCenter}
            exact
          />
          <PrivateRoute path="/knowledge-center/library" component={Library} />
          <PrivateRoute
            path="/knowledge-center/course-ai"
            component={AiCourseCreation}
          />

          <PrivateRoute
            path="/course/:id/course-ai"
            component={AiCourseCreation}
            exact
          />

          <PrivateRoute path="/video-create" component={VideoAi} />

          {/* Calendar */}
          <PrivateRoute path="/calendar" component={MyCalendar} exact />
          {/* Help Center route */}
          <PrivateRoute path="/help-center" component={HelpCenter} />
          {/* Auth routes */}
          <PublicRoute path="/auth/signin" component={Auth.SignIn} exact />
          <PublicRoute
            path="/auth/reset"
            component={Auth.PasswordReset}
            exact
          />
          <PublicRoute path="/auth" component={Auth.SignUp} />
          {/* Global routes */}
          <Route path="/pages" component={Pages} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    )
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  authenticate: () => dispatch(actions.user.authenticate()),
  updateUser: (id, data, dbData) =>
    dispatch(actions.user.updateUser(id, data, dbData))
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
