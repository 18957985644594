import React, { useState, useEffect } from 'react'
import { Drawer, Button, Checkbox, message, Typography } from 'antd'
import { api } from '../../../../services'

const { Text } = Typography

const PermissionManagementDrawer = ({
  visible,
  onCancel,
  role,
  onUpdatePermissions,
  allPermissions
}) => {
  const [checkedPermissions, setCheckedPermissions] = useState([])
  const [loading, setLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)

  const permissionValues = {
    training_live: 'View Live Trainings',
    training_channels: 'View Training Channels',
    training_courses: 'View Training Courses',
    training_events: 'View Training Events',
    training_podcast: 'View Training Podcasts',
    training_maze: 'View Training Mazes',
    training_skill: 'View Training Skills'
  }

  useEffect(() => {
    if (role && visible) {
      const fetchRolePermissions = async () => {
        setLoading(true)
        try {
          const response = await api.roles.getRolePermissions(role.id)
          const rolePermissions = response.data.data || []
          const rolePermissionIds = rolePermissions.map(
            item => item.RolePermission.permission_id
          )
          setCheckedPermissions(rolePermissionIds)
        } catch (error) {
          message.error('Failed to load role permissions')
        } finally {
          setLoading(false)
        }
      }
      fetchRolePermissions()
    }
  }, [role, visible])

  const handleCheckboxChange = permission => {
    setCheckedPermissions(prev =>
      prev.includes(permission)
        ? prev.filter(p => p !== permission)
        : [...prev, permission]
    )
  }

  const handleSave = async () => {
    try {
      setSaveLoading(true)
      await onUpdatePermissions(role.id, checkedPermissions)
      message.success('Permissions updated successfully')
      onCancel()
    } catch (error) {
      message.error('Failed to update permissions')
    } finally {
      setSaveLoading(false)
    }
  }

  return (
    <Drawer
      title="Manage Permissions"
      visible={visible}
      onClose={onCancel}
      width={500}
      footer={null}
      className="permission-drawer"
    >
      <div style={{ marginBottom: 16, textAlign: 'left' }}>
        <h3>Role: {role && role.name}</h3>
      </div>

      <div
        style={{
          height: 'calc(100% - 120px)',
          overflowY: 'auto',
          paddingBottom: 70
        }}
      >
        {loading ? (
          <div>Loading permissions...</div>
        ) : (
          <div className="permission-checkbox-container">
            {allPermissions.map(permission => (
              <Checkbox
                className="permission-checkbox"
                key={permission.id}
                checked={checkedPermissions.includes(permission.id)}
                onChange={() => handleCheckboxChange(permission.id)}
              >
                {permissionValues[permission.name]}
              </Checkbox>
            ))}
          </div>
        )}
      </div>

      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: 'white',
          textAlign: 'right',
          zIndex: 10
        }}
      >
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          type="primary"
          onClick={handleSave}
          style={{ marginLeft: 8 }}
          disabled={loading}
          loading={saveLoading}
        >
          Save Changes
        </Button>
      </div>
    </Drawer>
  )
}

export default PermissionManagementDrawer
