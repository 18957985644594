export default {
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    USER_POOL_WEB_CLIENT_ID:
      process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    MANDATORY_SIGN_IN: false,
    AUTHENTICATION_FLOW_TYPE: 'USER_PASSWORD_AUTH',
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
  },
  marketingURL: process.env.REACT_APP_MARKETING_URL,
  baseURL: process.env.REACT_APP_BASE_URL,
  socketURL: process.env.REACT_APP_SOCKET_URL,
  pexel: process.env.REACT_APP_PEXEL_API_KEY,
  audioSynthesizer: process.env.REACT_APP_AUDIO_SYNTHESIZER,
  fetchPexelPhotos: process.env.REACT_APP_FETCH_PEXEL_PHOTOS,
  vimeoUrl: process.env.REACT_APP_VIMEO_URL,
  antWSURL: process.env.REACT_APP_ANT_WS_CONNECT_URL,
  turnURL: process.env.REACT_APP_ANT_TURN_SERVER,
  wowza: {
    license: process.env.REACT_APP_WOWZA_LICENSE
  },
  ttsAudioSynthesizer: process.env.REACT_APP_TTS_AUDIO_SYNTHESIZER,
  canvaKey: process.env.REACT_APP_CANVA_API_KEY,
  envType: process.env.REACT_APP_ENVIRONMENT_KEY,
  confDurationExceed: process.env.REACT_APP_CONFERENCE_DURATION_EXCEED_WARNING,
  recordingToken: process.env.REACT_EVENT_RECORDING_STATIC_TOKEN,
  orgId: process.env.REACT_APP_ORG_ID,
  observerURL: process.env.REACT_APP_OBSERVER_API_ENDPOINT_URL,
  aiBotURL: process.env.REACT_APP_AI_BOT_URL,
  summarizeURL: process.env.REACT_APP_SUMMAIRZE_URL,
  scormURL: process.env.REACT_APP_SCORM_BASE_URL,
  phpServerURL: process.env.REACT_APP_PHP_SERVER,
  clientID: process.env.REACT_APP_ZOOM_CLIENT_ID,
  redirectURI: process.env.REACT_APP_ZOOM_REDIRECT_URI,
  cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN,
  whiteboardBucket: process.env.REACT_APP_WB_BUCKET,
  agoraWbAccesKey: process.env.REACT_APP_AGORA_WB_ACCES_KEY,
  agoraWbSecretKey: process.env.REACT_APP_AGORA_WB_SECRET_ACCES_KEY,
  agoraWbAppId: process.env.REACT_APP_AGORA_WB_APP_ID,
  youtubeApiKey: process.env.REACT_APP_YOUTUBE_API_KEY,
  midJourneySocket: process.env.REACT_APP_MIDJOURNEY_SOCKET,
  questionAnswerAiUrl: process.env.REACT_APP_QUESTION_ANSWER_AI_URL,
  aiCourseCreationUrl: process.env.REACT_APP_AI_COURSE_CREATION_URL,
  aiContentGenerationUrl: process.env.REACT_APP_SHAREBOT_API,
  s3BucketURL: process.env.REACT_APP_S3_BUCKET_URL,
  summarizeAPI: process.env.REACT_APP_AI_SUMMARISE_API,
  ideogramApiKey: process.env.REACT_APP_IDEOGRAM_API_KEY,
  elevenLabsApiKey: process.env.REACT_APP_ELEVENLABS_API_KEY
}
