import React, { useState } from 'react'
import { Button, Modal, Icon, message, Card, Result, Typography } from 'antd'
import { api } from '../../services'

import './DeleteAccount.scss'
import { useSelector } from 'react-redux'
import history from '../../history'
import { useTranslation } from 'react-i18next'

const { Paragraph, Text } = Typography

const DeleteAccount = () => {
  const [isDeletingAccount, setIsDeletingAccount] = useState(false)

  const { confirm } = Modal

  const user = useSelector(state => state.user)

  const { id } = user && user.info

  const handleDelete = () => {
    setIsDeletingAccount(true)
    api.user
      .deleteUser(id)
      .then(() => {
        message.success('Your account has been deleted successfully.')
        api.user
          .signOut()
          .then(() => {
            history.push('/auth/signin')
          })
          .catch(() => {
            setIsDeletingAccount(false)
          })
      })
      .catch(() => {
        setIsDeletingAccount(false)
      })
  }

  const handleGoBack = () => {
    history.push('/account')
  }

  const handleDeleteAccount = () => {
    confirm({
      title: t('v3:account_confirm_delete'),
      content: t('v3:action_cannot_be_undone'),
      centered: true,
      onOk() {
        handleDelete()
      },
      onCancel() {}
    })
  }
  const { t } = useTranslation()
  return (
    <div className="section delete-account">
      <Card>
        <Result
          icon={<Icon type="delete" />}
          title={t('v3:delete_account_title')}
          subTitle={t('v3:delete_account_subtitle')}
          extra={[
            <Button icon="arrow-left" onClick={handleGoBack}>
              {t('v3:back_to_account')}
            </Button>,
            <Button
              type="danger"
              onClick={handleDeleteAccount}
              loading={isDeletingAccount}
            >
              <Icon type={isDeletingAccount ? 'loading' : 'delete'} />{' '}
              {t('v3:delete_account')}
            </Button>
          ]}
        >
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16
                }}
              >
                {t('v3:delete_account_cannot_undo')}
              </Text>
            </Paragraph>
            <Paragraph>{t('v3:account_org_profile')}</Paragraph>
            <Paragraph>{t('v3:deleting_account_database')}</Paragraph>
          </div>
        </Result>
      </Card>
    </div>
  )
}

export default DeleteAccount
