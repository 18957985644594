import { Col, Row } from 'antd'
import React from 'react'
import { Fragment } from 'react'
import { report } from '../../../../../../constants'
import { userImageNoBack } from '../../../../images'
import { CustomStatusBox } from '../../../../styled'

const StatsContainer = props => {
  const {
    t,
    data,
    activeType,
    user,
    isUserAsHost,
    changeTab,
    enableMaze,
    onClickStatsButton,
    loading,
    totalCount
  } = props

  const { courses, events, assessments, mazes, quizzes, skills, trivia } = data
  return (
    <Fragment>
      <div className="user-report-title">
        <div
          className={`host ${isUserAsHost ? 'activeTab' : ''}`}
          onClick={() => changeTab(true)}
        >
          {t('reports:host')}
        </div>
        <div
          className={`participant ${!isUserAsHost ? 'activeTab' : ''}`}
          onClick={() => changeTab(false)}
        >
          {t('reports:participant')}
        </div>
      </div>
      <Row style={{ padding: '0 2rem', marginBottom: '3rem' }}>
        <Col lg={7} sm={24}>
          <div
            style={
              loading ? { pointerEvents: 'none' } : { pointerEvents: 'visible' }
            }
            onClick={() => (loading ? '' : onClickStatsButton(report.OVERVIEW))}
            className={`box-statistic user-report-profile ${activeType === report.OVERVIEW ? 'bluish-background' : ''
              }`}
          >
            <div className="info">
              <div className="picture">
                <img
                  src={
                    loading || (user && !user.avatar)
                      ? userImageNoBack
                      : user && user.avatar && user.avatar.resizes.thumbnail
                  }
                  alt=""
                />
              </div>
              <div className="about">
                <h3 className="name">
                  {(user &&
                    user.first_name &&
                    `${user.first_name + ' ' + user.last_name}`) ||
                    (user && user.username)}
                </h3>
                <p>{user && user.email}</p>
              </div>
            </div>
            <div className="status">
              <div className="value-statistic">
                {loading ? '...' : totalCount}
              </div>
              <div className="content-statistic">
                {t('tabs:overview')} <br />
                {t('reports:workshops')}
              </div>
            </div>
          </div>
        </Col>
        <Col lg={17} sm={30}>
          <div className="report-status-container">
            <CustomStatusBox selected={activeType === report.COURSE}>
              <div
                style={
                  loading
                    ? { pointerEvents: 'none' }
                    : { pointerEvents: 'visible' }
                }
                onClick={() =>
                  loading ? '' : !courses.loading && onClickStatsButton(report.COURSE)
                }
                className="box-statistic"
              >
                <div className="value-statistic">
                  {courses.loading ? '...' : courses.data.length}
                </div>
                <div className="content-statistic">{t('reports:courses')}</div>
              </div>
            </CustomStatusBox>
            {enableMaze && (
              <CustomStatusBox selected={activeType === report.MAZE}>
                <div
                  style={
                    loading
                      ? { pointerEvents: 'none' }
                      : { pointerEvents: 'visible' }
                  }
                  onClick={() =>
                    loading ? '' : !mazes.loading && onClickStatsButton(report.MAZE)
                  }
                  className="box-statistic"
                >
                  <div className="value-statistic">
                    {mazes.loading ? '...' : mazes.data.length}
                  </div>
                  <div className="content-statistic">{t('v4:maze')}</div>
                </div>
              </CustomStatusBox>
            )}
            <CustomStatusBox selected={activeType === report.LIVE_EVENT}>
              <div
                style={
                  loading
                    ? { pointerEvents: 'none' }
                    : { pointerEvents: 'visible' }
                }
                onClick={() =>
                  loading ? '' : !events.loading && onClickStatsButton(report.LIVE_EVENT)
                }
                className="box-statistic"
              >
                <div className="value-statistic">
                  {events.loading ? '...' : events.data.length}
                </div>
                <div className="content-statistic">
                  {t('reports:live_events')}
                </div>
              </div>
            </CustomStatusBox>
            <CustomStatusBox selected={activeType === report.LIVE_ASSESSMENT}>
              <div
                style={
                  loading
                    ? { pointerEvents: 'none' }
                    : { pointerEvents: 'visible' }
                }
                onClick={() =>
                  loading ? '' : !assessments.loading && onClickStatsButton(report.LIVE_ASSESSMENT)
                }
                className="box-statistic"
              >
                <div className="value-statistic">
                  {assessments.loading ? '...' : assessments.data.length}
                </div>
                <div className="content-statistic">
                  {t('reports:live_assessments')}
                </div>
              </div>
            </CustomStatusBox>
            <CustomStatusBox selected={activeType === report.QUIZ}>
              <div
                style={
                  loading
                    ? { pointerEvents: 'none' }
                    : { pointerEvents: 'visible' }
                }
                onClick={() => (loading ? '' : !quizzes.loading && onClickStatsButton(report.QUIZ))}
                className="box-statistic"
              >
                <div className="value-statistic">
                  {quizzes.loading ? '...' : quizzes.data.length}
                </div>
                <div className="content-statistic">{t('reports:quizzes')}</div>
              </div>
            </CustomStatusBox>
            <CustomStatusBox selected={activeType === report.SKILL_JOURNEY}>
              <div
                style={
                  loading
                    ? { pointerEvents: 'none' }
                    : { pointerEvents: 'visible' }
                }
                onClick={() =>
                  loading ? '' : !skills.loading && onClickStatsButton(report.SKILL_JOURNEY)
                }
                className="box-statistic"
              >
                <div className="value-statistic">
                  {skills.loading ? '...' : skills.data.length}
                </div>
                <div className="content-statistic">{t('v4:skill_journey')}</div>
              </div>
            </CustomStatusBox>
            <CustomStatusBox selected={activeType === report.TRIVIA}>
              <div
                style={
                  loading
                    ? { pointerEvents: 'none' }
                    : { pointerEvents: 'visible' }
                }
                onClick={() =>
                  loading ? '' : !trivia.loading && onClickStatsButton(report.TRIVIA)
                }
                className="box-statistic"
              >
                <div className="value-statistic">
                  {trivia.loading ? '...' : trivia.data.length}
                </div>
                <div className="content-statistic">{t('v3:share_sphere')}</div>
              </div>
            </CustomStatusBox>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

export default StatsContainer
