import React from 'react'
import NowLiveList from './NowLiveList'
import EventList from './EventList'
import CourseList from './CourseList'
import MazeList from './MazeList'
import SkillJourneyList from './SkillJourneyList'
import PodcastList from './PodcastList'
import ChannelList from './ChannelList'
import Unauthorized from './Unauthorized'
import { useSelector } from 'react-redux'

const components = {
  '/training/live': NowLiveList,
  '/training/events': EventList,
  '/training/courses': CourseList,
  '/training/metamazes': MazeList,
  '/training/skill-journeys': SkillJourneyList,
  '/training/podcasts': PodcastList,
  '/training/channels': ChannelList
}

const permissionMapping = {
  '/training/live': 'training_live',
  '/training/events': 'training_events',
  '/training/courses': 'training_courses',
  '/training/metamazes': 'training_maze',
  '/training/skill-journeys': 'training_skill',
  '/training/podcasts': 'training_podcast',
  '/training/channels': 'training_channels'
}

const RoutePage = props => {
  // Define default permissions
  const defaultPermissions = {
    training_live: false,
    training_events: false,
    training_courses: false,
    training_maze: false,
    training_skill: false,
    training_podcast: false,
    training_channels: false
  }

  const roleState = useSelector(state => state.user)

  const {
    info: { roles = [], is_super_admin = false, role = {} }
  } = roleState

  const hasAllPermissions = is_super_admin || role.name === 'Admin'

  // If user is super admin, set all permissions to true
  const permissionValues = hasAllPermissions
    ? Object.keys(defaultPermissions).reduce(
        (acc, key) => ({
          ...acc,
          [key]: true
        }),
        {}
      )
    : roles.reduce(
        (acc, role) => {
          if (role.role && role.role.permissions) {
            role.role.permissions.forEach(permission => {
              if (permission.name in acc) {
                acc[permission.name] = true
              }
            })
          }
          return acc
        },
        { ...defaultPermissions }
      )

  const {
    location: { pathname: path }
  } = props

  const Component = components[path]
  const permissionKey = permissionMapping[path]

  if (!permissionValues[permissionKey]) {
    return <Unauthorized />
  }

  return <Component {...props} />
}

export default RoutePage
