import React, { Fragment, useEffect, useRef, useState } from 'react'
import { api } from '../../services'
import {
  Alert,
  Modal,
  Card,
  Input,
  Button,
  Icon,
  Avatar,
  Spin,
  Row,
  Col
} from 'antd'
import './AiCourseCreation.scss'
import AiCourseForm from './AiCourseForm'
import AiTableOfContent from './AiTableOfContent'
import { useTranslation } from 'react-i18next'

import uuidv4 from 'uuid/v4'
import NewHeader from '../NewHeader'
import history from '../../history'
import { useSelector } from 'react-redux'

const { TextArea } = Input
const { confirm } = Modal

const defaultErrorObj = {
  hasError: false,
  msg: ''
}

const defaultShowModal = {
  isVisible: false,
  content: {}
}

const CourseCreation = props => {
  const [courseTitle, setCourseTitle] = useState('')
  const [conversation, setConversation] = useState([])
  const [chatText, setChatText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(defaultErrorObj)
  const [isInitialForm, setIsInitialForm] = useState(true)
  const [isGeneratingTableOfContent, setIsGeneratingTableOfContent] = useState(
    false
  )

  const [isContentGenerationStarted, setIsContentGenerationStarted] = useState(
    false
  )
  const [showModal, setShowModal] = useState(defaultShowModal)
  const [isFetchingAiContent, setIsFetchingAiContent] = useState(false)
  const [aiContent, setAiContent] = useState(null)
  const [
    isContentGenerationCompleted,
    setIsContentGenerationCompleted
  ] = useState(false)

  const courseId = props.match.params.id

  const user = useSelector(state => state.user)

  useEffect(() => {
    if (courseId) {
      getCourseTitle()
    }
  }, [])

  useEffect(() => {
    if (aiContent) {
      setShowModal({
        isVisible: true,
        content: {
          id: uuidv4(),
          content: aiContent
        }
      })
      setIsInitialForm(false)
      setIsContentGenerationStarted(true)
    }
  }, [aiContent])

  const getCourseTitle = () => {
    setIsFetchingAiContent(true)
    api.courses
      .getCourseById(courseId)
      .then(res => {
        setCourseTitle(res.data.title)
        if (res.data.is_ai_generated) {
          history.push(`/courses/${courseId}/edit`)
        } else {
          fetchContent()
        }
      })
      .catch(error => {
        console.log(error)
        setIsFetchingAiContent(false)
        history.push(`/courses/${courseId}/edit`)
      })
  }

  const fetchContent = () => {
    setIsFetchingAiContent(true)
    const activeOrganizationId = user.info.active_organization_id
    api.courses
      .getGeneratedContent({
        orgId: activeOrganizationId,
        courseId: courseId
      })
      .then(response => {
        if (response.data.content) {
          setAiContent(response.data.content)
          setIsInitialForm(false)
        }

        setIsFetchingAiContent(false)
      })
      .catch(() => {
        setIsFetchingAiContent(false)
      })
  }

  const handleChat = (prompt = '') => {
    if (!prompt && (!chatText || isLoading)) return

    if (prompt) {
      setChatText(prompt)
    }

    setIsLoading(true)
    setConversation(prev => [
      ...prev,
      { id: uuidv4(), role: 'user', questions: prompt || chatText }
    ])
    const lastItem = conversation[conversation.length - 1]

    api.courses
      .askAiCourseCreation({
        params: {
          prompt: prompt || chatText,
          context: lastItem.hasOwnProperty('context') ? lastItem.context : []
        }
      })
      .then(res => {
        setIsLoading(false)
        setError(defaultErrorObj)
        setConversation(prev => [
          ...prev,
          {
            id: uuidv4(),
            ...res.data.body[0],
            role: 'system'
          }
        ])
        setChatText('')
      })
      .catch(err => {
        setIsLoading(false)
        setError({ hasError: true, msg: err.message })
      })
  }

  const handleFormSubmit = (values, data) => {
    setIsInitialForm(false)
    setConversation([
      {
        id: uuidv4(),
        formValues: values,
        role: 'user',
        isInitial: true
      },
      {
        id: uuidv4(),
        ...data,
        role: 'system'
      }
    ])
  }

  const generateTableOfContent = () => {
    const lastItem = conversation[conversation.length - 1]
    setIsGeneratingTableOfContent(true)
    setChatText()
    api.courses
      .askAiCourseCreation({
        params: {
          course_design_template: lastItem && lastItem.revised_prompt,
          context: lastItem.hasOwnProperty('context') ? lastItem.context : []
        }
      })
      .then(res => {
        setConversation(prev => [
          ...prev,
          {
            id: uuidv4(),
            table_of_content: res.data.body[0].table_of_content,
            promptId: lastItem.id,
            role: 'system'
          }
        ])
        setIsGeneratingTableOfContent(false)
      })
      .catch(err => {
        setIsGeneratingTableOfContent(false)
        setError({ hasError: true, msg: err.message })
      })
  }

  const handleSetupAgain = () => {
    confirm({
      title: t('v3:ai_creation_confirm_title'),
      content: t('v3:ai_creation_confirm_content'),
      centered: true,
      onOk() {
        setIsInitialForm(true)
        setConversation([])
        setShowModal({ isVisible: false, content: {} })
        setIsGeneratingTableOfContent(false)
        setIsLoading(false)
        setChatText('')
        setError(defaultErrorObj)
        setIsInitialForm(true)
      },
      onCancel() {
        console.log('Cancel')
      }
    })
  }
  const handleGoBack = () => {
    if (isContentGenerationCompleted) {
      history.push(`/courses/${courseId}/edit`)
      return
    }

    confirm({
      title: t('v3:ai_creation_confirm_title'),
      content: t('v3:ai_creation_confirm_content'),
      centered: true,
      onOk() {
        history.push(`/courses/${courseId}/edit`)
        // setIsInitialForm(true)
        // setConversation([])
        // setShowModal({ isVisible: false, content: {} })
        // setIsGeneratingTableOfContent(false)
        // setIsLoading(false)
        // setChatText('')
        // setError(defaultErrorObj)
        // setIsInitialForm(true)
      },
      onCancel() {
        console.log('Cancel')
      }
    })
  }

  const handleTableOfContentChange = ({ id, tableOfContent }) => {
    setConversation(prev => {
      return prev.map(item => {
        if (item.id === id) {
          return {
            ...item,
            table_of_content: tableOfContent
          }
        } else {
          return item
        }
      })
    })
  }

  const handleContentGenerationStarted = () => {
    setIsContentGenerationStarted(true)
  }

  const isFromKnowledgeCenter = window.location.pathname.includes(
    'knowledge-center'
  )

  const handleCancel = () => {
    const hasContentGenerationStarted =
      isContentGenerationStarted && !isGeneratingTableOfContent
    if (hasContentGenerationStarted) {
      return
    }
    confirm({
      title: 'Are you sure you want to close?',
      content: `All your changes will be lost.`,
      onOk: () => {
        history.push(`/courses/${courseId}/edit`)
      }
    })
  }
  const { t } = useTranslation()

  const renderCreationModalContent = () => {
    return (
      <>
        <div className="course-creation-ai-modal__header">
          <h2>{t('v3:ai_course_creation')}</h2>
          {/* {!isFromKnowledgeCenter && (
            <Icon type="close" className="close_icon" onClick={handleCancel} />
          )} */}

          <div className="shl-header">
            <NewHeader />
          </div>
        </div>

        {isInitialForm ? (
          isFromKnowledgeCenter ? (
            <div onCancel={handleCancel}>
              <AiCourseForm
                courseId={courseId}
                onFormSubmit={handleFormSubmit}
                loading={isFetchingAiContent}
                isFromKnowledgeCenter={isFromKnowledgeCenter}
              />
            </div>
          ) : (
            <Modal visible footer={false} onCancel={handleCancel} centered>
              <AiCourseForm
                courseId={courseId}
                onFormSubmit={handleFormSubmit}
                loading={isFetchingAiContent}
                courseTitle={courseTitle}
              />
            </Modal>
          )
        ) : (
          <div className="course-creation-ai-modal__body">
            {!isContentGenerationStarted && (
              <Card bordered={false}>
                <div
                  className={`course-creation-ai-wrap ${
                    showModal.isVisible ? 'show-creation-content' : ''
                  }`}
                >
                  <AiCourseFormPreview
                    handleSetupAgain={handleSetupAgain}
                    message={conversation[0]}
                  />
                  <div className="course-creation-ai-box">
                    <div className="course-creation-ai-box-title">
                      <h2>{t('v4:course_assistance')}</h2>
                    </div>
                    <div className="course-creation-ai-box-body">
                      <AiCourseChat
                        conversation={conversation}
                        isLoading={isLoading}
                        generateTableOfContent={generateTableOfContent}
                        isGeneratingTableOfContent={isGeneratingTableOfContent}
                        setShowModal={setShowModal}
                        showModal={showModal}
                        handleSetupAgain={handleSetupAgain}
                      />
                      <div className="course-creation-ai-modal__input-container">
                        <TextArea
                          disabled={isLoading || isGeneratingTableOfContent}
                          placeholder={t('reports:message')}
                          className="course-creation-ai-modal__input"
                          onChange={e => setChatText(e.target.value)}
                          onPressEnter={() => handleChat()}
                          value={chatText}
                          style={{ width: '100%' }}
                        />

                        <a
                          className="course-creation-ai-modal__send"
                          onClick={() => handleChat()}
                        >
                          <Icon type="arrow-up" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            )}
            {showModal && showModal.isVisible && (
              <AiTableOfContent
                courseTitle={
                  courseTitle
                    ? courseTitle
                    : aiContent
                    ? t('v3:course_generated')
                    : conversation[0].formValues.courseTitle
                }
                courseId={courseId}
                tableOfContent={showModal.content}
                onTableOfContentChange={handleTableOfContentChange}
                onContentGenerationStarted={handleContentGenerationStarted}
                isFromKnowledgeCenter={isFromKnowledgeCenter}
                setIsContentGenerationCompleted={
                  setIsContentGenerationCompleted
                }
              />
            )}
          </div>
        )}

        {error.hasError && !isLoading && (
          <Alert
            message={error.msg}
            type="error"
            showIcon
            style={{ marginTop: 20 }}
          />
        )}
        {!isFromKnowledgeCenter && (
          <div className="ai-go-back-btn">
            <Button
              className="shl-primary-btn"
              icon="left"
              onClick={handleGoBack}
            >
              {t('v4:go_back')}
            </Button>
          </div>
        )}
      </>
    )
  }

  if (isFromKnowledgeCenter) {
    return (
      <div className="course-creation-ai-modal knowledge-center-course-ai-container">
        {renderCreationModalContent()}
      </div>
    )
  }

  return (
    <Spin spinning={isFetchingAiContent}>
      <div
        className="course-creation-ai-modal"
        centered
        visible
        onOk={handleCancel}
        onCancel={handleCancel}
        width="100%"
        // style={{ maxWidth: isInitialForm ? '600px' : '90vw' }}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t('v4:go_back')}
          </Button>
        ]}
        maskClosable={false}
        closable={false}
      >
        {renderCreationModalContent()}
      </div>
    </Spin>
  )
}

const AiCourseChat = ({
  conversation,
  showModal,
  setShowModal,
  generateTableOfContent,
  isGeneratingTableOfContent,
  isLoading
}) => {
  const endOfConversationRef = useRef(null)
  const user = useSelector(state => state.user)

  useEffect(() => {
    // Scroll to the end of the conversation when it changes
    endOfConversationRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [conversation])

  const showGenerateTableOfContentButton = messageId => {
    const lastItem = conversation[conversation.length - 1]
    if (lastItem && lastItem.id === messageId) {
      return Boolean(lastItem && lastItem.revised_prompt)
    }
    return false
  }

  const showTableOfContentButton = messageId => {
    const item = conversation.find(item => item.promptId === messageId)
    if (item && item.table_of_content) {
      return item
    }
    return false
  }

  const isShowingTableOfContent = messageId => {
    const contentToShow = showTableOfContentButton(messageId)
    const contentId = contentToShow && contentToShow.id
    const currentPreviewId = showModal.content && showModal.content.id
    return contentId === currentPreviewId
  }
  const { t } = useTranslation()

  return (
    <div className="course-creation-ai-conversation">
      {conversation.map(message => {
        const isSystem = message.role && message.role === 'system'

        const questions = message && message.questions

        if (message && message.table_of_content) return null

        const content = questions
          ? questions
          : message && message.revised_prompt
          ? t('v3:ai_prompt_ready')
          : t('v3:ai_unable_respond')

        const lines = content.split('\n')

        return (
          <Fragment key={message.id}>
            {message.isInitial ? null : (
              <div
                className={`course-creation-ai-conversation__message ${
                  isSystem ? 'msg--system' : 'msg--user'
                }`}
              >
                <div className="course-creation-ai-conversation__message-from">
                  {/* user?.info?.avatar */}
                  {isSystem ? (
                    <span>{t('v3:ai')}</span>
                  ) : user.info && user.info.avatar && user.info.avatar.link ? (
                    <Avatar
                      className="knowledge-center-item-avatar"
                      src={
                        user.info && user.info.avatar && user.info.avatar.link
                      }
                      alt="Sharelook Avatar"
                    />
                  ) : (
                    <span>{t('v3:ai_you')}</span>
                  )}
                </div>
                <div className="course-creation-ai-conversation__message-content">
                  {/* <p>
                    {questions
                      ? questions
                      : message && message.revised_prompt
                      ? t('v3:ai_prompt_ready')
                      : t('v3:ai_unable_respond')}
                  </p> */}
                  {lines.length > 1 ? (
                    <ol>
                      {lines.map((line, index) => (
                        <li key={index}>{line.trim()}</li>
                      ))}
                    </ol>
                  ) : (
                    <p>{content}</p>
                  )}

                  {showTableOfContentButton(message && message.id) && (
                    <div
                      className="course-creation-ai-conversation__message-content__preview-button"
                      onClick={() => {
                        const contentToShow = showTableOfContentButton(
                          message && message.id
                        )
                        setShowModal(
                          isShowingTableOfContent(message && message.id)
                            ? defaultShowModal
                            : {
                                isVisible: true,
                                content: {
                                  id: contentToShow && contentToShow.id,
                                  content:
                                    contentToShow &&
                                    contentToShow.table_of_content
                                }
                              }
                        )
                      }}
                    >
                      {isShowingTableOfContent(message && message.id) ? (
                        <>
                          <Icon type="eye-invisible" /> {t('v3:ai_hide_toc')}
                        </>
                      ) : (
                        <>
                          <Icon type="eye" /> {t('v3:ai_preview_toc')}
                        </>
                      )}
                    </div>
                  )}

                  {showGenerateTableOfContentButton(message && message.id) && (
                    <Button
                      loading={isGeneratingTableOfContent}
                      disabled={isLoading}
                      type="primary"
                      onClick={() => generateTableOfContent()}
                    >
                      {t('v3:ai_generate_toc_now')}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        )
      })}

      <div ref={endOfConversationRef}></div>

      {isLoading && (
        <div className="course-creation-ai-conversation__message msg--system">
          <div className="course-creation-ai-conversation__message-from">
            <span>{t('v3:ai')}</span>
          </div>
          <div className="course-creation-ai-conversation__message-content">
            <div className="dot-container">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const AiCourseFormPreview = ({ message = {}, handleSetupAgain }) => {
  if (message && !message.formValues) return null

  const {
    industrySpecific,
    industry,
    standard,
    standardDetails,
    courseTitle,
    learningObjective,
    duration
  } = message.formValues
  const { t } = useTranslation()
  return (
    <div className="course-ai-preview">
      <div className="course-ai-titile">
        <h2>{t('v4:course_information')}</h2>
      </div>
      <div className="course-ai-sec">
        <div className="course-ai-preview-section">
          <div className="course-ai-preview-item">
            <span>{t('v3:ai_creation_course_title')}</span>
            <p>{courseTitle}</p>
          </div>
          <div className="course-ai-preview-item">
            <span>{t('v3:ai_creation_industry_specific')}</span>
            <p>{industrySpecific}</p>
          </div>
          {industry && (
            <div className="course-ai-preview-item">
              <span>{t('v3:ai_creation_industry')}</span>
              <p>{industry}</p>
            </div>
          )}
          <div className="course-ai-preview-item">
            <span>{t('v3:ai_creation_standard_needed')}</span>
            <p>{standard}</p>
          </div>
          {standardDetails && (
            <div className="course-ai-preview-item">
              <span>{t('v3:ai_creation_standard_details')}</span>
              <p>{standardDetails}</p>
            </div>
          )}
        </div>
        <div className="course-ai-preview-section">
          <div className="course-ai-preview-item">
            <span>{t('v3:ai_creation_estimated_duration')}</span>
            <p>
              {duration} {t('v3:minutes')}
            </p>
          </div>
          <div className="course-ai-preview-item">
            <span>{t('v3:ai_creation_learning_objective')}</span>
            <p>{learningObjective}</p>
          </div>
        </div>
      </div>
      <Row type="flex" justify="end" className="course-ai-btn">
        <Col>
          <Button
            type="green"
            shape="round"
            className="course-ai-preview-button"
            onClick={handleSetupAgain}
          >
            {t('v3:setup_again')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CourseCreation
