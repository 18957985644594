import React, { useState, useMemo, Fragment } from 'react'

import { Col, Divider, Icon, message, Modal, Pagination, Result, Row } from 'antd'
import {
  PaginationRightAligned,
  ReportTable,
  SearchEventMargin
} from '../../../../../styled'

import { Search } from '../../../../../../../components'
import TableInfoColumns from './internal/TableInfoColumns'
import TableCoverImage from './internal/TableCoverImage'
import { getMinutes } from '../../../../../../../utils'
import { getNestedChildren } from '../../../../../../Maze/Create/components/MazeScenario/mazeHelper'
import Tree from 'react-d3-tree'

const customExpandIcon = props => {
  return <Icon type={props.expanded ? 'up' : 'down'} {...props} />
}

const UserMetaMazeTable = props => {
  const {
    t,
    data: {
      mazes: { data, loading }
    },
    isUserAsHost: isHost
  } = props

  const participantMazeColumns = useMemo(
    () => [
      {
        title: t('v4:item').toLowerCase(),
        dataIndex: 'cover',
        render: cover => <TableCoverImage cover={cover} />
      },
      {
        title: '',
        dataIndex: 'info',
        render: (text, record) => <TableInfoColumns record={record} />
      },
      { title: t('v4:pass_score'), dataIndex: 'minimum_pass_score' },
      { title: t('v4:avg_score'), dataIndex: 'averageScore' },
      { title: t('v4:detail') }
    ],
    []
  )

  const hostMazeColumns = useMemo(
    () => [
      {
        title: t('v4:item').toLowerCase(),
        dataIndex: 'cover',
        render: cover => <TableCoverImage cover={cover} />
      },
      {
        title: '',
        dataIndex: 'info',
        render: (text, record) => <TableInfoColumns record={record} />
      },
      { title: t('v4:pass_score'), dataIndex: 'minimum_pass_score' },
      { title: t('reports:views'), dataIndex: 'session_play' },
      { title: t('v4:detail') }
    ],
    []
  )

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [showMazeGraphModal, setShowMazeGraphModal] = useState(false)
  const [idealPathData, setIdealPathData] = useState([])
  const [total, setTotal] = useState(data.length)

  const getData = (current, pageSize) => {
    let searchData = ''
    if (search) {
      searchData = data.filter(item =>
        item.title.toLowerCase().includes(search.toLowerCase())
      )
    }
    const dataToFilter = search ? searchData : data
    setTotal(search ? searchData.length : data.length)
    return dataToFilter.slice((current - 1) * pageSize, current * pageSize)
  }

  const onPaginationChange = page => setCurrentPage(page)
  const onSearch = value => {
    setSearch(value)
    setCurrentPage(1)
  }

  const getIdealPath = (data = []) => {
    if (data.length === 0)
      return (
        <Result
          status="warning"
          title="Could not display graph"
          subTitle="Sorry, this maze has no scenarios."
        />
      )
    var nest = getNestedChildren(data)
    if (nest) return generateIdealPath(data, nest[0])
  }

  const generateIdealPath = (data, JSON) => {
    var idealPath = []
    function checkIdealCondition(arr) {
      if (arr.length === 0 || arr === undefined) return
      arr.forEach(item => {
        if (item.values.is_ideal_option) {
          const scenario = item.children
          delete item.children
          idealPath.push(item)
          if (scenario.length === 0) return
          const scenarioChildren = scenario[0].children
          delete scenario[0].children
          idealPath.push(scenario[0])
          checkIdealCondition(scenarioChildren)
        }
      })
    }

    function getIdealPath() {
      if (JSON.code === 1) {
        const children = JSON.children
        delete JSON.children
        idealPath.push(JSON)
        checkIdealCondition(children)
      }
    }

    getIdealPath()

    var ids = new Set(idealPath.map(d => d.code))
    var merged = [...idealPath, ...data.filter(d => !ids.has(d.code))]
    const formattedData = merged.map(item => {
      const isIdealPath = idealPath.find(e => e.code === item.code)
      if (isIdealPath) {
        return { ...item, pathType: 'IDEAL' }
      }
      return item
    })

    const treeData = getNestedChildren(
      formattedData.sort((a, b) => a.code - b.code)
    )

    return (
      <Row>
        <Col span={17}>
          <div
            id="treeContainer"
            style={{
              height: '50vh',
              background: 'white'
            }}
          >
            <Tree
              data={treeData}
              pathClassFunc={getPathClass}
              collapsible={false}
              renderCustomNodeElement={params =>
                renderRectSvgNode(params, 'mazeHostReportTooltip')
              }
              zoom={0.65}
            />
            <div id="mazeHostReportTooltip"></div>
          </div>
        </Col>
        <Col span={1} />
        <Col span={6}>
          <div className="legend-container">
            <div className="legend-item">
              <div
                className="legend-item__shape"
                style={{ backgroundColor: 'green' }}
              ></div>
              {t('v2:ideal_path')}
            </div>
            <div className="legend-item">
              <div
                className="legend-item__shape"
                style={{ backgroundColor: 'red' }}
              ></div>
              {t('v2:incorrect_path')}
            </div>
            <div className="legend-item">
              <div
                className="legend-item__shape"
                style={{
                  backgroundColor: '#0967c0',
                  height: '20px',
                  borderRadius: '50%'
                }}
              ></div>
              {t('v2:scenario')}
            </div>
            <div className="legend-item">
              <div
                className="legend-item__shape"
                style={{
                  backgroundColor: '#605890',
                  height: '20px',
                  borderRadius: '50%'
                }}
              ></div>
              {t('v2:option')}
            </div>
          </div>
        </Col>
      </Row>
    )
  }

  const renderMazePlayGraph = record => {
    return (
      <div className="lesson-table">
        <table>
          <thead>
            <tr>
              <th>{t('v2:play')}</th>
              <th>{t('v2:time_spent')}</th>
              <th>{t('reports:score')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {record &&
              record.plays.map((play, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{getMinutes(play.created, play.updated)}</td>
                  <td>{play.score}</td>
                  <td
                    className="view-graph"
                    onClick={() => {
                      setIdealPath(play.paths)
                    }}
                  >
                    {t('v2:view_graph')}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }

  const setIdealPath = (paths) => {
    if (!paths || !Array.isArray(paths.chosen) || !Array.isArray(paths.ideal)) {
      message.warn(t("v4:maze_path_info"))
      return;
    }

    const ids = new Set(paths.chosen.map(d => d.code));
    const merged = [...paths.chosen, ...paths.ideal.filter(d => !ids.has(d.code))];
    const formattedData = merged.map(item => {
      const isIdealPath = paths.ideal.find(e => e.code === item.code);
      const chosenPath = paths.chosen.find(e => e.code === item.code);
      if (isIdealPath && chosenPath) {
        return { ...item, pathType: 'CHOSEN_IDEAL' };
      } else if (isIdealPath) {
        return { ...item, pathType: 'IDEAL' };
      }
      return item;
    });

    const treeData = getNestedChildren(formattedData);
    setIdealPathData(treeData[0]);
    setShowMazeGraphModal(true)
  };

  const getPathClass = ({ source, target }) => {
    if (
      source.data.pathType === 'CHOSEN_IDEAL' &&
      target.data.pathType === 'CHOSEN_IDEAL'
    ) {
      return 'user_right_path'
    } else if (source.data.pathType && target.data.pathType) {
      return 'ideal_path_link'
    }
    return 'wrong_path_link'
  }

  const showPopup = (evt, data, id) => {
    const mypopup = document.getElementById(id)
    mypopup.innerHTML = `<h3>${data.type}</h3><p>${data.content || 'N/A'}</p>`
    mypopup.style.display = 'block'
    mypopup.style.left = evt.pageX - 50 + 'px'
    mypopup.style.top = evt.pageY - 240 + 'px'
  }

  const hidePopup = id => {
    const mypopup = document.getElementById(id)
    mypopup.style.display = 'none'
  }

  const renderRectSvgNode = (params, id) => {
    const { nodeDatum } = params
    return (
      <>
        <g>
          <circle
            r="40"
            pointerEvents="all"
            stroke="#E9E9E9"
            stroke-width="3"
            fill={nodeDatum.type === 'Option' ? '#605890' : '#0967c0'}
            x="-10"
            id="myicon"
            onMouseOver={evt => showPopup(evt, nodeDatum, id)}
            onMouseOut={evt => hidePopup(id)}
          />
          <text
            strokeWidth="1"
            id="thingyouhoverover"
            x="-20"
            y="70"
            font-size="14"
          >
            {nodeDatum.type}
          </text>
        </g>
      </>
    )
  }

  const mazes = useMemo(() => getData(currentPage, 10), [
    data,
    currentPage,
    search
  ])

  console.log('check data log', total)

  return (
    <Fragment>
      <ReportTable
        isDateActive
        borderRadius
        columns={isHost ? hostMazeColumns : participantMazeColumns}
        loading={loading}
        dataSource={mazes}
        expandIconAsCell={false}
        expandIconColumnIndex={4}
        expandIcon={customExpandIcon}
        expandRowByClick
        pagination={false}
        expandedRowRender={record => {
          if (isHost) {
            return getIdealPath(record && record.contents)
          }
          return renderMazePlayGraph(record)
        }}
        title={() => (
          <>
            <div className="user-report-table-title">
              <div>
                <SearchEventMargin>
                  <Search
                    placeholder={t('v4:search_maze')}
                    onChange={e => onSearch(e.target.value)}
                  />
                </SearchEventMargin>
              </div>
              <div className="user-report-table-pagination">
                <span>{t('general:page').toUpperCase()}</span>
                <PaginationRightAligned>
                  <Pagination
                    total={total}
                    simple
                    current={currentPage}
                    pageSize={10}
                    onChange={onPaginationChange}
                  />
                </PaginationRightAligned>
              </div>
            </div>
            <Divider style={{ margin: '0px' }} />
          </>
        )}
      />
      {showMazeGraphModal && (
        <Modal
          visible={showMazeGraphModal}
          footer={false}
          width="70%"
          onCancel={() => {
            setShowMazeGraphModal(false)
            setIdealPathData([])
          }}
        >
          <Row>
            <Col span={18}>
              <div
                id="treeContainer"
                style={{
                  height: '50vh',
                  background: 'white'
                }}
              >
                <Tree
                  data={idealPathData}
                  pathClassFunc={getPathClass}
                  collapsible={false}
                  renderCustomNodeElement={params =>
                    renderRectSvgNode(params, 'mazeReportTooltip')
                  }
                  zoom={0.65}
                />
                <div id="mazeReportTooltip"></div>
              </div>
            </Col>
            <Col span={6}>
              <div className="legend-container" style={{ margin: 'auto 0' }}>
                <div className="legend-item">
                  <div
                    className="legend-item__shape thick__line"
                    style={{ backgroundColor: 'teal' }}
                  ></div>
                  {t('v2:ideal_path_taken')}
                </div>
                <div className="legend-item">
                  <div
                    className="legend-item__shape"
                    style={{ backgroundColor: 'green' }}
                  ></div>
                  {t('v2:ideal_path')}
                </div>
                <div className="legend-item">
                  <div
                    className="legend-item__shape"
                    style={{ backgroundColor: 'red' }}
                  ></div>
                  {t('v2:user_incorrect_path')}
                </div>
                <div className="legend-item">
                  <div
                    className="legend-item__shape"
                    style={{
                      backgroundColor: '#0967c0',
                      height: '20px',
                      borderRadius: '50%'
                    }}
                  ></div>
                  {t('v2:scenario')}
                </div>
                <div className="legend-item">
                  <div
                    className="legend-item__shape"
                    style={{
                      backgroundColor: '#605890',
                      height: '20px',
                      borderRadius: '50%'
                    }}
                  ></div>
                  {t('v2:option')}
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
      )}
    </Fragment>
  )
}

export default UserMetaMazeTable
