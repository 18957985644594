import React, { useEffect, useRef, useState } from 'react'

import './AiQuiz.scss'
import { Button, Icon, Modal, Popconfirm, Tooltip, message } from 'antd'
import { useTranslation } from 'react-i18next'
import QuizQuestions from '../QuizQuestions'

import DeleteIcon from '../../../../../../assets/bin.svg'
import QuizCreation from '../QuizCreation'
import { api } from '../../../../../../services'
import { useDispatch } from 'react-redux'

import * as lessonBuilderActions from '../../../../../../store/actions/lessonBuilder'

import nanoid from 'nanoid'

import AiQuizPageSelection from './AiQuizPageSelection'

const AiQuizCreation = props => {
  const {
    data,
    onCancel,
    previewData,
    lessonUpdate,
    pages,
    currentPage
  } = props

  const { handleLessonInput } = lessonBuilderActions

  const { t } = useTranslation()

  const [quizData, setQuizData] = useState(null)
  const [editQuestion, setEditQuestion] = useState(null)
  const [quizSetupData, setQuizSetupData] = useState(null)
  const [quizSetupError, setQuizSetupError] = useState(null)
  const [isSavingQuiz, setIsSavingQuiz] = useState(false)
  const [showQuizPositionModal, setShowQuizPositionModal] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setQuizData(data)
  }, [data])

  const onEditQuestion = uid => {
    setEditQuestion(uid)
  }

  const updateCurrentQuizQuestions = val => {
    const updatedQuizObj = val[editQuestion]

    const newData =
      quizData &&
      quizData.data &&
      quizData.data.map(item => {
        if (item.uid === editQuestion) {
          return { ...updatedQuizObj, uid: editQuestion }
        }

        return item
      })

    setQuizData({
      ...quizData,
      data: newData
    })

    message.success(`${t('v4:quiz_updated_successfully')}`)

    setEditQuestion(null)
  }

  const handleAddNewQuizElement = val => { }

  const onRemoveQuestion = uid => {
    const newData =
      quizData && quizData.data && quizData.data.filter(q => q.uid !== uid)

    setQuizData({
      ...quizData,
      data: newData
    })

    message.success(`${t('v4:quiz_updated_successfully')}`)
  }

  const onAddToPage = () => {
    if (quizSetupData) {
      if (validateObject(quizSetupData)) {
        setShowQuizPositionModal(true)
        setQuizSetupError(false)
      } else {
        setQuizSetupError(true)
      }
    } else {
      setQuizSetupError(true)
    }
  }

  const getPositionPageId = position => {
    if (pages && pages.length > 0) {
      switch (position) {
        case 'current':
          return pages[currentPage - 1] && pages[currentPage - 1].id

        case 'start':
        case 'end':
        default:
          return null
      }
    }
  }

  const saveQuiz = position => {
    const { lessonId, courseId } = props

    const pageId = getPositionPageId(position)

    const payload = {
      ...quizSetupData,
      is_restricted: Object.keys(quizSetupData).includes('is_restricted')
        ? quizSetupData.is_restricted
        : false,
      lesson_id: lessonId,
      course_id: courseId,
      page_id: pageId
    }

    try {
      setIsSavingQuiz(true)
      api.quizes
        .quizCreate(payload)
        .then(res => {
          api.quizes
            .quizUpdate(
              {
                questions: quizData.data
              },
              res.data.id
            )
            .then(() => {
              handleData(res.data.id, pageId, position)
            })
        })
        .catch(err => {
          console.log(err)
        })
    } catch (err) {
      message.error('Something went wrong ! Please try again.')
      setIsSavingQuiz(false)
    }
  }

  const handleData = (id, pageId, position) => {
    const coordinates = {
      x: 0,
      y: 0,
      ...(position === 'current'
        ? {
          height: 200,
          width: 400
        }
        : {
          width: 1023,
          height: 552
        })
    }

    const newPage = {
      data: [],
      orientation: 'portrait',
      size: { width: 1024, height: 768 }
    }

    const newData = {
      uid: nanoid(8),
      type: 'QUIZ',
      params: {
        content: id,
        questions_count: quizData.data.length,
        ...coordinates
      }
    }

    const newPageData = {
      size: { width: 1024, height: 768 },
      orientation: 'landscape',
      data: [newData]
    }

    let newPageStructureData
    let newPages = [...pages]

    if (position === 'current') {
      newPageStructureData =
        previewData &&
        previewData.map(item => {
          if (item.id === pageId) {
            return {
              ...item,
              data: [...item.data, newData]
            }
          }
          return item
        })
    } else if (position === 'start') {
      newPageStructureData = [{ ...newPageData }, ...previewData]
      newPages.unshift(newPage)
    } else if (position === 'end') {
      newPageStructureData = [...previewData, { ...newPageData }]
      newPages.push(newPage)
    }

    newPages.forEach((page, index) => {
      page.ordering = index
    })
    newPageStructureData.forEach((page, index) => {
      page.ordering = index
    })

    update(newPages, newPageStructureData)
  }

  const update = async (newPages, updatedData) => {
    if (currentPage !== 1) {
      await dispatch(handleLessonInput('currentPage', 1))
    }
    await dispatch(handleLessonInput('pages', newPages))
    await dispatch(handleLessonInput('pages_landscape', updatedData))
    await lessonUpdate({ pages: newPages, pages_landScape: updatedData })
    message.success(`${t('v4:quiz_created_successfully')}`)
    onCancel(updatedData)
  }

  function validateObject(obj) {
    for (const key in obj) {
      if (key !== 'is_restricted' && !obj[key]) {
        return false
      }
    }
    return true
  }

  const handleQuizSetupData = async data => {
    setQuizSetupData(data)
    const isValid = await validateObject(data)
    setQuizSetupError(!isValid)
  }

  const quizQuestions = quizData && quizData.data

  const editQuizData = editQuestion
    ? quizData.data.find(q => q.uid === editQuestion)
    : null

  return (
    <Modal
      visible
      footer={null}
      centered
      className="ai-quiz-creation-main-sec"
      closable={false}
    >
      <div className="ai-quiz-creation-main-header">
        <h1>{t('v4:create_quiz')}</h1>
        <Icon onClick={onCancel} className="close-icon" type="close" />
      </div>

      <div className="ai-quiz-container">
        <div
          className={`ai-quiz-creation-container quiz-setup ${quizSetupError ? 'error' : ''
            }`}
        >
          <div className="ai-quiz-creation-header">
            <h2>{t('v4:quiz_setting')}</h2>
          </div>
          <QuizCreation isAiCreation onQuizSetupData={handleQuizSetupData} />

          {quizSetupError && (
            <div className="quiz-setup-error">
              {t('v4:please_fill_quiz_setting')}
            </div>
          )}
        </div>
        <div className="ai-quiz-creation-main">
          <div className="ai-quiz-creation-container">
            <div className="ai-quiz-creation-header">
              <h2>{t('v4:question_list')}</h2>
              <span>
                Total {quizQuestions && quizQuestions.length} {quizQuestions && quizQuestions.length > 1 ? t("v3:items") : t('v2:item')}
              </span>
            </div>

            <div className="ai-quiz-questions-container">
              {quizQuestions &&
                quizQuestions.map((question, index) => (
                  <div
                    className={`ai-quiz-questions-question ${question.uid === editQuestion ? 'selected' : ''
                      }`}
                    key={index}
                  >
                    <span>{index + 1}.</span>
                    <p onClick={() => onEditQuestion(question.uid)}>
                      {question.title}
                    </p>

                    <Popconfirm
                      title={t('warnings:delete')}
                      okText={t('general:yes')}
                      cancelText={t('general:no')}
                      icon={false}
                      onConfirm={() => onRemoveQuestion(question.uid)}
                    >
                      <Tooltip title={t('v4:delete')}>
                        <img
                          className="ai-quiz-question-delete"
                          src={DeleteIcon}
                          alt="delete-icon"
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                ))}
            </div>

            <div className="ai-quiz-questions-action">
              <Button
                shape="round"
                size="large"
                className="ai-quiz-questions-action-btn"
                onClick={onAddToPage}
                loading={isSavingQuiz}
              >
                {t('v4:add_quiz_to_page')}
              </Button>
            </div>
          </div>

          {editQuestion && (
            <div className="ai-quiz-creation-container">
              <div className="ai-quiz-creation-header">
                <h2>{t('v4:form_create_question')}</h2>
              </div>
              <div className="ai-quiz-edit-question-container">
                <QuizQuestions
                  isAiCreation
                  currentQuiz={editQuizData}
                  updateCurrentQuizQuestions={updateCurrentQuizQuestions}
                  data={editQuizData}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {showQuizPositionModal && (
        <AiQuizPageSelection
          onClose={() => setShowQuizPositionModal(false)}
          onPosition={saveQuiz}
        />
      )}
    </Modal>
  )
}

export default AiQuizCreation
