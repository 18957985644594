/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 15:55:38
 * @LastEditTime: 2019-08-22 14:08:16
 * @LastEditors: Please set LastEditors
 */
const modes = {
  PAGE: 'PAGE',
  ANNOTATION: 'ANNOTATION'
}

const types = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  BADGE: 'BADGE',
  ICON: 'ICON',
  CERTIFICATE: 'CERT',
  SIGNATURE: 'SIGNATURE',
  LOGO: 'LOGO',
  IMAGEGIF: 'image/gif',
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  QUIZ: 'QUIZ',
  ANNOTATION: 'ANNOTATION',
  BACKGROUND_IMAGE: 'BACKGROUND_IMAGE',
  BACKGROUND_COLOR: 'BACKGROUND_COLOR',
  ARIMAGE: 'ARIMAGE',
  BACKGROUND_IMAGE_AUTO: 'BACKGROUND_IMAGE_AUTO',
  VIDEO_STREAM: 'VIDEO_STREAM',
  VIDEO_ANTMEDIA: 'VIDEO_ANTMEDIA',
  MEDIA_LIBRARY: 'MEDIA_LIBRARY',
  ASSESSMENT: 'ASSESSMENT',
  EVENT: 'EVENT',
  PDF: 'APPLICATION',
  APPLICATION: 'APPLICATION',
  GRID: 'GRID',
  SCENARIO: 'SCENARIO'
}

const icons = {
  [types.TEXT]: 'font-colors',
  [types.IMAGE]: 'picture',
  [types.AUDIO]: 'sound',
  [types.VIDEO]: 'play-circle',
  [types.QUIZ]: 'check-circle',
  [types.ANNOTATION]: 'message',
  [types.BACKGROUND_IMAGE]: 'picture',
  [types.AR_IMAGE]: 'code-sandbox',
  [types.CHAT]: 'message',
  [types.MEDIA_LIBRARY]: 'video-camera',
  [types.GRID]: 'number'
}

const toolbar = [
  {
    name: 'Grid',
    type: types.GRID,
    icon: icons[types.GRID],
    mode: [modes.PAGE]
  },
  {
    name: 'Text',
    type: types.TEXT,
    icon: icons[types.TEXT],
    mode: [modes.PAGE, modes.ANNOTATION]
  },
  {
    name: 'Media Library',
    type: types.MEDIA_LIBRARY,
    icon: icons[types.MEDIA_LIBRARY],
    mode: []
  },
  {
    name: 'Image',
    type: types.IMAGE,
    icon: icons[types.IMAGE],
    mode: [modes.PAGE, modes.ANNOTATION]
  },
  {
    name: 'Video',
    type: types.VIDEO,
    icon: icons[types.VIDEO],
    mode: [modes.PAGE, modes.ANNOTATION]
  },
  {
    name: 'Audio',
    type: types.AUDIO,
    icon: icons[types.AUDIO],
    mode: [modes.PAGE, modes.ANNOTATION]
  },
  {
    name: 'AR',
    type: types.AR_IMAGE,
    icon: icons[types.AR_IMAGE],
    mode: [modes.PAGE]
  },
  {
    name: 'Quiz',
    type: types.QUIZ,
    icon: icons[types.QUIZ],
    mode: [modes.PAGE]
  },
  {
    name: 'Annotation',
    type: types.ANNOTATION,
    icon: icons[types.ANNOTATION],
    mode: [modes.PAGE]
  },
  {
    name: 'Background',
    type: types.BACKGROUND_IMAGE,
    icon: icons[types.BACKGROUND_IMAGE],
    mode: [modes.PAGE, modes.ANNOTATION]
  }
]

export { modes, types, icons, toolbar }
