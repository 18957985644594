import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Card as AntdCard, Col, Pagination, Row, Tabs } from 'antd'
import { useTranslation, withTranslation } from 'react-i18next'
import { Card } from '../../../../../components'
import { api } from '../../../../../services'
import TrainingListActions from '../components/TrainingListActions'
import TrainingListContainer from '../components/TrainingListContainer'
import { Container, Draggable } from 'react-smooth-dnd'

import { types } from '../types'
import { useSelector } from 'react-redux'
import TrainingSequence from '../components/TrainningSquence'

const { TabPane } = Tabs

const PAGE_LIMIT = 12

function EventList(props) {
  const [events, setEvents] = useState([])
  const [total, setTotal] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [search, setSearch] = useState('')
  const [activeEventType, setActiveEventType] = useState('all')
  const [scheduleState, setScheduleState] = useState('upcoming')
  const [pageOffset, setPageOffset] = useState(0)
  const [sequenceState, setSequenceState] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [seqList, setSeqList] = useState([])
  const [newSequence, setNewSequence] = useState([])

  const userData = useSelector(state => state.user.info)
  const isOrganizationActive = Boolean(
    userData && userData.active_organization_id
  )

  const eventTypes = {
    all: 'All',
    webinar: 'Webinar',
    public_conference: 'Public Conference',
    coaching_session: 'Coaching Session',
    ...(isOrganizationActive && {
      private_conference: 'Private Conference',
      live_assessment: 'Live Assessment'
    })
  }

  useEffect(() => {
    const fetchEvents = () => {
      if (!loading) setLoading(true)
      api.events
        .get({
          mode: 'all',
          schedule_state: scheduleState,
          event_type: activeEventType,
          offset: pageOffset,
          search: search,
          limit: PAGE_LIMIT
        })
        .then(res => {
          setEvents(res.data.data)
          if (isInitialLoad) {
            setSeqList(res.data.data.slice(0, 10))
            setIsInitialLoad(false)
          }
          setTotal(res.data.pagination.total)
          setLoading(false)
          setError(false)
        })
        .catch(err => {
          setEvents([])
          setTotal(null)
          setLoading(false)
          setError(true)
        })
    }

    fetchEvents()
  }, [activeEventType, pageOffset, scheduleState, search])

  const handleTabChange = key => {
    const eventTypesArray = Object.keys(eventTypes)
    const selectedEventType = eventTypesArray[key - 1]
    setPageOffset(0)
    setActiveEventType(selectedEventType === 'all' ? 'all' : selectedEventType)
  }

  const handlePagination = pageNumber =>
    setPageOffset(pageNumber * PAGE_LIMIT - PAGE_LIMIT)

  const handleTagSelect = tag => {
    setPageOffset(0)
    setScheduleState(tag)
  }

  const handleSearch = searchValue => {
    setPageOffset(0)
    setSearch(searchValue)
  }

  const onCardClick = ({ id }) => props.history.push(`/events/${id}`)

  const currentPageNumber = useMemo(() => pageOffset / 10 + 1, [pageOffset])

  const showItems = !loading && events.length > 0
  const handleSaveSequence = () => {
    if (newSequence.length > 0) {
      console.log('test save', newSequence)
    }
    setSequenceState(false)
  }
  const { t } = useTranslation()
  return (
    <TrainingListContainer title={t('v2:events')}>
      <TrainingListActions
        type={types.EVENT}
        loading={loading}
        onTagSelect={handleTagSelect}
        onListSearch={handleSearch}
        sequenceState={sequenceState}
        handleSaveSequence={handleSaveSequence}
        openSequenceState={val => {
          setSequenceState(val)
        }}
      />{' '}
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={sequenceState ? 16 : 24}>
          <Tabs defaultActiveKey="1" onChange={handleTabChange}>
            {Object.keys(eventTypes).map((eventType, index) => (
              <TabPane
                disabled={loading}
                tab={eventTypes[eventType]}
                key={index + 1}
              >
                {showItems && (
                  <div className="training-list-pagination">
                    <h2>
                      {search && (
                        <>
                          {t('v4:search_result')} <u>{search}</u> -
                        </>
                      )}{' '}
                      {events.length} {events.length <= 1 ? t('v2:item') : t('v3:items')}
                    </h2>
                  </div>
                )}
                <div className="training-list-cards">
                  {loading ? (
                    Array.from(Array(PAGE_LIMIT).keys()).map(item => (
                      <AntdCard style={{ width: 250 }} loading>
                        {t('v4:this_card')}
                      </AntdCard>
                    ))
                  ) : error ? (
                    <Alert type="error" message="Error !" />
                  ) : events.length === 0 ? (
                    <Alert
                      message={
                        search
                          ? `Search for '${search}' - No results available !`
                          : `No ${scheduleState} ${activeEventType ||
                              'event'}s available !`
                      }
                    />
                  ) : (
                    events.map((item, index) => (
                      <Container
                        key={index}
                        groupName="1"
                        behaviour="copy"
                        getChildPayload={() => item}
                      >
                        <Draggable payload={item}>
                          <Card
                            type={types.EVENT}
                            mode="enrolled"
                            data={{ ...item, type: 'Live Event' }}
                            onClick={onCardClick}
                            tagName="live_event"
                          />
                        </Draggable>
                      </Container>
                    ))
                  )}
                </div>
              </TabPane>
            ))}
          </Tabs>
          <div className="pagination-wrapper">
            {showItems && (
              <Pagination
                disabled={loading}
                total={total}
                pageSize={PAGE_LIMIT}
                onChange={handlePagination}
                defaultCurrent={currentPageNumber}
              />
            )}
          </div>
        </Col>
        {sequenceState && (
          <Col xs={24} sm={24} md={8}>
            <TrainingSequence
              seqList={seqList}
              setNewSequence={setNewSequence}
              loading={loading}
              type={types.EVENT}
            />
          </Col>
        )}
      </Row>
    </TrainingListContainer>
  )
}

export default withTranslation()(EventList)
