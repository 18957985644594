import React, { Component } from 'react'
import { Button, Tooltip } from 'antd'
import { Field } from '../../../../../../../styles'
import { withTranslation } from 'react-i18next'
import './styles.scss'

class PrivacySelector extends Component {
  onClick = id => {
    const { onChange } = this.props

    if (onChange) {
      onChange(id)
    }
  }

  render() {
    const { types, t, isAdmin, checkSelectedType } = this.props
    console.log('isAdmin', isAdmin, types)
    return (
      <Field>
        <div className="publish-option">
          {/* <Field.Label>{t('labels:privacy_settings')}</Field.Label>
        <div style={{ paddingBottom: '10px' }}>
          {!isAdmin && (
            <Trans i18nKey="helpers:public_description_normal">
              <span>{t('labels:course')}</span>
            </Trans>
          )}
          {isAdmin && (
            <Trans i18nKey="helpers:public_description_admin">
              <span>{t('labels:course')}</span>
            </Trans>
          )}
        </div> */}
          <div>
            <h3 className="head__title">{t('v4:publish_options')}</h3>
          </div>
          <Field.Control>
            <div className="publish-option-row">
              {types.map(type => (
                <div key={type.id} className="publish-option-buttons">
                  <div className="publish-option-btn">
                    {type.name === 'Marketplace' && type.disabled ? (
                      <Tooltip
                        placement="bottom"
                        title={
                          isAdmin ? t('v4:not_complete') : t('v4:not_admin')
                        }
                      >
                        <Button icon="exclamation-circle" block disabled>
                          {type.name}
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        block
                        type={checkSelectedType(type.id) ? 'primary' : ''}
                        onClick={() => this.onClick(type.id)}
                        disabled={type.disabled}
                      >
                        {type.name}
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Field.Control>
        </div>
      </Field>
    )
  }
}

export default withTranslation()(PrivacySelector)
