import React, { Fragment, useEffect, useMemo, useState } from 'react'
import './CreateChannelForm.scss'

import api from '../../../../../services/api'
import ChannelFormVideos from './components/ChannelFormVideos/'
import ChannelFormMain from './components/ChannelFormMain/'
import ChannelFormMarketplace from './components/ChannelFormMarketplace'
import ChannelFormPublish from './components/ChannelFormPublish'
import CreateChannelFooter from '../CreateChannelFooter'
import ChannelContext from './ChannelContext'
import CreateChannelHeader from '../CreateChannelHeader'
import { Spinner } from '../../../../../components'
import _ from 'lodash'
import history from '../../../../../history'
import EmptyMessage from '../../../../../components/EmptyMessage/EmptyMessage'

function CreateChannelForm({ channelId, newAiVideo, trainerInfo }) {
  const [channelApiData, setChannelApiData] = useState([])
  const [inputChannelData, setInputChannelData] = useState([])
  const [savingChannel, setSavingChannel] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isMarketplaceView, setIsMarketplaceView] = useState(false)
  const [isCheckedTerms, setIsCheckedTerms] = useState(false)
  const [publishSettings, setPublishSettings] = useState({})
  const [isPublishing, setIsPublishing] = useState(false)
  const [marketplaceData, setMarketplaceData] = useState({})
  const [isChannelError, setIsChannelError] = useState(false)

  useEffect(() => {
    if (channelId && _.isEmpty(channelApiData)) {
      fetchChannelData()
    }
  }, [])

  const fetchChannelData = () => {
    setLoading(true)
    api.channel
      .getChannel(channelId)
      .then(res => {
        const { marketplace = {}, ...response } = res.data
        setChannelApiData(response)
        updateInputChannelData(response)
        if (marketplace) {
          setDefaultMarketplaceData(marketplace)
        }
        setLoading(false)
        setIsChannelError(false)
      })
      .catch(err => {
        setLoading(false)
        setIsChannelError(true)
      })
  }

  const setDefaultMarketplaceData = data => {
    const {
      linkedin = null,
      bio = null,
      is_free = false,
      price_monthly = null,
      price_yearly = null,
      price_one_time = null,
      is_shared_profile = false,
      course_obj = '',
      course_outline = '',
      trainer = "",
      username = "",
    } = data
    setMarketplaceData({
      bio,
      trainer,
      linkedin,
      username,
      is_free,
      price_monthly,
      price_yearly,
      price_one_time,
      is_shared_profile,
      course_obj,
      course_outline
    })
  }

  const updateInputChannelData = ({
    title = '',
    description = '',
    host_emails = [],
    topics = [],
    cover,
    collections_videos = []
  }) => {
    setInputChannelData({
      title,
      description,
      host_emails,
      topics,
      cover,
      videos: collections_videos.map((item, index) => mapVideoData(item, index))
    })
  }

  const mapVideoData = (video, index) => ({
    id: video.id,
    type: video.type || null,
    title: video.title || video.filename || '',
    order: index,
    ...(video.hasOwnProperty('isAiEnabled') && {
      isAiEnabled: video.isAiEnabled,
      ai_language: video.ai_language
    })
  })

  const createChannel = () => {
    if (channelApiData.id || channelId) {
      updateChannel()
      return
    }

    const formData = formatData({
      ...inputChannelData,
      ...(inputChannelData.videos.length > 0 && {
        videos: inputChannelData.videos.map((item, index) =>
          mapVideoData(item, index)
        )
      })
    })

    setSavingChannel(true)
    api.channel
      .createChannel(formatData(formData))
      .then(res => {
        setChannelApiData(res.data)
        setSavingChannel(false)
      })
      .catch(err => channelApiError(err))
  }

  const updateChannel = async (isPublish, videos = []) => {
    const id = channelApiData.id || channelId
    if (!id || savingChannel) return

    const isPublishButtonClicked = isPublish && isPublish === 'publish'

    if (isPublishButtonClicked) {
      setIsPublishing(true)
    } else {
      setSavingChannel(true)
    }
    const toUpdateVideos =
      videos.length > 0 ? videos : inputChannelData.videos || []

    const formData = formatData({
      ...inputChannelData,
      ...(toUpdateVideos &&
        toUpdateVideos.length > 0 && {
        videos: toUpdateVideos.map((item, index) => mapVideoData(item, index))
      })
    })

    const channelDataToUpdate = isPublishButtonClicked
      ? { ...formData, ...publishSettings }
      : formData

    try {
      const res = await api.channel.updateChannel(id, channelDataToUpdate)
      if (isPublishButtonClicked) {
        history.push(`/channel/${id}/`)
        return
      }
      setChannelApiData({
        ...res.data,
        collections_videos:
          res.data.collections_videos || channelApiData.collections_videos
      })
      setSavingChannel(false)
    } catch (error) {
      channelApiError(error)
    }
  }

  const formatData = obj =>
    _.pickBy({
      ...obj,
      cover_id: obj.cover_id || (obj.cover && obj.cover.id),
      ...(channelApiData.id && { marketplace: marketplaceData })
    })

  const channelApiError = err => {
    setSavingChannel(false)
  }

  const onCheckedTerms = (isChecked, privacyObj) => {
    setIsCheckedTerms(isChecked)

    if (isChecked && privacyObj) {
      setPublishSettings(privacyObj)
    }
  }

  const id = useMemo(
    () => channelId || channelApiData.id || inputChannelData.id,
    [channelId, channelApiData.id, inputChannelData.id]
  )

  const canPublish = useMemo(
    () =>
      id &&
      isCheckedTerms &&
      channelApiData.videos &&
      channelApiData.videos.length,
    [id, isCheckedTerms, channelApiData]
  )

  if (loading) {
    return <Spinner />
  }

  if (isChannelError) {
    return <EmptyMessage title="Channel Not Found." />
  }

  return (
    <Fragment>
      <CreateChannelHeader
        channelId={id}
        isMarketplaceView={isMarketplaceView}
        onToggleMarketplace={() => {
          setIsMarketplaceView(prev => !prev)
          if (isMarketplaceView) {
            updateChannel()
          }
        }}
      />
      {isMarketplaceView ? (
        <ChannelFormMarketplace
          channelId={channelId}
          closeMarketplace={() => {
            setIsMarketplaceView(prev => !prev)
          }}
          onToggleMarketplace={() => {
            setIsMarketplaceView(prev => !prev)
            if (isMarketplaceView) {
              updateChannel()
            }
          }}
          marketplaceData={marketplaceData}
          onPrice={priceObj =>
            setMarketplaceData(prev => ({ ...prev, ...priceObj }))
          }
          onMarketplaceValues={marketplaceValues => {
            const {
              info: { first_name, last_name, username, email, avatar, bio, linkedin },
            } = trainerInfo
            marketplaceValues.bio = bio
            marketplaceValues.first_name = first_name
            marketplaceValues.last_name = last_name
            marketplaceValues.username = username
            marketplaceValues.avatar = avatar
            marketplaceValues.email = email
            marketplaceValues.linkedin = linkedin
            setMarketplaceData(prev => ({ ...prev, ...marketplaceValues }))
          }
          }
        />
      ) : (
        <ChannelContext.Provider
          value={{
            id,
            apiData: channelApiData,
            channelData: inputChannelData,
            setChannelData: setInputChannelData,
            marketplaceData: marketplaceData
          }}
        >
          <div className="shl-create-channel-form-container">
            <ChannelFormMain
              id={channelApiData.id || channelId}
              createChannel={createChannel}
              updateChannel={updateChannel}
            />
            <ChannelFormVideos
              updateChannel={updateChannel}
              newAiVideo={newAiVideo}
            />
            <ChannelFormPublish onCheckedTerms={onCheckedTerms} />
          </div>
          <CreateChannelFooter
            onPublish={() => updateChannel('publish')}
            onSaveForLater={updateChannel}
            loadingSave={savingChannel}
            isPublishDisabled={!canPublish}
            loadingPublish={isPublishing}
          />
        </ChannelContext.Provider>
      )}
    </Fragment>
  )
}

export default CreateChannelForm
