import { connect } from 'react-redux'

import MazeRightPanel from './MazeRightPanel'

import actions from '../../../../../../store/actions'

export default connect(
  state => ({
    user: state.user
  }),
  dispatch => ({    
    upload: (file, opts) => dispatch(actions.library.upload(file, opts))    
  })
)(MazeRightPanel)
