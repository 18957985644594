import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { Cards, Scrollbars, Spinner, Search } from '../../../components'
import history from '../../../history'
import { HelpMessage, Row } from './styled'
import { api } from '../../../services'
import trophy from '../../../assets/trophy.png'

import './styles.scss'
import NewHeader from '../../../components/NewHeader'

import Unauthorized from './pages/Unauthorized'

const TrainingCardsRow = ({
  data,
  loading,
  t,
  app,
  type,
  mode,
  title,
  message,
  onSeeMore,
  tagName
}) => (
  <section className="section section_light training__cards">
    <div
      className={`head training_head ${title === 'now_live_event_title' &&
        'red__font'}`}
    >
      <div className="head__title">{t(`${title}`)}</div>
      {!loading && title !== 'now_live_event_title' && data && data.length > 0 && (
        <div onClick={() => onSeeMore(title)} className="head__action">
          {t('v3:see_more').toUpperCase()}
          {'>'}
        </div>
      )}
    </div>

    {loading ? (
      <Spinner size="small" />
    ) : data && data.length ? (
      <Scrollbars
        type="horizontal"
        style={{ width: 'calc(100vw - 21rem)' }}
        minimize="true"
        autoHeight
      >
        <Cards
          data={data}
          type={type}
          width={30}
          mode={mode}
          horizontal
          onClick={app.handleClick}
          tagName={tagName}
        />
      </Scrollbars>
    ) : (
      <HelpMessage>{t(`${message}`)}</HelpMessage>
    )}
  </section>
)

class TrainingPage extends Component {
  _isMount = false
  state = {
    search: '',
    page: 1,
    pageSize: 20,
    score: null,
    scoreLoading: true,
    eventLoading: true,
    eventsData: [],
    nowLiveLoading: true,
    nowLiveData: [],
    mazeLoading: true,
    mazeData: [],
    courseLoading: true,
    courseData: [],
    skillJourneyLoading: true,
    skillJourneyData: [],
    channelData: [],
    channelLoading: true,
    podcastData: [],
    podcastLoading: true,
    permissionValues: {
      training_live: true,
      training_channels: true,
      training_courses: true,
      training_events: true,
      training_podcast: true,
      training_maze: true,
      training_skill: true
    }
  }

  componentDidMount() {
    const {
      user: {
        info: { roles, active_organization_id, is_super_admin, role }
      }
    } = this.props

    const showAll =
      !active_organization_id || is_super_admin || role.name === 'Admin'

    this.updatePermissions(roles, showAll)
  }

  onMount() {
    const {
      user: {
        info: { id, timezone: userTZ, is_reload }
      },
      updateUser
    } = this.props

    this.fetchData()

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const min = new Date().getTimezoneOffset()
    const hr =
      `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60))
    const prefix = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${tz}`

    const timezone = { value: tz, label: prefix }
    if (!userTZ || userTZ.length === 0) {
      const attributes = {
        'custom:timezone': JSON.stringify(timezone)
      }
      updateUser(id, attributes, null)
    }

    if (is_reload) {
      updateUser(id, null, { is_reload: false }).then(res => {
        console.log('OK one time reload dashboard', res)
        setTimeout(() => {
          window.location.reload(true)
        }, 3000)
      })
    }
  }

  updatePermissions = (rolesData, showAll) => {
    if (!showAll) {
      this.setState(
        prevState => {
          const updatedPermissions = { ...prevState.permissionValues }

          // Enable granted permissions
          rolesData.forEach(roleData => {
            if (roleData.role && roleData.role.permissions) {
              roleData.role.permissions.forEach(permission => {
                if (permission.name in updatedPermissions) {
                  updatedPermissions[permission.name] = true
                }
              })
            }
          })

          // Disable non-granted permissions
          Object.keys(updatedPermissions).forEach(key => {
            if (
              !rolesData.some(
                roleData =>
                  roleData.role &&
                  roleData.role.permissions &&
                  roleData.role.permissions.some(
                    permission => permission.name === key
                  )
              )
            ) {
              updatedPermissions[key] = false
            }
          })

          return { permissionValues: updatedPermissions }
        },
        () => this.onMount()
      )
    } else {
      this.onMount()
    }
  }

  fetchData = () => {
    const {
      user: {
        info: { organization, active_organization_id }
      }
    } = this.props

    const { search, permissionValues } = this.state

    if (permissionValues.training_live) {
      this.getNowLiveEventsData(search)
    }

    if (permissionValues.training_channels) {
      this.getChannelData(search)
    }

    if (permissionValues.training_courses) {
      this.getCoursesData(search)
    }

    if (permissionValues.training_events) {
      this.getEventsData(search)
    }

    if (permissionValues.training_skill) {
      this.getSkillJourneyData(search)
    }

    if (permissionValues.training_podcast) {
      this.getPodcastData(search)
    }

    const allowMaze =
      (organization && organization.enable_maze) || !active_organization_id

    if (allowMaze && permissionValues.training_maze) {
      this.getMazeData(search)
    }

    if (active_organization_id) {
      this.fetchScore()
    }
  }

  fetchScore = async () => {
    await api.trivia
      .getScore()
      .then(res => {
        if (res.status < 300 && res.data && res.data.score) {
          this.setState({
            score: res.data.score.toFixed(2)
          })
        }
      })
      .catch(err => {
        console.log('trivia :', err || 'Failed to get score!')
      })
    this.setState({
      scoreLoading: false
    })
  }

  getChannelData = search => {
    api.channel
      .listChannels({
        offset: 0,
        limit: 10,
        search,
        mode: 'training'
      })
      .then(res => {
        this.setState({
          channelData: this.mapByType(res.data, 'Channel'),
          channelLoading: false
        })
      })
      .catch(err => {
        this.setState({
          channelData: [],
          channelLoading: false
        })
      })
  }

  getCoursesData = search => {
    api.courses
      .getCoursesList({
        offset: 0,
        limit: 10,
        order: 'created_at',
        mode: 'enrollment',
        search
      })
      .then(res => {
        this.setState({
          courseData: this.mapByType(res.data.data, 'Course'),
          courseLoading: false
        })
      })
      .catch(err => {
        this.setState({
          courseData: [],
          courseLoading: false
        })
      })
  }

  getEventsData = search => {
    api.events
      .get({
        mode: 'all',
        schedule_state: 'all',
        event_type: 'all',
        offset: 0,
        limit: 10,
        search
      })
      .then(res => {
        this.setState({
          eventsData: this.mapByType(res.data.data, 'Live Event'),
          eventLoading: false
        })
      })
      .catch(err => {
        this.setState({
          eventsData: [],
          eventLoading: false
        })
      })
  }

  getSkillJourneyData = search => {
    api.skillJourney
      .listSkillJourneys({
        limit: 10,
        offset: 0,
        search,
        mode: 'all'
      })
      .then(res => {
        this.setState({
          skillJourneyData: this.mapByType(res.data.data, 'Skill'),
          skillJourneyLoading: false
        })
      })
      .catch(err => {
        this.setState({
          skillJourneyData: [],
          skillJourneyLoading: false
        })
      })
  }

  getMazeData = search => {
    api.maze
      .listMazes({
        offset: 0,
        limit: 10,
        mode: 'training',
        search
      })
      .then(res => {
        this.setState({
          mazeData: this.mapByType(res.data.data, 'Maze'),
          mazeLoading: false
        })
      })
      .catch(err => {
        this.setState({
          mazeData: [],
          mazeLoading: false
        })
      })
  }

  getNowLiveEventsData = search => {
    api.events
      .get({
        mode: 'live',
        schedule_state: 'all',
        event_type: 'all',
        search
      })
      .then(res => {
        this.setState({
          nowLiveData: this.mapByType(res.data.data, 'Live Event'),
          nowLiveLoading: false
        })
      })
      .catch(err => {
        this.setState({
          nowLiveData: [],
          nowLiveLoading: false
        })
      })
  }
  getPodcastData = search => {
    api.events
      .get({
        mode: 'all',
        schedule_state: 'all',
        event_type: 'discussion_room',
        offset: 0,
        limit: 10,
        search
      })
      .then(res => {
        this.setState({
          podcastData: this.mapByType(res.data.data, 'Live Event'),
          podcastLoading: false
        })
      })
      .catch(err => {
        this.setState({
          podcastData: [],
          podcastLoading: false
        })
      })
  }

  mapByType = (arr, type) => arr && arr.map(item => ({ ...item, type: type }))

  handleClick = item => {
    console.log(item)
    if (item.type === 'Course') {
      if (item && item.course_type === 'scorm') {
        history.push(`/scorm/${item.id}`)
      } else {
        history.push(`/courses/${item.id}`)
      }
    } else if (item.type === 'Maze') {
      history.push(`/maze-detail/${item.id}`)
    } else if (item.type === 'Skill') {
      history.push(`/skill-journey/${item.id}`)
    } else if (item.type === 'Channel') {
      history.push(`/channel/${item.id}`)
    } else if (
      item.event_type === 'discussion_room' &&
      item.privacy[0].is_host_only
    ) {
      history.push(`/podcast/${item.id}`)
    } else if (
      item.event_type === 'discussion_room' &&
      !item.privacy[0].is_host_only
    ) {
      history.push(`/podcast/${item.id}/play`)
    } else {
      history.push(`/events/${item.id}`)
    }
  }
  navigateTo = () => {
    history.push(`/share-sphere`)
  }

  handleSeeMore = item => {
    console.log(item, 'clicked')
    if (item === 'v4:now_live_event_title') {
      history.push(`/training/live`)
    } else if (item === 'v4:enrolled_liveEvents') {
      history.push(`/training/events`)
    } else if (item === 'v4:enrolled_courses') {
      history.push(`/training/courses`)
    } else if (item === 'v4:maze_channel') {
      history.push(`/training/metamazes`)
    } else if (item === 'v4:skill_journey') {
      history.push(`/training/skill-journeys`)
    } else if (item === 'v4:my_video_channel') {
      history.push(`/training/channels`)
    } else if (item === 'v3:podcast') {
      history.push(`/training/podcasts`)
    }
  }

  onSearch = _.debounce(
    value =>
      this.setState(
        {
          search: value
        },
        () => {
          this.fetchData()
        }
      ),
    1000
  )

  sortDataWithConcat = (firstData, secData) => {
    const mergeData = [...firstData, ...secData]
    const orderByStartDate = _.orderBy(mergeData, ['start_date'], ['desc'])

    return orderByStartDate
  }

  checkIfNoPermission = () => {
    const { permissionValues } = this.state
    const noPermission = Object.values(permissionValues).every(
      permission => !permission
    )

    return noPermission
  }

  render() {
    const {
      t,
      user: {
        info: { organization, active_organization_id }
      }
    } = this.props
    const {
      nowLiveLoading,
      nowLiveData,
      eventLoading,
      eventsData,
      courseLoading,
      courseData,
      mazeLoading,
      mazeData,
      skillJourneyLoading,
      skillJourneyData,
      channelData,
      channelLoading,
      podcastData,
      podcastLoading,
      score,
      scoreLoading,
      permissionValues
    } = this.state

    const isMazeAllowedInOrg =
      (organization && organization.enable_maze) || !active_organization_id

    const enableSharesphere =
      active_organization_id && organization && organization.enable_sharesphere
    return (
      <>
        <section className="section training-wrapper">
          <Row>
            <div className="head head_nopadding">
              <div className="head__title">{t('v3:learning_center')}</div>
            </div>
            <div className="right_wrapper">
              {enableSharesphere && (
                <div className="trivia_score" onClick={this.navigateTo}>
                  <div className="trivia_score_trophy">
                    <img src={trophy} alt="trophy" />
                  </div>
                  <div className="trivia_score_title">
                    {scoreLoading
                      ? ''
                      : score
                      ? t('v3:final_score')
                      : t('v4:play_game')}
                  </div>
                  <div className="trivia_score_score">{score ? score : ''}</div>
                  <div className="trivia_score_mobile">
                    <div className="score"> {score ? score : ''}</div>
                    <div className="title">
                      {scoreLoading
                        ? ''
                        : score
                        ? t('v3:final_score')
                        : t('v4:play_game')}
                    </div>
                  </div>
                </div>
              )}
              {!this.checkIfNoPermission() && (
                <div className="head head_nopadding search">
                  <Search
                    placeholder={t('placeholders:search_training')}
                    onChange={e => this.onSearch(e.target.value)}
                  />
                </div>
              )}
              <NewHeader />
            </div>
          </Row>
        </section>

        {this.checkIfNoPermission() && (
          <Unauthorized
            header={false}
            button={false}
            history={this.props.history}
          />
        )}

        {/* Now Live Events */}
        {permissionValues.training_live && (
          <TrainingCardsRow
            data={nowLiveData}
            loading={nowLiveLoading}
            title={'training:now_live_event_title'}
            message={'training:now_live_event'}
            type={'Live Event'}
            tagName="live_event"
            t={t}
            app={this}
            onSeeMore={this.handleSeeMore}
          />
        )}

        {/* Video Channel */}
        {permissionValues.training_channels && (
          <TrainingCardsRow
            data={channelData}
            loading={channelLoading}
            title={'v4:my_video_channel'}
            message={'v3:training_no_channel'}
            type={'Channel'}
            tagName="channel"
            t={t}
            app={this}
            onSeeMore={this.handleSeeMore}
          />
        )}

        {/* Enrolled Course */}
        {permissionValues.training_courses && (
          <TrainingCardsRow
            data={courseData}
            loading={courseLoading}
            title={'v4:enrolled_courses'}
            message={'v3:training_no_courses'}
            type={'Course'}
            tagName="course"
            t={t}
            app={this}
            onSeeMore={this.handleSeeMore}
          />
        )}

        {/* Podcasts */}
        {permissionValues.training_podcast && (
          <TrainingCardsRow
            data={podcastData}
            loading={podcastLoading}
            title={'v3:podcast'}
            type={'Podcast'}
            message={'v4:no_podcast'}
            tagName="podcast"
            t={t}
            app={this}
            onSeeMore={this.handleSeeMore}
          />
        )}

        {/* Live Events */}
        {permissionValues.training_events && (
          <TrainingCardsRow
            data={eventsData}
            loading={eventLoading}
            title={'v4:enrolled_liveEvents'}
            sub_title={'enrolled_subtitle'}
            message={'v3:training_no_events'}
            type={'enrolled'}
            tagName="live_event"
            t={t}
            app={this}
            onSeeMore={this.handleSeeMore}
          />
        )}

        {/* Maze */}
        {isMazeAllowedInOrg && permissionValues.training_maze && (
          <TrainingCardsRow
            data={mazeData}
            loading={mazeLoading}
            title={'v4:maze_channel'}
            type={'Maze'}
            message={'v4:no_maze'}
            tagName="maze"
            t={t}
            app={this}
            onSeeMore={this.handleSeeMore}
          />
        )}

        {/* Skill Journey */}
        {permissionValues.training_skill && (
          <TrainingCardsRow
            data={skillJourneyData}
            loading={skillJourneyLoading}
            title={'v4:skill_journey'}
            type={'Skill'}
            message={'v4:no_skill'}
            tagName="skill"
            t={t}
            app={this}
            onSeeMore={this.handleSeeMore}
          />
        )}
      </>
    )
  }
}

export default withTranslation('training')(TrainingPage)
