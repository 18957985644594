import React, { Component } from 'react'
import _ from 'lodash'
import {
  Radio,
  Input,
  Button,
  Popover,
  Tabs,
  Slider,
  Spin,
  Popconfirm,
  InputNumber
} from 'antd'
import { updateMedia } from '../../../../../../../services/api/library'
import './badge.scss'

import background from '../../../../../../../assets/background.svg'
import text from '../../../../../../../assets/text.svg'
import icon from '../../../../../../../assets/icon.svg'
import pencil from '../../../../../../../assets/pencil.svg'
import bin from '../../../../../../../assets/bin.svg'
import iconContainer from '../../../../../../../assets/icon_tsp.png'

import { SketchPicker } from 'react-color'
import {
  BoldOutlined,
  CloseOutlined,
  ItalicOutlined,
  VerticalAlignMiddleOutlined,
  ReloadOutlined,
  LoadingOutlined
} from '@ant-design/icons'
import { VerticalIcon, HorizontalIcon } from '../../images'

import ReactSVG from 'react-svg'
import { withTranslation } from 'react-i18next'

const { TabPane } = Tabs
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

class Badge extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedBadgeTemplate: null,
      text: '',
      templateColor: '',
      textColor: '#000000',
      isTextAdded: false,
      isIconSelected: false,
      selectedIcon: null,
      userUploadedIcon: null,
      userUploadedIconId: null,
      fontSize: 22,
      textDecoration: 'normal',
      iconScaleFactor: null,
      iconColor: null,
      badgeTemplates: [],
      icons: [],
      textOffsetX: null,
      textOffsetY: null,
      curveText: false,
      topSlant: null,
      textRadius: 60,
      iconOffsetX: null,
      iconOffsetY: null,
      badgeName: '',
      badgeDetails: '',
      imageFormat: 'BADGE',
      templateKey: 1,
      iconKey: null,
      badgeLoading: false,
      history: {},
      userBadge: [],
      userIcons: [],
      badgesWithHistory: [],
      historyBadges: [],
      badgeFetching: true,
      iconId: null,
      iconLoading: false,
      toEditBadgeId: null,
      isDeleting: false,
      activeTab: '1'
    }
    this.resizeHandler = () => {
      this.canvas.width = this.canvas.clientHeight
      this.canvas.height = this.canvas.clientWidth
      this.clearAndDraw()
    }
  }

  async componentDidMount() {
    await this.getUserImages('ICON')
    await this.getUserImages('BADGE')
    await this.getAllBadgeTemplates()
    window.addEventListener('resize', this.resizeHandler)
    this.canvas.width = this.canvas.clientHeight
    this.canvas.height = this.canvas.clientWidth
    this.clearAndDraw()
  }

  getHistoryBadges = () => {
    if (this.state.userBadge) {
      const badgesWithHistory = this.state.userBadge.filter(
        item => item.custom_data && item.custom_data.fontSize
      )
      this.setState({
        badgeFetching: false,
        historyBadges: badgesWithHistory
      })
    }
  }

  getUserImages = type => {
    const { getLibraryList } = this.props
    if (type === 'BADGE') {
      this.setState({
        badgeFetching: true
      })
    }
    getLibraryList({
      offset: 0,
      limit: 20,
      type: type,
      sort: 'NEWEST'
    }).then(res => {
      if (type === 'ICON') {
        this.setState({
          userIcons: res.rows,
          iconLoading: false
        })
      } else if (type === 'BADGE') {
        this.setState(
          {
            userBadge: res.rows
          },
          () => {
            this.getHistoryBadges()
          }
        )
      }
    })
  }

  getAllBadgeTemplates = () => {
    const badgeTemplates = this.importAll(
      require.context(
        '../../../../../../../assets/templates/',
        false,
        /\.(png|jpe?g|svg)$/
      )
    )
    const icons = this.importAll(
      require.context(
        '../../../../../../../assets/userIcons/',
        false,
        /\.(png|jpe?g|svg)$/
      )
    )
    const allTemplates = this.importedArray(badgeTemplates)
    const allIcons = this.importedArray(icons)
    this.setState({
      badgeTemplates: allTemplates,
      icons: allIcons
    })
  }

  importedArray = o => {
    let obj = Object.keys(o)
      .map((key, index) => {
        return { [index]: o[key] }
      })
      .reduce((a, b) => Object.assign({}, a, b))
    return obj
  }

  importAll = r => {
    let images = {}
    r.keys().forEach(item => {
      images[item.replace('./', '')] = r(item)
    })
    return images
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  clearAndDraw = () => {
    const {
      templateColor,
      textColor,
      iconScaleFactor,
      curveText,
      isIconSelected,
      isTextAdded,
      fontSize,
      textDecoration,
      textOffsetX,
      textOffsetY,
      textRadius,
      iconOffsetX,
      iconOffsetY,
      iconColor,
      iconKey,
      text,
      templateKey,
      topSlant
    } = this.state
    const history = {
      templateColor,
      textColor,
      iconScaleFactor,
      curveText,
      isIconSelected,
      isTextAdded,
      fontSize,
      textDecoration,
      textOffsetX,
      textOffsetY,
      textRadius,
      iconOffsetX,
      iconOffsetY,
      iconColor,
      text,
      iconKey,
      templateKey,
      topSlant
    }
    this.setState({ history: history })
    let handleSlider = _.debounce(() => this.handleCreateAndDraw(), 100)
    handleSlider()
  }

  handleCreateAndDraw = () => {
    const ctx = this.canvas.getContext('2d')
    if (ctx) {
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.draw(ctx)
    }
  }

  handleTemplateColorChange = color => {
    this.setState(
      {
        templateColor: color.hex
      },
      () => {
        this.clearAndDraw()
      }
    )
  }

  handleTextColorChange = color => {
    this.setState(
      {
        textColor: color.hex
      },
      () => {
        this.clearAndDraw()
      }
    )
  }

  getImageDetails = image => {
    var ratio = image.width / image.height
    var newWidth = this.canvas && this.canvas.width
    var newHeight = newWidth / ratio
    if (newHeight > this.canvas && this.canvas.height) {
      newHeight = this.canvas.height
      newWidth = newHeight * ratio
    }
    var xOffset =
      newWidth < this.canvas.width ? (this.canvas.width - newWidth) / 2 : 0
    var yOffset =
      newHeight < this.canvas.height ? (this.canvas.height - newHeight) / 2 : 0
    return {
      xOffset,
      yOffset,
      newHeight,
      newWidth
    }
  }

  draw = ctx => {
    let image = new Image()
    image.src = this.state.selectedBadgeTemplate || this.state.badgeTemplates[1]
    image.setAttribute('crossorigin', 'anonymous')
    image.onload = () => {
      const { xOffset, yOffset, newWidth, newHeight } = this.getImageDetails(
        image
      )
      ctx.drawImage(image, xOffset, yOffset, newWidth, newHeight)
      if (this.state.templateColor) {
        this.fillBadgeTemplate(ctx)
      }
      let iconImage = new Image()
      iconImage.src = this.state.selectedIcon || iconContainer
      iconImage.setAttribute('crossorigin', 'anonymous')
      iconImage.onload = () => {
        if (this.state.selectedIcon) {
          this.addInsideIcon(ctx, iconImage)
        }
        if (this.state.text) {
          this.addText(ctx)
        }
      }
    }
  }

  fillBadgeTemplate = ctx => {
    ctx.globalCompositeOperation = 'source-in'
    ctx.fillStyle = this.state.templateColor
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
    ctx.globalCompositeOperation = 'source-over'
  }

  addInsideIcon = (ctx, img) => {
    const { iconScaleFactor, iconOffsetX, iconOffsetY, iconColor } = this.state
    const imageCtx = document.createElement('canvas').getContext('2d')
    const insideImage = new Image()
    let height = iconScaleFactor !== null ? (iconScaleFactor / 10) * 80 : 80
    let width = iconScaleFactor !== null ? (iconScaleFactor / 10) * 80 : 80
    imageCtx.canvas.width = height
    imageCtx.canvas.height = width
    if (iconColor && width) {
      imageCtx.save()
      imageCtx.fillStyle = iconColor
      imageCtx.fillRect(0, 0, width, height)
      imageCtx.globalCompositeOperation = 'destination-in'
    }
    imageCtx.drawImage(img, 0, 0, width, height)
    insideImage.src = imageCtx.canvas.toDataURL('image/png')
    let x =
      iconOffsetX === null
        ? this.canvas.width / 2 - height / 2
        : this.canvas.width / 2 - height / 2 + iconOffsetX
    let y =
      iconOffsetY === null
        ? this.canvas.height / 2 - (3 * height) / 4
        : this.canvas.height / 2 - (3 * height) / 4 + iconOffsetY
    insideImage.onload = () => {
      ctx.drawImage(insideImage, x, y, width, height)
    }
  }

  addText = ctx => {
    const {
      textOffsetX,
      textOffsetY,
      textColor,
      textDecoration,
      fontSize
    } = this.state
    ctx.textAlign = 'center'
    ctx.fillStyle = textColor
    ctx.font = `${textDecoration} ${fontSize}px Perpetua`
    let width = textOffsetX
      ? this.canvas.width / 2 + textOffsetX
      : this.canvas.width / 2
    let height = textOffsetY
      ? this.canvas.height / 1.5 + this.state.fontSize / 4 + textOffsetY
      : this.canvas.height / 1.5 + this.state.fontSize / 4
    if (this.state.curveText) {
      this.textCircle(ctx, width, height)
    } else {
      ctx.fillText(this.state.text, width, height)
      ctx.restore()
    }
  }

  onChange = e => {
    let key = e.target.value
    this.setState(
      state => ({
        selectedBadgeTemplate: state.badgeTemplates[key],
        templateKey: key
      }),
      () => {
        this.clearAndDraw()
      }
    )
  }

  onIconChange = e => {
    let key = e.target.value

    this.setState(
      state => ({
        selectedIcon: state.icons[key],
        iconKey: key,
        isIconSelected: true,
        iconId: null
      }),
      () => {
        this.clearAndDraw()
      }
    )
  }

  onUserIconChange = async item => {
    console.log(item, 'text1')
    let blob = await fetch(item.link).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    this.setState(
      () => ({
        selectedIcon: data,
        iconKey: null,
        isIconSelected: true,
        iconId: item.id
      }),
      () => {
        this.clearAndDraw()
      }
    )
  }

  uploadBadge = () => {
    const { onUploadImage } = this.props
    const body = this.getImageInfo(true)
    onUploadImage(body)
  }

  saveUserBadge = () => {
    this.setState(
      {
        imageFormat: 'BADGE',
        badgeLoading: this.state.isDeleting ? false : true
      },
      () => {
        const { upload } = this.props
        const { toEditBadgeId } = this.state
        const body = this.getImageInfo(true)
        if (toEditBadgeId === null) {
          upload(body)
            .then(() => {
              this.setState(
                {
                  badgeLoading: false
                },
                () => {
                  this.getUserImages('BADGE')
                }
              )
            })
            .catch(error => {
              console.error('Error uploading badge:', error)
            })
        } else {
          const { name, imgFormat, imgDesc, customData, contentId } = body
          updateMedia(toEditBadgeId, {
            filename: name,
            customData,
            contentId,
            description: imgDesc,
            format: imgFormat
          })
            .then(() => {
              this.setState(
                {
                  badgeLoading: false,
                  toEditBadgeId: null,
                  isDeleting: false
                },
                () => {
                  this.getUserImages('BADGE')
                }
              )
            })
            .catch(error => {
              console.error('Error updating badge:', error)
            })
        }
      }
    )
  }

  editBadge = async item => {
    this.putItemInCanvas(item, 'edit')
  }

  deleteBadgeHistory = item => {
    this.setState(
      {
        toEditBadgeId: item.id,
        history: {},
        isDeleting: true
      },
      () => {
        this.saveUserBadge()
      }
    )
  }

  putItemInCanvas = (item, type) => {
    const { custom_data } = item
    this.setState(
      {
        templateColor: custom_data.templateColor,
        textColor: custom_data.textColor,
        iconScaleFactor: custom_data.iconScaleFactor,
        curveText: custom_data.curveText,
        isIconSelected: custom_data.isIconSelected,
        isTextAdded: custom_data.isTextAdded,
        fontSize: custom_data.fontSize,
        textDecoration: custom_data.textDecoration,
        textOffsetX: custom_data.textOffsetX,
        textOffsetY: custom_data.textOffsetY,
        textRadius: custom_data.textRadius,
        iconOffsetX: custom_data.iconOffsetX,
        iconOffsetY: custom_data.iconOffsetY,
        iconColor: custom_data.iconColor,
        text: custom_data.text,
        iconKey: custom_data.iconKey,
        templateKey: custom_data.templateKey,
        topSlant: custom_data.topSlant,
        selectedBadgeTemplate: this.state.badgeTemplates[
          custom_data.templateKey
        ],
        selectedIcon:
          custom_data.icon_key !== null
            ? this.state.icons[custom_data.iconKey]
            : null,
        badgeName: item.filename,
        badgeDetails: item.description,
        toEditBadgeId: type ? item.id : null,
        iconId: item.content_id
      },
      () => {
        const { userIcons } = this.state
        if (item.content_id !== null) {
          const icon = userIcons.filter(icon => icon.id === item.content_id)
          this.onUserIconChange({
            link: icon[0].link,
            id: icon[0].id
          })
        }
        this.clearAndDraw()
      }
    )
  }

  getImageInfo = (isHistory, type, img) => {
    const image = img || this.canvas.toDataURL()
    let y = [...image].slice(-1)[0] === '==' ? 2 : 1
    let x = image.length * (3 / 4) - y
    const body = {
      croppedImage: image,
      lastModifiedDate: new Date(),
      name: this.state.badgeName,
      size: x,
      type: `image/png`,
      imgFormat: this.state.imageFormat,
      imgDesc: this.state.badgeDetails || '',
      customData: isHistory ? this.state.history : {},
      contentId: this.state.iconId
    }
    return body
  }

  handleText = text => {
    this.setState(
      {
        text: text,
        isTextAdded: text.length > 0 ? true : false
      },
      () => {
        this.clearAndDraw()
      }
    )
  }

  background = () => {
    return (
      <div className="tab_icon">
        <div className="badge-img-template">
          <img alt="" src={background} />
        </div>
        <span>Background</span>
      </div>
    )
  }

  icon = () => {
    return (
      <div className="tab_icon">
        <div className="badge-img-template">
          <img alt="" src={icon} />
        </div>
        <span>Icon</span>
      </div>
    )
  }

  text = () => {
    return (
      <div className="tab_icon">
        <div className="badge-img-template">
          <img alt="" src={text} />
        </div>
        <span>Text</span>
      </div>
    )
  }

  clearIcon = () =>
    this.setState(
      { selectedIcon: null, isIconSelected: false, iconKey: null },
      () => {
        this.clearIconColor()
      }
    )

  clearText = () =>
    this.setState({ text: '', isTextAdded: false }, () => {
      this.clearAndDraw()
    })

  clearIconColor = () =>
    this.setState({ iconColor: null }, () => {
      this.clearAndDraw()
    })

  clearBackgroundFilled = () => {
    this.setState(
      {
        templateColor: ''
      },
      () => this.clearAndDraw()
    )
  }

  handleUserUpload = e => {
    let that = this
    var file = e.target.files[0]
    var reader = new FileReader()
    reader.onloadend = function() {
      that.uploadUserIcon(reader.result, file)
    }
    reader.readAsDataURL(file)
  }

  uploadUserIcon = (image, data) => {
    this.setState(
      {
        userUploadedIcon: image,
        selectedIcon: null,
        isIconSelected: true,
        imageFormat: 'ICON',
        iconLoading: true
      },
      () => {
        const { upload } = this.props
        const body = {
          croppedImage: image,
          lastModifiedDate: new Date().getTime(),
          name: data.name,
          type: data.type,
          size: data.size,
          imgFormat: 'ICON'
        }
        upload(body).then(res => {
          const { id } = res
          this.setState(
            {
              userUploadedIconId: id
            },
            () => {
              this.getUserImages('ICON')
            }
          )
        })
      }
    )
  }

  onSliderChange = value => {
    this.setState(
      {
        fontSize: value
      },
      () => {
        this.clearAndDraw()
      }
    )
  }

  handleIconSize = value => {
    this.setState(
      {
        iconScaleFactor: value
      },
      () => {
        this.clearAndDraw()
      }
    )
  }

  switchTextDecoration = async key => {
    const { textDecoration } = this.state
    let style = ''
    switch (key) {
      case 1:
        await this.setState({
          textDecoration: 'normal'
        })
        break
      case 2:
        if (textDecoration === 'bold') {
          style = 'normal'
        } else if (textDecoration.includes('bold')) {
          style = 'italic'
        } else if (textDecoration === 'italic') {
          style = `italic bold`
        } else {
          style = 'bold'
        }
        await this.setState({
          textDecoration: style
        })
        break
      case 3:
        if (textDecoration === 'italic') {
          style = 'normal'
        } else if (textDecoration.includes('italic')) {
          style = 'bold'
        } else if (textDecoration === 'bold') {
          style = `italic bold`
        } else {
          style = 'italic'
        }
        await this.setState({
          textDecoration: style
        })
        break
      default:
        break
    }
    this.clearAndDraw()
  }

  textCircle = (ctx, x, y) => {
    const { text, topSlant, textRadius } = this.state
    let space = Math.PI / 12 || 0
    var numRadsPerLetter = (Math.PI - space * 2) / text.length
    ctx.save()
    ctx.translate(x, y)
    var k = topSlant ? 1 : -1
    ctx.rotate(-k * ((Math.PI - numRadsPerLetter) / 2 - space))
    for (var i = 0; i < text.length; i++) {
      ctx.save()
      ctx.rotate(k * i * numRadsPerLetter)
      ctx.textAlign = 'center'
      ctx.textBaseline = !topSlant ? 'top' : 'bottom'
      ctx.fillText(text[i], 0, -k * textRadius)
      ctx.restore()
    }
    ctx.restore()
  }

  changeIconColor = color => {
    this.setState(
      {
        iconColor: color.hex
      },
      () => {
        this.clearAndDraw()
      }
    )
  }

  handleTextOffsetX = val => {
    this.setState(
      {
        textOffsetX: val
      },
      () => {
        this.clearAndDraw()
      }
    )
  }

  handleTextOffsetY = val => {
    this.setState(
      {
        textOffsetY: val
      },
      () => {
        this.clearAndDraw()
      }
    )
  }

  handleIconOffsetY = val => {
    this.setState(
      {
        iconOffsetY: val
      },
      () => {
        this.clearAndDraw()
      }
    )
  }

  handleIconOffsetX = val => {
    this.setState(
      {
        iconOffsetX: val
      },
      () => {
        this.clearAndDraw()
      }
    )
  }

  handleTextSlant = () => {
    this.setState(
      {
        curveText: !this.state.curveText
      },
      () => {
        this.clearAndDraw()
      }
    )
  }

  handleSlantDirection = () => {
    this.setState(
      {
        topSlant: !this.state.topSlant
      },
      () => {
        this.clearAndDraw()
      }
    )
  }

  handleTextRadius = val => {
    this.setState(
      {
        textRadius: val
      },
      () => {
        this.clearAndDraw()
      }
    )
  }

  handleBadgeName = e => {
    const inputValue = e.target.value
    if (inputValue.length <= 150) {
      this.setState({
        badgeName: inputValue
      })
    } else {
      this.setState({
        badgeName: inputValue.slice(0, 150)
      })
    }
  }

  handleBadgeDetails = e => {
    this.setState({
      badgeDetails: e.target.value
    })
  }

  clearCanvas = () => {
    this.setState(
      {
        templateColor: '',
        textColor: '#000000',
        iconScaleFactor: null,
        curveText: null,
        isIconSelected: null,
        isTextAdded: null,
        fontSize: 22,
        textDecoration: 'normal',
        textOffsetX: null,
        textOffsetY: null,
        textRadius: 60,
        iconOffsetX: null,
        iconOffsetY: null,
        iconColor: '',
        text: '',
        iconKey: null,
        templateKey: 1,
        topSlant: null,
        selectedBadgeTemplate: this.state.badgeTemplates[1],
        selectedIcon: null,
        badgeName: '',
        badgeDetails: '',
        toEditBadgeId: null,
        iconId: null
      },
      () => {
        this.clearAndDraw()
      }
    )
  }
  onTabChange = activeKey => {
    this.setState({ activeTab: activeKey })
  }

  render() {
    const {
      templateColor,
      textColor,
      iconScaleFactor,
      curveText,
      isIconSelected,
      isTextAdded,
      fontSize,
      textDecoration,
      textOffsetX,
      textOffsetY,
      textRadius,
      iconOffsetX,
      iconOffsetY,
      iconColor,
      icons,
      badgeTemplates,
      badgeFetching,
      historyBadges,
      badgeName,
      badgeDetails,
      activeTab,
      text
    } = this.state

    const { t } = this.props
    return (
      <React.Fragment>
        <div className="badge_modal_wrap">
          <h1 className="badge_modal_title">{t('v4:create_badge')}</h1>
          <div className="badge_modal_content">
            <div className="badge_create_left">
              <div className="badge_container">
                <div className="left_panel">
                  <Tabs
                    className="tabs"
                    defaultActiveKey="1"
                    tabPosition="top"
                    onChange={this.onTabChange}
                  >
                    {/* Tab - Badge Template / Background */}

                    <TabPane className="tab" tab={this.background()} key="1">
                      <Radio.Group
                        className="badge-container badge"
                        onChange={this.onChange}
                        defaultActiveKey="1"
                      >
                        {Object.entries(badgeTemplates).map(([key, val]) => (
                          <Radio.Button
                            className="badge__icon"
                            value={key}
                            key={key}
                          >
                            <img alt="" src={val} />
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                      <Popover
                        content={
                          <SketchPicker
                            color={templateColor}
                            onChange={this.handleTemplateColorChange}
                          />
                        }
                        title="Change color of template"
                        trigger="click"
                      >
                        <div className="badge_color_picker">
                          {t('v4:change_bg')}
                        </div>
                      </Popover>
                    </TabPane>

                    {/* Tab -  Text inside badge & text manipulation */}

                    <TabPane
                      className="tab"
                      tab={this.text()}
                      key="2"
                    ></TabPane>

                    {/* Tab - Icons Selection */}

                    <TabPane className="tab" tab={this.icon()} key="3">
                      <Radio.Group
                        className="badge-container icon"
                        onChange={this.onIconChange}
                        defaultActiveKey="1"
                      >
                        {Object.entries(icons).map(([key, val]) => (
                          <Radio.Button
                            className="badge__icon"
                            value={key}
                            key={key}
                          >
                            <img alt="" src={val} />
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                      {!this.state.userIcons && (
                        <Spin
                          indicator={antIcon}
                          style={{ backgroundColor: 'white' }}
                        />
                      )}
                      {this.state.userIcons && (
                        <div className="user_icon">
                          {this.state.userIcons &&
                            this.state.userIcons.map(item => (
                              <div
                                onClick={() => this.onUserIconChange(item)}
                                className="badge__icon"
                                value={item}
                                key={item.id}
                              >
                                <img alt="" src={item.link} />
                              </div>
                            ))}
                        </div>
                      )}
                    </TabPane>
                  </Tabs>
                  <div className="below-panel">
                    <div className="canvas-wrap">
                      <div className="canvas">
                        <canvas ref={canvas => (this.canvas = canvas)} />
                      </div>
                      <div className="canvas_info">
                        {templateColor && (
                          <div className="menu">
                            {t('v4:background')}
                            <span onClick={this.clearBackgroundFilled}>
                              <CloseOutlined />
                            </span>
                          </div>
                        )}
                        {isIconSelected && (
                          <div className="menu">
                            {t('v4:icon')}
                            <span onClick={this.clearIcon}>
                              <CloseOutlined />
                            </span>
                          </div>
                        )}
                        {isTextAdded && (
                          <div className="menu">
                            {t('v4:only_text')}
                            <span onClick={this.clearText}>
                              <CloseOutlined />
                            </span>
                          </div>
                        )}
                        {iconColor && (
                          <div className="menu">
                            {t('v4:icon_color')}
                            <span onClick={this.clearIconColor}>
                              <CloseOutlined />
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {activeTab === '2' ? (
                      <div className="badge-chage-sec">
                        <div className="text_env">
                          <div class="badge-item-title">
                            <span>{t('v4:only_text')}</span>
                            <span class="max-character">
                              {t('v4:charecters_fifteen_less')}
                            </span>
                          </div>
                          <Input
                            className="text"
                            type="text"
                            maxLength={15}
                            defaultValue={text}
                            onChange={e => this.handleText(e.target.value)}
                            placeholder={t('v4:leadership')}
                          />
                          <div className="badge-text-setting">
                            <div
                              content={
                                <Slider
                                  min={8}
                                  max={30}
                                  onChange={this.onSliderChange}
                                  value={
                                    typeof fontSize === 'number' ? fontSize : 0
                                  }
                                />
                              }
                              title={t('v4:font_size')}
                              trigger="click"
                              className="font_size_picker"
                            >
                              <div className="font_picker">
                                {t('v4:font_size')}
                              </div>
                              <div className="badge-number">
                                <InputNumber
                                  min={0}
                                  max={30}
                                  value={fontSize}
                                  onChange={this.onSliderChange}
                                />
                              </div>
                            </div>
                            <div className="text-stunt-wrap">
                              <div className="text_radius">
                                <div
                                  content={
                                    <Slider
                                      min={-100}
                                      max={100}
                                      onChange={this.handleTextRadius}
                                      value={
                                        typeof textRadius === 'number'
                                          ? textRadius
                                          : 0
                                      }
                                    />
                                  }
                                  title={t('v4:text_radius')}
                                  trigger="click"
                                  className="font_size_picker"
                                >
                                  <div className="text_picker">
                                    {t('v4:text_radius')}
                                  </div>
                                  <InputNumber
                                    min={0}
                                    max={100}
                                    value={textRadius}
                                    onChange={this.handleTextRadius}
                                  />
                                </div>
                              </div>
                              <div className="slant-wrap">
                                <div className="text_picker">
                                  {t('v4:text_arc')}
                                </div>
                                <div className="slant">
                                  <div
                                    className="slant_picker"
                                    onClick={this.handleTextSlant}
                                    style={{
                                      background: curveText ? 'green' : null,
                                      color: curveText ? 'white' : null
                                    }}
                                  >
                                    {t('v4:slant_text')}
                                  </div>
                                  <div className="slant_icon">
                                    <span
                                      style={{
                                        pointerEvents: curveText
                                          ? 'all'
                                          : 'none'
                                      }}
                                      onClick={this.handleSlantDirection}
                                    >
                                      <ReloadOutlined />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="badge-text-setting">
                            <div className="text_position">
                              <div className="badge-horizontal">
                                <h3>{t('v4:horizontal')}</h3>
                                <div
                                  content={
                                    <Slider
                                      min={-50}
                                      max={50}
                                      onChange={this.handleTextOffsetX}
                                      value={
                                        typeof textOffsetX === 'number'
                                          ? textOffsetX
                                          : 0
                                      }
                                    />
                                  }
                                  title={t('v4:horizontal_align')}
                                  trigger="click"
                                  className="font_size_picker"
                                >
                                  <div className="horizontal-wrap">
                                    <div className="text_picker">
                                      <ReactSVG src={HorizontalIcon} />
                                    </div>
                                    <InputNumber
                                      min={-50}
                                      max={50}
                                      value={
                                        typeof textOffsetX === 'number'
                                          ? textOffsetX
                                          : 0
                                      }
                                      onChange={this.handleTextOffsetX}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="badge-horizontal">
                                <h3>
                                  <h3>{t('v4:vertical')}</h3>
                                </h3>
                                <div
                                  content={
                                    <Slider
                                      min={-150}
                                      max={50}
                                      onChange={this.handleTextOffsetY}
                                      value={
                                        typeof textOffsetY === 'number'
                                          ? textOffsetY
                                          : 0
                                      }
                                    />
                                  }
                                  title={t('v4:vertical_align')}
                                  trigger="click"
                                  className="font_size_picker"
                                >
                                  <div className="vertical-wrap">
                                    <div className="text_picker">
                                      <ReactSVG src={VerticalIcon} />
                                    </div>
                                    <InputNumber
                                      min={-150}
                                      max={50}
                                      value={
                                        typeof textOffsetY === 'number'
                                          ? textOffsetY
                                          : 0
                                      }
                                      onChange={this.handleTextOffsetY}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="badge-text-wrap">
                              <div className="text_decoration">
                                <div
                                  className={`item ${
                                    textDecoration === 'normal'
                                      ? 'checked'
                                      : null
                                  }`}
                                  onClick={() => this.switchTextDecoration(1)}
                                >
                                  {t('v4:normal')}
                                </div>
                                <div
                                  className={`item ${
                                    textDecoration.includes('bold')
                                      ? 'checked'
                                      : null
                                  }`}
                                  onClick={() => this.switchTextDecoration(2)}
                                >
                                  <BoldOutlined />
                                </div>
                                <div
                                  className={`item ${
                                    textDecoration.includes('italic')
                                      ? 'checked'
                                      : null
                                  }`}
                                  onClick={() => this.switchTextDecoration(3)}
                                >
                                  <ItalicOutlined />
                                </div>
                              </div>
                              <Popover
                                content={
                                  <SketchPicker
                                    color={textColor}
                                    onChange={this.handleTextColorChange}
                                  />
                                }
                                title="Change text color"
                                trigger="click"
                                className="picker-container"
                              >
                                <div className="badge_color_picker">
                                  <span>{t('v4:change_color')}</span>
                                </div>
                              </Popover>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {activeTab === '3' ? (
                      <div className="active-tab-three">
                        <div className="badge-text-setting">
                          <div
                            content={
                              <div>
                                <Slider
                                  min={0}
                                  max={30}
                                  onChange={this.handleIconSize}
                                  value={
                                    typeof iconScaleFactor === 'number'
                                      ? iconScaleFactor
                                      : 0
                                  }
                                />
                              </div>
                            }
                            title={t('v4:icon_size')}
                            trigger="click"
                            className="font_size_picker"
                          >
                            <div className="font_picker">
                              {t('v4:icon_size')}
                            </div>
                            <div className="badge-number">
                              <InputNumber
                                min={0}
                                max={30}
                                value={iconScaleFactor}
                                onChange={this.handleIconSize}
                              />
                            </div>
                          </div>

                          <div className="text_position">
                            <div className="badge-horizontal">
                              <h3>{t('v4:horizontal')}</h3>
                              <div
                                content={
                                  <Slider
                                    min={-50}
                                    max={50}
                                    onChange={this.handleTextOffsetX}
                                    value={
                                      typeof textOffsetX === 'number'
                                        ? textOffsetX
                                        : 0
                                    }
                                  />
                                }
                                title={t('v4:horizontal_align')}
                                trigger="click"
                                className="font_size_picker"
                              >
                                <div className="horizontal-wrap">
                                  <div className="text_picker">
                                    <ReactSVG src={HorizontalIcon} />
                                  </div>
                                  <InputNumber
                                    min={-50}
                                    max={50}
                                    value={
                                      typeof textOffsetX === 'number'
                                        ? textOffsetX
                                        : 0
                                    }
                                    onChange={this.handleTextOffsetX}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="badge-horizontal">
                              <h3>{t('v4:vertical')}</h3>
                              <div
                                content={
                                  <Slider
                                    min={-150}
                                    max={50}
                                    onChange={this.handleTextOffsetY}
                                    value={
                                      typeof textOffsetY === 'number'
                                        ? textOffsetY
                                        : 0
                                    }
                                  />
                                }
                                title={t('v4:vertical_align')}
                                trigger="click"
                                className="font_size_picker"
                              >
                                <div className="vertical-wrap">
                                  <div className="text_picker">
                                    <ReactSVG src={VerticalIcon} />
                                  </div>
                                  <InputNumber
                                    min={-150}
                                    max={50}
                                    value={
                                      typeof textOffsetY === 'number'
                                        ? textOffsetY
                                        : 0
                                    }
                                    onChange={this.handleTextOffsetY}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="icon-color-wrap">
                          <Popover
                            content={
                              <SketchPicker
                                color={iconColor || '#000000'}
                                onChange={val => this.changeIconColor(val)}
                              />
                            }
                            title="Change icon color"
                            trigger="click"
                            className="picker-container"
                          >
                            <div className="badge_color_picker">
                              <span>{t('v4:change_icon_color')}</span>
                            </div>
                          </Popover>
                          <label
                            className="btn-upload"
                            style={{
                              pointerEvents: this.state.iconLoading
                                ? 'none'
                                : 'all'
                            }}
                          >
                            <input
                              type="file"
                              name="fileupload"
                              onChange={this.handleUserUpload}
                            />
                            <button className="btn">
                              {this.state.iconLoading && <Spin />}
                              {t('v4:upload_icon')}
                            </button>
                          </label>
                        </div>
                      </div>
                    ) : null}

                    {activeTab === '2' || activeTab === '3' ? null : (
                      <div className="detail">
                        <div className="title d-col">
                          <div className="badge-item-title">
                            <span>{t('v4:name_badges')}</span>
                            <span className="max-character">
                              {t('v4:should_less_150')}{' '}
                            </span>
                          </div>
                          <input
                            type="text"
                            value={this.state.badgeName}
                            onChange={this.handleBadgeName}
                          />
                        </div>
                        <div className="details d-col">
                          <span>{t('general:details')}</span>
                          <textarea
                            value={this.state.badgeDetails}
                            onChange={this.handleBadgeDetails}
                          />
                        </div>
                        <div className="buttons">
                          <Button
                            className="btn"
                            disabled={badgeName === '' || badgeDetails === ''}
                            style={{
                              backgroundColor: '#b10f0f',
                              cursor:
                                badgeName || badgeDetails
                                  ? 'pointer'
                                  : 'no-drop'
                            }}
                            onClick={() => this.clearCanvas()}
                          >
                            {t('v4:delete')}
                          </Button>
                          <Button
                            className="btn"
                            disabled={badgeName === '' || badgeDetails === ''}
                            loading={this.state.badgeLoading}
                            onClick={() => this.saveUserBadge()}
                            style={{
                              backgroundColor: '#00BE5E',
                              cursor:
                                badgeName && badgeDetails
                                  ? 'pointer'
                                  : 'no-drop'
                            }}
                          >
                            {this.state.toEditBadgeId
                              ? 'Update badge'
                              : 'Save For Later'}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* User History of Badge Creation */}

            <div className="badge_create_right">
              {activeTab === '2' || activeTab === '3' ? (
                <div
                  className={`detail ${
                    activeTab === '2' || activeTab === '3'
                      ? 'badge-add-active'
                      : ''
                  }`}
                >
                  <div className="title d-col">
                    <div className="badge-item-title">
                      <span>{t('v4:name_badges')}</span>
                      <span className="max-character">
                        {t('v4:should_less_150')}
                      </span>
                    </div>
                    <input
                      type="text"
                      value={this.state.badgeName}
                      onChange={this.handleBadgeName}
                    />
                  </div>
                  <div className="details d-col">
                    <span>{t('general:details')}</span>
                    <textarea
                      value={this.state.badgeDetails}
                      onChange={this.handleBadgeDetails}
                    />
                  </div>
                  <div className="buttons">
                    <Button
                      className="btn"
                      disabled={badgeName === '' || badgeDetails === ''}
                      style={{
                        backgroundColor: '#b10f0f',
                        cursor:
                          badgeName || badgeDetails ? 'pointer' : 'no-drop'
                      }}
                      onClick={() => this.clearCanvas()}
                    >
                      {t('v4:delete')}
                    </Button>
                    <Button
                      className="btn"
                      disabled={badgeName === '' || badgeDetails === ''}
                      loading={this.state.badgeLoading}
                      onClick={() => this.saveUserBadge()}
                      style={{
                        backgroundColor: '#00BE5E',
                        cursor:
                          badgeName && badgeDetails ? 'pointer' : 'no-drop'
                      }}
                    >
                      {this.state.toEditBadgeId
                        ? 'Update badge'
                        : 'Save For Later'}
                    </Button>
                  </div>
                </div>
              ) : null}
              <div
                className={`badge_history ${
                  activeTab === '2' || activeTab === '3'
                    ? 'badge-history-active'
                    : ''
                }`}
              >
                <div className="title">{t('v4:badge_history')}</div>
                <div className="history_items" style={{}}>
                  {badgeFetching && <Spin indicator={antIcon} />}
                  {!badgeFetching &&
                    historyBadges.map(item => (
                      <Spin
                        key={item.id}
                        spinning={
                          item.id === this.state.toEditBadgeId &&
                          this.state.isDeleting
                        }
                        tip="Deleting..."
                      >
                        <div className="history_item">
                          <div
                            className="history_item_details"
                            onClick={() => this.putItemInCanvas(item)}
                          >
                            <img
                              alt=""
                              src={
                                badgeTemplates[
                                  item.custom_data &&
                                    item.custom_data.templateKey
                                ]
                              }
                            />
                            <div className="history_item_info">
                              <h3>{item.filename}</h3>
                              <p>{item.description}</p>
                            </div>
                          </div>
                          <div className="history_item_action">
                            <div
                              className="history_item_action_img"
                              onClick={() => this.editBadge(item)}
                            >
                              <img alt="" src={pencil} />
                            </div>
                            <Popconfirm
                              title={`${t('v4:do_you_want_delete_badge')}`}
                              onConfirm={() => this.deleteBadgeHistory(item)}
                              okText="Yes"
                              cancelText="No"
                              icon={null}
                            >
                              <div className="history_item_action_img">
                                <img alt="" src={bin} />
                              </div>
                            </Popconfirm>
                          </div>
                        </div>
                      </Spin>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          disabled={badgeDetails === '' || badgeName === ''}
          className="save_badge"
          onClick={this.uploadBadge}
        >
          {t('buttons:save')}
        </Button>
      </React.Fragment>
    )
  }
}

export default withTranslation()(Badge)
