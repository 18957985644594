import React, { useState } from 'react'
import { Button, Input, message, Drawer, Popover, Icon, Select } from 'antd'
import ReactPlayer from 'react-player'
import './VideoGeneration.scss'
import CommentInput from '../CommentInput'

const { TextArea } = Input

const defaultScenes = [
  {
    id: 1,
    title: 'Scene 01',
    description: 'Big city night scene',
    settings: {
      location: "Big city with a reporter's van on the street",
      duration: 5,
      fps: 24,
      resolution: '1080p',
      musicIntensity: 50
    },
    shots: [
      {
        id: 1,
        src: 'https://www.w3schools.com/html/mov_bbb.mp4',
        prompt: 'A news reporter reporting on latest news',
        shotType: 'Medium-Shot',
        clipDuration: 3
      },
      {
        id: 2,
        src: 'https://www.w3schools.com/html/movie.mp4',
        prompt: 'A big screen showing news',
        shotType: 'Close-Up',
        clipDuration: 2
      },
      {
        id: 3,
        src: '', // No video yet
        prompt: 'Loads of police cyberpunk robots running on the street',
        shotType: '',
        clipDuration: 4
      }
    ]
  },
  {
    id: 2,
    title: 'Scene 02',
    description: 'Rural morning tranquility',
    settings: {
      location: 'Countryside with morning mist',
      duration: 6,
      fps: 30,
      resolution: '720p',
      musicIntensity: 30
    },
    shots: [
      {
        id: 1,
        src: 'https://example.com/rural_morning.mp4',
        prompt: 'Wide shot of a quiet rural landscape',
        shotType: 'Wide-Shot',
        clipDuration: 4
      },
      {
        id: 2,
        src: 'https://example.com/bird.mp4',
        prompt: 'Bird singing in a tree',
        shotType: 'Close-Up',
        clipDuration: 2
      }
    ]
  },
  {
    id: 3,
    title: 'Scene 03',
    description: 'Urban rush hour',
    settings: {
      location: 'Busy city intersection',
      duration: 8,
      fps: 24,
      resolution: '1080p',
      musicIntensity: 75
    },
    shots: [
      {
        id: 1,
        src: 'https://example.com/traffic.mp4',
        prompt: 'Traffic congestion at peak hours',
        shotType: 'Medium-Shot',
        clipDuration: 5
      },
      {
        id: 2,
        src: 'https://example.com/metro.mp4',
        prompt: 'People rushing into subway',
        shotType: 'Medium-Shot',
        clipDuration: 3
      }
    ]
  },
  {
    id: 4,
    title: 'Scene 04',
    description: 'Beach sunset',
    settings: {
      location: 'Tropical beach at sunset',
      duration: 7,
      fps: 24,
      resolution: '4K',
      musicIntensity: 20
    },
    shots: [
      {
        id: 1,
        src: 'https://example.com/beach_sunset.mp4',
        prompt: 'Sun setting over the ocean',
        shotType: 'Wide-Shot',
        clipDuration: 4
      },
      {
        id: 2,
        src: 'https://example.com/waves.mp4',
        prompt: 'Waves lapping at the shore',
        shotType: 'Close-Up',
        clipDuration: 3
      }
    ]
  }
]

const transitions = [
  { id: 'fade', name: 'Fade', icon: 'eye' },
  { id: 'slide', name: 'Slide', icon: 'arrow-right' },
  { id: 'dissolve', name: 'Dissolve', icon: 'swap' },
  { id: 'zoom-in', name: 'Zoom In', icon: 'zoom-in' },
  { id: 'zoom-out', name: 'Zoom Out', icon: 'zoom-out' },
  { id: 'wipe-left', name: 'Wipe Left', icon: 'arrow-left' },
  { id: 'wipe-right', name: 'Wipe Right', icon: 'arrow-right' },
  { id: 'wipe-up', name: 'Wipe Up', icon: 'arrow-up' },
  { id: 'wipe-down', name: 'Wipe Down', icon: 'arrow-down' },
  { id: 'spin', name: 'Spin', icon: 'loading' },
  { id: 'flip-horizontal', name: 'Flip Horizontal', icon: 'swap' },
  { id: 'flip-vertical', name: 'Flip Vertical', icon: 'retweet' },
  { id: 'roll', name: 'Roll', icon: 'sync' },
  { id: 'split', name: 'Split', icon: 'split-cells' },
  { id: 'circle-reveal', name: 'Circle Reveal', icon: 'play-circle' }
]

const TransitionDisplay = ({ type }) => {
  const transition =
    transitions.find(transition => transition.id === type) || null

  if (!transition) {
    return <Icon type="plus" className="video-transition-icon" />
  }

  return (
    <div className="selected-transition__container">
      <span className="selected-transition__title">Transition</span>
      <div className="selected-transition">
        <Icon type={transition.icon} />
        <span>{transition.name}</span>
      </div>
    </div>
  )
}

const TransitionSelector = ({ onSelect, currentTransition }) => {
  return (
    <div className="transition-selector">
      <p className="selector-title">Select Transition</p>
      {transitions.map(transition => (
        <Button
          key={transition.id}
          className={`transition-option ${
            currentTransition === transition.id ? 'selected' : ''
          }`}
          onClick={() => onSelect(transition.id)}
        >
          <Icon type={transition.icon} />
          <span>{transition.name}</span>
        </Button>
      ))}
    </div>
  )
}

const VideoGeneration = () => {
  const [scenes, setScenes] = useState(defaultScenes)
  const [selectedSettings, setSelectedSettings] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [transitions, setTransitions] = useState({})

  const handleRegenerateVideo = (sceneId, shotId) => {
    message.success(`Scene ${sceneId}, Shot ${shotId} regenerated!`)
  }

  const handleEditSettings = (sceneId, shotId) => {
    const scene = scenes.find(s => s.id === sceneId)
    const shot = scene.shots.find(sh => sh.id === shotId)

    setSelectedSettings({
      sceneId,
      shotId,
      ...scene.settings,
      ...shot,
      motion: shot.motion || 'Static', // Default to 'Static' if motion is not set
      sceneMotion: shot.sceneMotion || 'Natural motion' // Default to 'Natural motion'
    })
    setDrawerVisible(true)
  }

  const updateSettings = (key, value) => {
    setSelectedSettings(prev => ({
      ...prev,
      [key]: value
    }))
  }

  const saveSettings = () => {
    setScenes(prevScenes =>
      prevScenes.map(scene => {
        if (scene.id === selectedSettings.sceneId) {
          return {
            ...scene,
            shots: scene.shots.map(shot =>
              shot.id === selectedSettings.shotId
                ? {
                    ...shot,
                    prompt: selectedSettings.prompt,
                    shotType: selectedSettings.shotType,
                    clipDuration: selectedSettings.clipDuration,
                    motion: selectedSettings.motion,
                    sceneMotion: selectedSettings.sceneMotion
                  }
                : shot
            )
          }
        }
        return scene
      })
    )
    setDrawerVisible(false)
  }

  const handleTransitionSelect = (sceneId, position, transitionType) => {
    setTransitions(prev => ({
      ...prev,
      [`${sceneId}-${position}`]: transitionType
    }))

    message.success(`Transition updated to ${transitionType}`)
  }

  return (
    <div className="video-generation-container">
      <div className="header">
        <h1>Video Generation</h1>
        <p>Create videos for your project</p>
      </div>
      {scenes.map(scene => (
        <div key={scene.id} className="scene-container">
          <div className="left-panel">
            <h2>{scene.title}</h2>
            <TextArea
              value={scene.description}
              rows={2}
              onChange={e => {
                const newDescription = e.target.value
                setScenes(prevScenes =>
                  prevScenes.map(s =>
                    s.id === scene.id
                      ? { ...s, description: newDescription }
                      : s
                  )
                )
              }}
            />
          </div>

          <div className="right-panel">
            <div className="shots-grid">
              {/* Opening transition */}
              <div className="transition-wrapper start-transition">
                <Popover
                  content={
                    <TransitionSelector
                      onSelect={type =>
                        handleTransitionSelect(scene.id, 'start', type)
                      }
                      currentTransition={transitions[`${scene.id}-start`]}
                    />
                  }
                  trigger="hover"
                  placement="right"
                >
                  <div className="transition-trigger">
                    <TransitionDisplay
                      type={transitions[`${scene.id}-start`]}
                    />
                  </div>
                </Popover>
              </div>

              {scene.shots.map((shot, index) => (
                <React.Fragment key={shot.id}>
                  <div className="shot-card">
                    <h3 className="shot-title">Shot {index + 1}</h3>
                    <div className="video-wrapper">
                      <ReactPlayer
                        url={
                          shot.src ||
                          'https://www.w3schools.com/html/mov_bbb.mp4'
                        }
                        width="100%"
                        height="250px"
                        controls={true}
                      />
                    </div>
                    <CommentInput
                      text={shot.prompt}
                      placeholder="Write your video prompt..."
                    />
                    {/* <Input value={shot.shotType} placeholder="Shot Type" /> */}
                    <div className="card-actions">
                      <Button
                        size="small"
                        icon="reload"
                        onClick={() => handleRegenerateVideo(scene.id, shot.id)}
                      >
                        Regenerate Video
                      </Button>
                      <Button
                        size="small"
                        icon="setting"
                        onClick={() => handleEditSettings(scene.id, shot.id)}
                      >
                        Shot Editor
                      </Button>
                    </div>
                  </div>

                  {/* Only show between-shots transition if it's not the last shot */}
                  {index < scene.shots.length - 1 && (
                    <div className="transition-wrapper">
                      <Popover
                        content={
                          <TransitionSelector
                            onSelect={type =>
                              handleTransitionSelect(scene.id, shot.id, type)
                            }
                            currentTransition={
                              transitions[`${scene.id}-${shot.id}`]
                            }
                          />
                        }
                        trigger="hover"
                        placement="right"
                      >
                        <div className="transition-trigger">
                          <TransitionDisplay
                            type={transitions[`${scene.id}-${shot.id}`]}
                          />
                        </div>
                      </Popover>
                    </div>
                  )}
                </React.Fragment>
              ))}

              {/* Only show end transition after the last shot */}
              <div className="transition-wrapper end-transition">
                <Popover
                  content={
                    <TransitionSelector
                      onSelect={type =>
                        handleTransitionSelect(scene.id, 'end', type)
                      }
                      currentTransition={transitions[`${scene.id}-end`]}
                    />
                  }
                  trigger="hover"
                  placement="right"
                >
                  <div className="transition-trigger">
                    <TransitionDisplay type={transitions[`${scene.id}-end`]} />
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Settings Drawer */}
      <Drawer
        title="Edit Shot Settings"
        placement="right"
        closable={true}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={400}
      >
        {selectedSettings && (
          <div className="settings-drawer-content">
            {/* Previous settings */}

            <div className="setting-group">
              <label>Motion</label>
              <Select
                value={selectedSettings.motion}
                onChange={e => updateSettings('motion', e.target.value)}
              >
                <Select.Option value="Static">Static</Select.Option>
                <Select.Option value="Track down">Track down</Select.Option>
                <Select.Option value="Track up">Track up</Select.Option>
                <Select.Option value="Pan left">Pan left</Select.Option>
                <Select.Option value="Pan right">Pan right</Select.Option>
                <Select.Option value="Tilt up">Tilt up</Select.Option>
                <Select.Option value="Tilt down">Tilt down</Select.Option>
                <Select.Option value="Dolly in">Dolly in</Select.Option>
                <Select.Option value="Dolly out">Dolly out</Select.Option>
              </Select>
            </div>

            <div className="setting-group">
              <label>Scene Motion</label>
              <Select
                value={selectedSettings.sceneMotion}
                onChange={e => updateSettings('sceneMotion', e.target.value)}
              >
                <Select.Option value="Natural motion">
                  Natural motion
                </Select.Option>
                <Select.Option value="Extreme motion">
                  Extreme motion
                </Select.Option>
              </Select>
            </div>

            <Button
              type="primary"
              onClick={saveSettings}
              style={{ marginTop: 16 }}
            >
              Save Settings
            </Button>
          </div>
        )}
      </Drawer>
    </div>
  )
}

export default VideoGeneration
