import React, { useState, useEffect } from 'react'
import {
  Drawer,
  Button,
  Input,
  List,
  Checkbox,
  Typography,
  Empty,
  Spin,
  message
} from 'antd'
import { useSelector } from 'react-redux'
import { PlusOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { api } from '../../../../services'

const { Text } = Typography

const UserManagementDrawer = ({ visible, onCancel, role, onUpdateUsers }) => {
  const user = useSelector(state => state.user)

  const [roleUsers, setRoleUsers] = useState([])
  const [userEmails, setUserList] = useState([])
  const [fetching, setFetching] = useState(false)
  const [fetchingRoleUsers, setFetchingRoleUsers] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [addUserDrawerVisible, setAddUserDrawerVisible] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [addingUsers, setAddingUsers] = useState(false)
  const [deletingUserId, setDeletingUserId] = useState(null)

  const fetchUserEmails = () => {
    const {
      info: { active_organization_id }
    } = user
    setFetching(true)
    api.organizations
      .getUsersByOrganizationId(active_organization_id)
      .then(({ data }) => {
        const userEmails =
          data &&
          data
            .filter(item => item.user !== null)
            .map(item => ({
              email: item.user.email,
              id: item.user.id,
              name: item.user.name || item.user.email.split('@')[0]
            }))

        setUserList(userEmails)
      })
      .catch(() => {
        message.error('Failed to fetch users')
      })
      .finally(() => {
        setFetching(false)
      })
  }

  const fetchRoleUsers = async () => {
    try {
      setFetchingRoleUsers(true)
      const response = await api.roles.getRoleUsers(role.id)
      const data = response.data.data || []
      const roleUsers = data.map(item => ({
        email: item.user.email,
        id: item.user.id,
        name: item.user.name || item.user.email.split('@')[0]
      }))
      setRoleUsers(roleUsers)
    } catch (error) {
      message.error('Failed to load role users')
    } finally {
      setFetchingRoleUsers(false)
    }
  }

  useEffect(() => {
    if (role && visible) {
      fetchRoleUsers()
      fetchUserEmails()
    }
  }, [role, visible])

  const handleRemoveUser = async userToRemove => {
    try {
      const updatedRoleUsers = roleUsers.filter(
        user => user.id !== userToRemove.id
      )
      setDeletingUserId(userToRemove.id)
      const response = await api.roles.deleteRoleUser(role.id, userToRemove.id)

      if (response) {
        setRoleUsers(updatedRoleUsers)
        message.success('User removed successfully')
      }
    } catch (error) {
      message.error('Failed to remove user')
    } finally {
      setDeletingUserId(null)
    }
  }

  const handleAddSelectedUsers = async () => {
    try {
      setAddingUsers(true)
      const usersToAdd = selectedUsers.filter(
        selectedUser =>
          !roleUsers.some(roleUser => roleUser.id === selectedUser.id)
      )

      // Make API call to update role users
      await api.roles.updateRoleUsers(role.id, {
        users: [
          ...roleUsers.map(user => user.id),
          ...usersToAdd.map(user => user.id)
        ]
      })

      // Update local state after successful API call
      const updatedRoleUsers = [...roleUsers, ...usersToAdd]
      setRoleUsers(updatedRoleUsers)
      message.success('Users added successfully')
      setSelectedUsers([])
      setAddUserDrawerVisible(false)
    } catch (error) {
      message.error('Failed to add users')
    } finally {
      setAddingUsers(false)
    }
  }

  const filteredAvailableUsers = userEmails.filter(
    user =>
      !roleUsers.some(roleUser => roleUser.id === user.id) &&
      (user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.name.toLowerCase().includes(searchTerm.toLowerCase()))
  )

  const renderRoleUsersList = () => {
    if (fetchingRoleUsers) {
      return <Spin size="small" tip="Loading..." />
    }

    if (roleUsers.length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Text type="secondary">No users assigned to this role</Text>
          }
        />
      )
    }

    return (
      <div className="user-list">
        {roleUsers.map(user => (
          <Spin
            key={user.id}
            spinning={deletingUserId === user.id}
            tip="Removing user..."
          >
            <div
              key={user.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '12px 16px',
                borderBottom: '1px solid #f0f0f0',
                transition: 'background-color 0.3s'
              }}
              onMouseEnter={e =>
                (e.currentTarget.style.backgroundColor = '#f5f5f5')
              }
              onMouseLeave={e =>
                (e.currentTarget.style.backgroundColor = 'transparent')
              }
            >
              <div>
                <Text strong style={{ marginRight: 8 }}>
                  {user.email}
                </Text>
              </div>
              <Button
                type="link"
                danger
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveUser(user)}
              >
                Remove
              </Button>
            </div>
          </Spin>
        ))}
      </div>
    )
  }

  const renderAddUserList = () => {
    const allUsersSelected =
      filteredAvailableUsers.length > 0 &&
      filteredAvailableUsers.every(user =>
        selectedUsers.some(selected => selected.id === user.id)
      )

    const handleSelectAllUsers = e => {
      if (e.target.checked) {
        const newSelectedUsers = [
          ...selectedUsers,
          ...filteredAvailableUsers.filter(
            user => !selectedUsers.some(selected => selected.id === user.id)
          )
        ]
        setSelectedUsers(newSelectedUsers)
      } else {
        const newSelectedUsers = selectedUsers.filter(
          selected =>
            !filteredAvailableUsers.some(user => user.id === selected.id)
        )
        setSelectedUsers(newSelectedUsers)
      }
    }

    return (
      <div>
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search users"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          style={{ marginBottom: 16 }}
        />

        {filteredAvailableUsers.length === 0 ? (
          <Empty description="No users available" />
        ) : (
          <>
            <List
              loading={fetching}
              className="roles-management__users-list"
              dataSource={filteredAvailableUsers}
              renderItem={user => (
                <List.Item key={user.id}>
                  <Checkbox
                    checked={selectedUsers.some(
                      selected => selected.id === user.id
                    )}
                    onChange={e => {
                      if (e.target.checked) {
                        setSelectedUsers([...selectedUsers, user])
                      } else {
                        setSelectedUsers(
                          selectedUsers.filter(
                            selected => selected.id !== user.id
                          )
                        )
                      }
                    }}
                  >
                    <List.Item.Meta title={user.email} />
                  </Checkbox>
                </List.Item>
              )}
            />
          </>
        )}

        <div
          className="user-drawer__footer"
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: 'white',
            textAlign: 'right'
          }}
        >
          <Checkbox
            checked={allUsersSelected}
            indeterminate={
              filteredAvailableUsers.length > 0 &&
              selectedUsers.length > 0 &&
              !allUsersSelected
            }
            onChange={handleSelectAllUsers}
          >
            Select All
          </Checkbox>

          <Button
            type="primary"
            onClick={handleAddSelectedUsers}
            disabled={selectedUsers.length === 0}
            loading={addingUsers}
          >
            Add {selectedUsers.length > 0 ? `(${selectedUsers.length})` : ''}{' '}
            Users
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      <Drawer
        className="user-drawer"
        title="Manage Users"
        visible={visible}
        onClose={onCancel}
        width={520}
      >
        <div className="users-management__header">
          <div className="title">
            <h3>Role: {role && role.name}</h3>
          </div>
          <Button
            type="primary"
            icon="plus"
            onClick={() => setAddUserDrawerVisible(true)}
            loading={fetchingRoleUsers}
          >
            Add Users
          </Button>
        </div>

        {renderRoleUsersList()}

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: 'white',
            textAlign: 'right'
          }}
        >
          <Button type="primary" ghost onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Drawer>

      {/* Add Users Drawer */}
      <Drawer
        title="Add Users"
        visible={addUserDrawerVisible}
        onClose={() => {
          setAddUserDrawerVisible(false)
          setSelectedUsers([])
          setSearchTerm('')
        }}
        width={500}
      >
        {renderAddUserList()}
      </Drawer>
    </>
  )
}

export default UserManagementDrawer
