import http from './http'

function resistrictMediaApi() {
  return http({
    method: 'GET',
    url: '/library?offset=0&limit=20&search=&sort=NEWEST&type=&isActiveOrg=true'
  })
}

// Get all roles
function getRoles(type) {
  return http({
    method: 'GET',
    url: '/roles',
    ...(type && { params: { type } })
  })
}

// Create a new role
function createRole(name) {
  return http({
    method: 'POST',
    url: '/roles',
    data: { name }
  })
}

// Update a specific role
function updateRole(roleId, name) {
  return http({
    method: 'PUT',
    url: `/roles/${roleId}`,
    data: { name }
  })
}

// Delete a specific role
function deleteRole(roleId) {
  return http({
    method: 'DELETE',
    url: `/roles/${roleId}`
  })
}

// Get permissions for a specific role
function getRolePermissions(roleId) {
  return http({
    method: 'GET',
    url: `/roles/${roleId}/permissions`
  })
}

// Add permissions to a role
function addRolePermissions(roleId, permissions) {
  return http({
    method: 'PUT',
    url: `/roles/${roleId}/permissions`,
    data: { permissions }
  })
}

// Get users assigned to a specific role
function getRoleUsers(roleId) {
  return http({
    method: 'GET',
    url: `/roles/${roleId}/users`
  })
}

// Update user's role
function updateRoleUsers(roleId, data) {
  return http({
    method: 'PUT',
    url: `/roles/${roleId}/users/`,
    data
  })
}

// Remove user from a role
function deleteRoleUser(roleId, userId) {
  return http({
    method: 'DELETE',
    url: `/roles/${roleId}/users/${userId}`
  })
}

// Get all available permissions
function getAllPermissions() {
  return http({
    method: 'GET',
    url: '/roles/permissions'
  })
}

export {
  getRoles,
  resistrictMediaApi,
  createRole,
  updateRole,
  deleteRole,
  getRolePermissions,
  addRolePermissions,
  getRoleUsers,
  updateRoleUsers,
  deleteRoleUser,
  getAllPermissions
}
