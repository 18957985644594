import React, { Fragment, useState } from 'react'
import _ from 'lodash'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import className from 'classnames'
import { api } from '../../../../../../../../../../services'
import { VideoRectangleIconBig } from '../../../images'
import { Button, Col, Row } from 'antd'
import { general } from '../../../../../../../../../../constants'
import { bytesToSize } from '../../../../../../../../../../utils'
import { AddDetailVideo } from '../../Create'
import './DropzoneVideo.scss'
import ReactPlayer from 'react-player'
const VideoPlayer = ({ src }) => {
  return (
    <div
      style={{
        width: '306px',
        height: '251px',
        marginLeft: '118px',
        marginBottom: '5px',
        overflow: 'hidden',
        border: 'none'
      }}
    >
      <ReactPlayer
        url={src}
        width="100%"
        height="100%"
        playing={false}
        loop={false}
        muted={true}
        controls={true}
        style={{ objectFit: 'cover' }}
      />
    </div>
  )
}
const DropzoneVideo = props => {
  const {
    t,
    uploadProgress,
    fileNotCompatible,
    fileSizeExceed,
    getSelectedFile,
    onDrop,
    uploadListClassName,
    checkedCompleteUpload,
    resetFileInCompatibleMessage,
    src
  } = props
  const [videoUrl, setVideoUrl] = useState('')
  const item = uploadProgress[0]
  if (item && item.id && item.percent === 100) {
    api.library.getMediaInfoById(item.id).then(data => {
      !videoUrl && setVideoUrl(data.data.link)
      getSelectedFile([data.data])
    })
  }
  // const media_id = src.substring(src.lastIndexOf('/') + 1)
  // const isMediaIdInteger =
  //   _.isFinite(_.parseInt(media_id)) && !_.isNaN(_.parseInt(media_id))

  return (
    <Fragment>
      <div className="upload-modal insert-audio-detail">
        <div className="upload-modal__inner">
          {uploadProgress.length === 0 && (
            <div className="upload-modal__dropzone">
              <Dropzone
                accept={['video/mp4,video/x-m4v,video/*']}
                multiple={false}
                onDrop={onDrop}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    className={className('upload-dropzone', {
                      'upload-dropzone_active': isDragActive
                    })}
                  >
                    <div className="upload-dropzone__text-wrapper">
                      <img src={VideoRectangleIconBig} alt="" />
                      <p>{t('media:drop_files')}</p>
                      <p>{t('media:or')}</p>
                      <Button>{t('media:import_files')}</Button>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          )}

          <div
            className={`upload-modal__list-wrapper ${uploadListClassName(
              uploadProgress
            )}`}
          >
            <div className="upload-modal__list-container">
              {fileNotCompatible && (
                <div className="upload-modal__error-item-wrapper">
                  <div className="upload-modal__error-message-container">
                    <div className="upload-modal__error-bar" />
                    <div className="upload-modal__error-message">
                      {t('errors:upload_error')}
                    </div>
                  </div>

                  <Button
                    className="upload-modal__cancel-error"
                    type="primary"
                    onClick={() => resetFileInCompatibleMessage()}
                  >
                    {t('buttons:ok')}
                  </Button>
                </div>
              )}

              {fileSizeExceed && (
                <div className="upload-modal__error-item-wrapper">
                  <div className="upload-modal__error-message-container">
                    <div className="upload-modal__error-bar" />
                    <div className="upload-modal__error-message">
                      {t('errors:file_size_exceed')}
                    </div>
                  </div>

                  <Button
                    className="upload-modal__cancel-error"
                    type="primary"
                    onClick={() => resetFileInCompatibleMessage()}
                  >
                    {t('buttons:ok')}
                  </Button>
                </div>
              )}

              {uploadProgress.length > 0 && (
                <div className="upload-modal__list">
                  <div key={item.uid} className="upload-modal__item">
                    <Row>
                      <Col span={12}>
                        {_.isEmpty(videoUrl) && (
                          <img
                            src={VideoRectangleIconBig}
                            className="thumbnail-video-fit-into-dropzone"
                            alt=""
                          />
                        )}
                        {videoUrl && <VideoPlayer src={videoUrl} />}
                      </Col>
                      <Col span={12}>
                        <div className="gutter-box">
                          <div className="upload-modal-item__percent-border">
                            <div
                              className={`upload-modal-item__percent ${
                                item.status === general.CANCELED
                                  ? 'upload-modal-item__percent__cancel'
                                  : ''
                              }`}
                              style={{ width: item.percent + '%' }}
                            />
                          </div>
                          {item.status !== general.CANCELED && (
                            <div className="upload-modal-item__weight">
                              {checkedCompleteUpload(item) && (
                                <div style={{ marginTop: '7px' }}>
                                  {bytesToSize(item.loaded)} {t('of')}{' '}
                                  {bytesToSize(item.total)}
                                </div>
                              )}
                              {!checkedCompleteUpload(item) && (
                                <span className="upload-modal-item__weight__complete">
                                  {t('general:completed')}
                                </span>
                              )}
                            </div>
                          )}
                          <AddDetailVideo
                            getSelectedFile={getSelectedFile}
                            mediaId={item.id}
                            fileName={item.name}
                            src={src}
                            uploadComplete={checkedCompleteUpload(item)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default withTranslation('video')(DropzoneVideo)
