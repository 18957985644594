import React, { useState } from 'react'
import { Steps, Button, Icon } from 'antd'
import './styles.scss'
import NewHeader from '../../components/NewHeader'
import UploadDocument from './components/UploadDocument'
import Voiceover from './components/Voiceover'
import ShotList from './components/ShotList'
import ImageGeneration from './components/ImageGeneration'
import VideoGeneration from './components/VideoGeneration'
import CharacterSelection from './components/CharacterSelection'
import VideoStyle from './components/VideoStyle'

const { Step } = Steps

const VideoAi = () => {
  const [currentStep, setCurrentStep] = useState(0)

  const [stepsValidation, setStepsValidation] = useState({
    uploadDocument: true, // done
    voiceover: true, /// done
    characterSelection: true, // done
    videoStyle: true, // done
    shotList: true,
    imageGeneration: true,
    videoGeneration: true
  })

  const [formData, setFormData] = useState({
    file: null,
    voiceoverText: '',
    voiceoverAudio: null,
    styles: {},
    characters: {},
    shotList: [],
    images: [],
    videos: []
  })

  const steps = [
    {
      key: 'uploadDocument',
      title: 'Upload',
      icon: 'file-text',
      component: UploadDocument
    },
    {
      key: 'voiceover',
      title: 'Voiceover',
      icon: 'sound',
      component: Voiceover
    },
    {
      key: 'characterSelection',
      title: 'Character',
      icon: 'user',
      component: CharacterSelection
    },
    {
      key: 'videoStyle',
      title: 'Video Style',
      icon: 'camera',
      component: VideoStyle
    },
    {
      key: 'shotList',
      title: 'Shot List',
      icon: 'ordered-list',
      component: ShotList
    },
    {
      key: 'imageGeneration',
      title: 'Images',
      icon: 'picture',
      component: ImageGeneration
    },
    {
      key: 'videoGeneration',
      title: 'Video',
      icon: 'video-camera',
      component: VideoGeneration
    }
  ]

  const handleNext = () => {
    setCurrentStep(prev => Math.min(prev + 1, steps.length - 1))
  }

  const handlePrevious = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0))
  }

  const updateStepValidation = (key, isValid) => {
    setStepsValidation(prev => ({
      ...prev,
      [key]: isValid
    }))
  }

  const renderStepContent = () => {
    const { key, component: StepComponent } = steps[currentStep]
    return StepComponent ? (
      <StepComponent
        icon={steps[currentStep].icon}
        formData={formData}
        setFormData={setFormData}
        onValidationChange={isValid => updateStepValidation(key, isValid)}
      />
    ) : null
  }

  const getPreviousNextStepTitles = () => {
    const previousStepIndex = Math.max(currentStep - 1, 0)
    const nextStepIndex = Math.min(currentStep + 1, steps.length - 1)

    return {
      previousStepTitle: steps[previousStepIndex].title,
      nextStepTitle: steps[nextStepIndex].title
    }
  }

  return (
    <div className="video-generation">
      <div className="video-generation__header">
        <div className="video-generation__title">
          <h1>Create Video</h1>
          <p>Follow the steps below to generate your video</p>
        </div>
        <NewHeader />
      </div>

      <div className="video-generation__container">
        <Steps
          current={currentStep}
          labelPlacement="vertical"
          className="video-generation__steps"
        >
          {steps.map((step, index) => (
            <Step
              key={step.key}
              title={step.title}
              icon={
                <div
                  className={`step-icon ${
                    currentStep === index
                      ? 'current'
                      : currentStep > index
                      ? 'completed'
                      : ''
                  }`}
                >
                  <Icon type={step.icon} />
                </div>
              }
            />
          ))}
        </Steps>

        <div className="video-generation__content">{renderStepContent()}</div>
        <div className="video-generation__navigation">
          {currentStep > 0 && (
            <Button
              size="large"
              onClick={handlePrevious}
              className="nav-button"
            >
              <Icon type="left" />
              {getPreviousNextStepTitles().previousStepTitle}
            </Button>
          )}
          <Button
            type="primary"
            size="large"
            onClick={handleNext}
            disabled={
              currentStep === steps.length - 1 ||
              !stepsValidation[steps[currentStep].key]
            }
            className="nav-button"
          >
            Next: {getPreviousNextStepTitles().nextStepTitle}
            <Icon type="right" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default VideoAi
