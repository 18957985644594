import React, { Component, Fragment } from 'react'
import {
  CustomSelect,
  HeaderTabs,
  CustomDatePicker,
  CustomTabPane,
  PaginationRightAligned,
  ReportTable,
  ReportSelect
} from '../styled'
import { isSafari } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import {
  Col,
  Row,
  Drawer,
  Spin,
  Button,
  Select,
  Pagination,
  Divider,
  Icon,
  Avatar,
  Skeleton,
  message
} from 'antd'
import _ from 'lodash'
import { generateCSV, rangeArray as range } from '../../../utils'
import { userImage } from '../images'
import './style.css'
import moment from 'moment'
import { Spinner, Search } from '../../../components'
import { Tabs } from 'antd/lib/index'
import { api } from '../../../services'
import {
  axisDate,
  axisShowData,
  formatByDate
} from '../components/SortedTimeline'
import ReportHeader from '../components/ReportHeader'
import OverviewChart from '../components/OverviewChart'
import OverviewGraph from '../components/OverviewGraph'
import CourseIcon from '../../../assets/course_report_icon.png'
import EventIcon from '../../../assets/event_report_icon.png'
import WebinarIcon from '../../../assets/webinar_icon.png'
import CoachingIcon from '../../../assets/coaching.png'
import PublicConferenceIcon from '../../../assets/pubilc_conference.png'
import MazeIcon from '../../../assets/maze_report_icon.png'
import SkillJourneyIcon from '../../../assets/skilljourney_report_icon.png'
import ShareSphereIcon from '../../../assets/sharesphere_report_icon.png'
import LiveAssessmentsIcon from '../../../assets/live_assessments_report_icon.png'
import HostIcon from '../../../assets/host.png'
import ParticipantsIcon from '../../../assets/participants.png'
import { report } from '../../../constants'
import ActivityCounter from './components/ActivityCounter'
import history from '../../../history'
import ActivityMarketPlaceCounter from './components/ActivityMarketPlaceCounter'
import OverviewMarketPlaceChart from '../components/OverviewChart/OverviewMarketplaceChart'
import OverviewMarketGraph from '../components/OverviewGraph/OverviewMarketGraph'
import UserReportTable from './components/UserReportTable'
import ReportCountItem from './components/ReportCountItem'

const antIcon = <Icon type="loading" style={{ fontSize: 26 }} spin />

const { TabPane } = Tabs
const { Option } = CustomSelect

const timeConvert = num => {
  let hours = Math.floor(num / 60)
  let minutes = num % 60
  return hours + ':' + minutes
}

const workShopType = {
  today: '1',
  week: '7',
  month: '30',
  year: '12',
  allTime: '5',
  other: '2'
}

const countDays = date => {
  if (!date) return 0

  const today = new Date()
  const givenDate = new Date(date)

  // Check if the date is valid
  if (isNaN(givenDate.getTime())) return 0

  // Reset hours to midnight to get accurate day difference
  today.setHours(0, 0, 0, 0)
  givenDate.setHours(0, 0, 0, 0)

  const diffTime = Math.abs(today - givenDate)
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

  return diffDays
}

const participantsColumn = t => [
  {
    title: t('reports:participant'),
    dataIndex: 'avatar',
    render: avatar => (
      <Avatar
        className="user-image"
        src={avatar.resizes.thumbnail}
        alt="logo"
        size="large"
      />
    ),
    width: 110
  },
  {
    title: t('reports:username'),
    dataIndex: 'username'
  },
  {
    title: t('v4:group'),
    dataIndex: 'groups',
    render: groups => {
      if (groups.length === 0) return 'N/A'
      return (
        <>
          {groups.map(group => {
            return (
              <span>
                {group}
                <br />
              </span>
            )
          })}
        </>
      )
    }
  },
  {
    title: t('reports:course'),
    dataIndex: 'course'
  },
  {
    title: t('reports:live_event'),
    dataIndex: 'live_event'
  },
  {
    title: t('v4:maze'),
    dataIndex: 'mazes'
  },
  {
    title: t('reports:quiz'),
    dataIndex: 'quizzes'
  },
  {
    title: t('reports:live_assessment'),
    dataIndex: 'live_assessment'
  },
  {
    title: t('v4:skill_journey'),
    dataIndex: 'skill_journey'
  }
]

const HostColumn = t => [
  {
    title: t('reports:host'),
    dataIndex: 'avatar',
    render: avatar => (
      <Avatar
        className="user-image"
        src={avatar.resizes.thumbnail}
        alt="logo"
        size="large"
      />
    ),
    width: 70
  },
  {
    title: t('reports:username'),
    dataIndex: 'username'
  },
  {
    title: t('v4:group'),
    dataIndex: 'groups',
    render: groups => {
      if (groups.length === 0) return 'N/A'
      return (
        <>
          {groups.map(group => {
            return (
              <span>
                {group}
                <br />
              </span>
            )
          })}
        </>
      )
    }
  },
  {
    title: t('reports:course'),
    dataIndex: 'course'
  },
  {
    title: t('reports:live_event'),
    dataIndex: 'live_event'
  },
  {
    title: t('v4:maze'),
    dataIndex: 'mazes'
  },
  {
    title: t('reports:quiz'),
    dataIndex: 'quizzes'
  },
  {
    title: t('reports:live_assessment'),
    dataIndex: 'live_assessments'
  },
  {
    title: t('v4:skill_journey'),
    dataIndex: 'skill_journey'
  },
  {
    title: t('reports:time'),
    dataIndex: 'time',
    render: time => {
      if (time === undefined) {
        return '- -'
      }
      return timeConvert(time)
    }
  }
]

class Report extends Component {
  state = {
    data: [],
    loading: true,
    activeFilterValue: 5,
    visible: false,
    disAbleTabPane: false,
    defaultActiveKey: '0',
    activeTabId: '0',
    startValue: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'month'),
    endValue: moment(new Date(), 'YYYY-MM-DD'),
    downloadType: '1',
    activites: [],
    TabName: 'WorkShop',
    search: '',
    searchType: 'search',
    hostSearch: '',
    hostSearchType: 'search',
    participantSearch: '',
    participantSearchType: 'search',
    userType: 'participant',
    totalParticipants: 0,
    groups: [],
    enableCustomDateSelect: false,
    startDate: '',
    endDate: '',
    workshopDateType: 'allTime',
    page: 1,
    pageSize: 10,
    dateFrom: '',
    dateTo: '',
    dateFilterValue: '5',

    downloadingReport: false,

    marketData: [],
    totalMarketProduct: 0,
    totalMarketHour: 0,
    totalMarketUser: 0,

    totalMarketCourse: 0,
    totalMarketCoaching: 0,
    totalMarketPublicConference: 0,
    totalMarketWebiner: 0,
    totalMarketChannels: 0,
    totalMarketMaze: 0,

    totalMarketCourseBuyers: 0,
    totalMarketCoachingBuyers: 0,
    totalMarketPublicConferenceBuyers: 0,
    totalMarketChannelsBuyers: 0,
    totalMarketWebinerBuyers: 0,
    totalMarketMazeBuyers: 0,

    courseMarketData: [],
    coachingMarketData: [],
    webinarMarketData: [],
    liveAssessmentMarketData: [],
    mazesMarketData: [],
    publicConferenceMarketData: [],
    totalMarketUnit: 0,
    totalMarketCourseUnit: 0,
    totalMarketCoachingUnit: 0,
    totalMarketPublicConferenceUnit: 0,
    totalMarketChannelsUnit: 0,
    totalMarketWebinerUnit: 0,
    totalMarketMazeUnit: 0,
    loadingMerket: false,

    allCourseMarketData: [],
    allCoachingMarketData: [],
    allWebinarMarketData: [],
    allChannelMarketData: [],
    allMazesMarketData: [],
    allPublicConferenceMarketData: [],

    reportDownloadProgress: 0
  }

  handleChange(value) {
    const activeFilterValue = value === '1' ? 24 : Number(value)

    this.setState({ activeFilterValue })
  }

  showDrawer = () => {
    this.setState({
      visible: true
    })
  }

  onClose = () => {
    this.setState({
      visible: false
    })
  }

  onChange = (field, value) => {
    this.setState({
      [field]: value,
      search: '',
      searchType: 'search',
      hostSearch: '',
      // hostSearchType: 'search',
      participantSearch: ''
      // participantSearchType: 'search'
    })
  }
  onStartChange = value => {
    this.onChange('startValue', value)
  }

  onEndChange = value => {
    this.onChange('endValue', value)
  }
  changeDownloadType = value => {
    this.onChange('downloadType', value)
  }

  handleDate = (val, type) => {
    if (type === 'from') {
      this.setState({ dateFrom: val })
    } else {
      this.setState({ dateTo: val })
    }
  }

  handleSearch = () => {
    // this.setState(
    //   {
    //     // workshopDateType: null
    //   },
    //   () =>
    this.getReport()
    // )
  }

  download = async () => {
    const {
      workshop: {
        list: {
          events,
          users,
          skill_journeys,
          trivia_quiz_categories,
          trivia_user_quiz_attempts_categories,
          live_assessments,
          courses: workshopCourses
        }
      }
    } = this.props.data

    let dataCSV = []
    let header = []

    const overviewData = workshopCourses.total.concat(
      events.total,
      skill_journeys.total,
      live_assessments.total,
      trivia_quiz_categories.total
    )

    if (this.state.TabName === 'marketplace') {
      if (this.state.downloadType === '1') {
        let dataArray = []
        header = [
          '#',
          'Title',
          'Created at',
          'Updated at',
          'Buyers',
          'Unit Amount'
        ]
        dataArray = [
          ...(this.state.allCourseMarketData || []),
          ...(this.state.allWebinarMarketData || []),
          ...(this.state.allCoachingMarketData || []),
          ...(this.state.allMazesMarketData || []),
          ...(this.state.allChannelMarketData || []),
          ...(this.state.allPublicConferenceMarketData || [])
        ]
        dataCSV = dataArray.map(x => ({
          id: x.id,
          title: x.title,
          created_at: moment(x.created_at).format('YYYY-MM-DD'),
          updated_at: moment(x.updated_at).format('YYYY-MM-DD'),
          buyers: x.buyers,
          unit_amount: x.unit_amount
        }))
      } else if (this.state.downloadType === '2') {
        const coursesArray = this.state.allCourseMarketData || []
        dataCSV = coursesArray.map(x => ({
          id: x.id,
          title: x.title,
          created_at: moment(x.created_at).format('YYYY-MM-DD'),
          updated_at: moment(x.updated_at).format('YYYY-MM-DD'),
          buyers: x.buyers,
          unit_amount: x.unit_amount
        }))
        header = [
          '#',
          'Title',
          'Created at',
          'Update at',
          'Buyers',
          'Unit Amount'
        ]
      } else if (this.state.downloadType === '3') {
        const webinarArray = this.state.allWebinarMarketData || []
        dataCSV = webinarArray.map(x => ({
          id: x.id,
          title: x.title,
          created_at: moment(x.created_at).format('YYYY-MM-DD'),
          updated_at: moment(x.updated_at).format('YYYY-MM-DD'),
          buyers: x.buyers,
          unit_amount: x.unit_amount
        }))
        header = [
          '#',
          'Title',
          'Created at',
          'Update at',
          'Buyers',
          'Unit Amount'
        ]
      } else if (this.state.downloadType === '4') {
        const coachingArray = this.state.allCoachingMarketData || []
        dataCSV = coachingArray.map(x => ({
          id: x.id,
          title: x.title,
          created_at: moment(x.created_at).format('YYYY-MM-DD'),
          updated_at: moment(x.updated_at).format('YYYY-MM-DD'),
          buyers: x.buyers,
          unit_amount: x.unit_amount
        }))
        header = [
          '#',
          'Title',
          'Created at',
          'Update at',
          'Buyers',
          'Unit Amount'
        ]
      } else if (this.state.downloadType === '5') {
        const mazeArray = this.state.allMazesMarketData || []
        dataCSV = mazeArray.map(x => ({
          id: x.id,
          title: x.title,
          created_at: moment(x.created_at).format('YYYY-MM-DD'),
          updated_at: moment(x.updated_at).format('YYYY-MM-DD'),
          buyers: x.buyers,
          unit_amount: x.unit_amount
        }))
        header = [
          '#',
          'Title',
          'Created at',
          'Update at',
          'Buyers',
          'Unit Amount'
        ]
      } else if (this.state.downloadType === '6') {
        const channelArray = this.state.allChannelMarketData || []
        dataCSV = channelArray.map(x => ({
          id: x.id,
          title: x.title,
          created_at: moment(x.created_at).format('YYYY-MM-DD'),
          updated_at: moment(x.updated_at).format('YYYY-MM-DD'),
          buyers: x.buyers,
          unit_amount: x.unit_amount
        }))
        header = [
          '#',
          'Title',
          'Created at',
          'Update at',
          'Buyers',
          'Unit Amount'
        ]
      } else if (this.state.downloadType === '7') {
        const publicConArray = this.state.allPublicConferenceMarketData || []
        dataCSV = publicConArray.map(x => ({
          id: x.id,
          title: x.title,
          created_at: moment(x.created_at).format('YYYY-MM-DD'),
          updated_at: moment(x.updated_at).format('YYYY-MM-DD'),
          buyers: x.buyers,
          unit_amount: x.unit_amount
        }))
        header = [
          '#',
          'Title',
          'Created at',
          'Update at',
          'Buyers',
          'Unit Amount'
        ]
      }
    } else {
      if (this.state.downloadType === '1') {
        dataCSV = overviewData
          .filter(x =>
            moment(x.updated_at).isBetween(
              this.state.startValue,
              this.state.endValue.add(1, 'days')
            )
          )
          .map(x => {
            return {
              id: x.host_employee_id ? x.host_employee_id : '',
              type: x.label
                ? x.label
                : x.hasOwnProperty('encoder')
                ? 'Event'
                : 'Course',
              title: x.title,
              duration: `${moment
                .duration(x.spending_time)
                .asMinutes()
                .toFixed(2)} minutes`,
              participants: x.participants,
              date: moment(x.updated_at).format('YYYY-MM-DD')
            }
          })
          .filter(x => {
            return x !== null
          })
        header = [
          'Employee Id',
          'Type',
          'Title',
          'Duration',
          'Participants',
          'Date'
        ]
      } else if (this.state.downloadType === '9') {
        header = [
          'Category Name',
          'Success Attempts',
          'Failed Attempts',
          'Total Attempts'
        ]
        dataCSV = trivia_user_quiz_attempts_categories.total.map(x => {
          return {
            category: x.name,
            success: x.successAttempts,
            failed: x.failedAttempts,
            total: x.totalAttempts
          }
        })
      } else if (this.state.downloadType === '5') {
        dataCSV = users.total
          .filter(x =>
            moment(x.updated_at).isBetween(
              this.state.startValue,
              this.state.endValue.add(1, 'days')
            )
          )
          .filter(x => x.username !== null)
          .map(x => {
            return {
              id: x.id,
              type: x.label,
              username: x.username,
              participants: x.participants,
              date: moment(x.updated_at).format('YYYY-MM-DD')
            }
          })
          .filter(x => {
            return x !== null
          })
        header = ['Id', 'Type', 'User Name', 'Participants', 'Date']
      } else if (this.state.downloadType === '2') {
        await this.props.getCourseWorkshopReport({
          from: moment(this.state.startValue).format('YYYY-MM-DD'),
          to: moment(this.state.endValue).format('YYYY-MM-DD')
        })
        const { workshopCourses: courses } = this.props.data

        courses &&
          courses.forEach(x => {
            dataCSV.push({
              title: x.title,
              username: '',
              employee_id: '',
              date: moment(x.created_at).format('YYYY-MM-DD'),
              lessons: x.lessons,
              quizzes: x.quizzes,
              score: '',
              is_pass: '',
              total_pass: `${x.pass_percent.toFixed(2)} %`
            })
            if (x.usersData.length) {
              x.usersData
                .filter(item => item.user !== null)
                .forEach(item => {
                  dataCSV.push({
                    title: '',
                    username: item.user,
                    employee_id: item.employee_id,
                    date: '',
                    lessons: '',
                    quizzes: '',
                    score: item.avg_scores ? item.avg_scores.toFixed() : 0,
                    is_pass: item.pass === 'true' ? 'Passed' : 'Not Passed',
                    total_pass: '',
                    time_spent: item.spending_time
                      ? `${moment
                          .duration(item.spending_time)
                          .asMinutes()
                          .toFixed(2)} minutes`
                      : 'N/A'
                  })
                })
            }
          })

        header = [
          'Title',
          'Username',
          'Employee Id',
          'Created Date',
          'Lessons',
          'Quizzes',
          'Score',
          'Pass Status',
          'Total Pass Percentage',
          'Time spent in course'
        ]
      } else if (this.state.downloadType === '6') {
        let data = []
        data = skill_journeys.total
        dataCSV = data
          .filter(x =>
            moment(x.updated_at).isBetween(
              this.state.startValue,
              this.state.endValue.add(1, 'days')
            )
          )
          .map(x => {
            return {
              title: x.title,
              description: x.description,
              pass_score: x.pass_score,
              date: moment(x.created_at).format('YYYY-MM-DD'),
              participants: x.participants,
              courses: x.courses_ids && x.courses_ids.length,
              events: x.events_ids && x.events_ids.length,
              mazes: x.mazes_ids && x.mazes_ids.length,
              scorms: x.scorms_ids && x.scorms_ids.length
            }
          })
          .filter(x => {
            return x !== null
          })
        header = [
          'Title',
          'Description',
          'Pass Score',
          'Created Date',
          'Participants',
          'Courses',
          'Events',
          'Mazes',
          'Scorms'
        ]
      } else if (this.state.downloadType === '3') {
        await this.props.getEventWorkshopReport({
          from: moment(this.state.startValue).format('YYYY-MM-DD'),
          to: moment(this.state.endValue).format('YYYY-MM-DD')
        })
        const {
          workshopEvents: { list }
        } = this.props.data

        list &&
          list.forEach(x => {
            var eventData = []
            const pollData = this.getPollData(x.polls)
            eventData.push({
              title: x.title,
              username: '',
              employee_id: '',
              duration: `${this.getTimeStamp(
                x.start_time
              )} - ${this.getTimeStamp(x.stop_time)}`,
              date: moment(x.updated_at).format('YYYY-MM-DD'),
              join_leave: '',
              watched_time: '',
              emptyCol: ''
            })
            if (x.user_data.length) {
              x.user_data
                .filter(item => item.user_name !== null)
                .forEach(item => {
                  eventData.push({
                    title: '',
                    username: item.user_name,
                    employee_id: item.employee_id,
                    duration: '',
                    date: '',
                    join_leave: `${this.getTimeStamp(
                      item.join_time
                    )} - ${this.getTimeStamp(item.leave_time)}`,
                    watched_time: this.getDuration(
                      item.join_time,
                      item.leave_time
                    ),
                    emptyCol: ''
                  })
                })
            }

            var emptyEventData = {
              title: '',
              username: '',
              employee_id: '',
              duration: '',
              date: '',
              join_leave: '',
              watched_time: '',
              emptyCol: ''
            }

            if (pollData.length) {
              for (let i = 0; i >= 0; i++) {
                pollData.forEach(item => {
                  eventData[i] = {
                    ...(eventData[i] || emptyEventData),
                    emptyCol: '',
                    question: item.question
                  }
                  i++
                  const answersArray = item.answers
                  if (answersArray) {
                    answersArray.forEach(el => {
                      const isVotes = el.answer && item.totalVotes
                      eventData[i] = {
                        ...(eventData[i] || emptyEventData),
                        question: '',
                        answer: el.answer,
                        votes: isVotes
                          ? `${((el.votes / item.totalVotes) * 100)
                              .toFixed(2)
                              .replace(/[.,]00$/, '')} %`
                          : el.answer
                          ? '0 %'
                          : ''
                      }
                      i++
                    })
                  }
                })
                break
              }
            }
            dataCSV.push(...eventData)
          })

        header = [
          'Live Event Title',
          'Username',
          'Employee id',
          'Start/End live',
          'Live Date',
          'Join/Leave',
          'Watched time',
          '',
          'Poll Question',
          'Poll Answers',
          'Vote'
        ]
      } else if (this.state.downloadType === '8') {
        await this.props.getUserTimeSpentReport({
          from: moment(this.state.startValue).format('YYYY-MM-DD'),
          to: moment(this.state.endValue).format('YYYY-MM-DD')
        })
        const {
          userTimeSpentData: { list }
        } = this.props.data
        dataCSV =
          list &&
          list
            .filter(x => x.user_name !== '')
            .map(x => {
              return {
                name: x.user_name,
                id: x.employee_id,
                event_time: `${x.time_spent_event.toFixed(2)} minutes`,
                course_time: `${(
                  x.course_time_participating /
                  1000 /
                  60
                ).toFixed(2)} minutes`,
                total: `${(
                  x.time_spent_event +
                  x.course_time_participating / 1000 / 60
                ).toFixed(2)} minutes`
              }
            })
            .filter(x => {
              return x !== null
            })
        header = [
          'Username',
          'Employee Id',
          'Live Event Participating',
          'Course Time Participanting',
          'Total time spent'
        ]
      } else {
        let data = []
        if (this.state.downloadType === '4') data = live_assessments.total
        dataCSV = data
          .filter(x =>
            moment(x.updated_at).isBetween(
              this.state.startValue,
              this.state.endValue.add(1, 'days')
            )
          )
          .map(x => {
            return {
              id: x.id,
              title: x.title,
              description: x.description,
              participants: x.participants,
              pass_score: x.pass_score,
              duration: x.duration,
              date: moment(x.star_date_time).format('YYYY-MM-DD')
            }
          })
          .filter(x => {
            return x !== null
          })
        header = [
          '#',
          'Title',
          'Description',
          'Participants',
          'Pass Score',
          'Duration',
          'Date'
        ]
      }
    }

    if (!isSafari) {
      // generateCSV(dataCSV, header)
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute(
        'download',
        this.state.TabName === 'marketplace'
          ? 'marketplace-report.csv'
          : 'work-report.csv'
      )
      document.body.appendChild(ele)
      ele.click()
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute(
        'download',
        this.state.TabName === 'marketplace'
          ? 'marketplace-report.csv'
          : 'work-report.csv'
      )
      ele.click()
    }
  }

  getTimeStamp = str => {
    if (!str) return 'N/A'
    return moment(str).format('hh:mm a')
  }

  getDuration = (from, to) => {
    if (!from || !to) return 'N/A'
    var start = moment(from)
    var end = moment(to)
    var duration = moment.duration(end.diff(start))
    return `${duration.asMinutes().toFixed(2)} minutes`
  }

  getPollData = (data = []) => {
    const pollData = data.map(item => {
      var answerArr = item.answers.map((answer, index) => ({
        answer: answer,
        votes:
          item.votes && item.votes.hasOwnProperty(index) ? item.votes[index] : 0
      }))

      const totalVotes = answerArr.reduce(
        (total, item) => total + item.votes,
        0
      )

      return {
        question: item.question,
        answers: answerArr,
        totalVotes
      }
    })
    return pollData
  }

  buildContentTab(tab = []) {
    const { activeFilterValue } = this.state
    const dates = axisDate(Number(activeFilterValue))
    let data =
      tab &&
      tab
        .map(i => ({
          date: formatByDate(i.updated_at, Number(activeFilterValue))
        }))
        .filter(i => dates && dates.includes(i.date))
        .reduce(
          (acc, item) => {
            const index = dates.findIndex(i => i === item.date)
            acc[index] += 1
            return acc
          },
          range(Number(activeFilterValue)).map(() => 0)
        )

    return data
  }

  buildRightSidebar(activities = []) {
    const { loading, TabName } = this.state
    const { t } = this.props
    let arr = []

    if (activities && activities.length > 0) {
      arr = activities.map(i => {
        return !!i
      })
    }
    const toShow = arr.includes(true)
    return (
      <Fragment>
        <ul className="report-view-item left-short-margin">
          <li>
            {t('reports:recent')} {t(`reports:${TabName}`)}
          </li>
        </ul>

        {loading && TabName === 'Activity' && (
          <div className="media-report-loading">
            <Spin />
          </div>
        )}
        {!toShow && !loading && TabName === 'Activity' && (
          <div>{t('reports:no_recent_activity')}</div>
        )}
        {toShow &&
          (!loading ||
            TabName === 'WorkShop' ||
            TabName === 'Courses' ||
            TabName === 'Live Events' ||
            TabName === 'Live Assessments' ||
            TabName === 'Maze' ||
            TabName === 'Skill journeys' ||
            TabName === 'Users') && (
            <div>
              {activities &&
                activities
                  .filter(
                    activity => activity && (activity.label || activity.title)
                  )
                  .map(activity => {
                    const hasAvatar = activity
                      ? activity.avatar && activity.avatar.link
                        ? activity.avatar.link
                        : activity.cover && activity.cover.link
                        ? activity.cover.link
                        : false
                      : false
                    const activityDate = countDays(activity && activity.date)
                    const updatedActivityDate = countDays(
                      activity && activity.updated_at
                    )

                    return (
                      <div className="media-report">
                        <Avatar
                          className="user-image"
                          src={hasAvatar || userImage}
                          alt="logo"
                          size="large"
                        />

                        <div>
                          {activity && (
                            <h4>
                              {activity.username ||
                                activity.organizer ||
                                'Activity'}
                            </h4>
                          )}
                          {activity && (
                            <h3>
                              {activity.label || activity.title || TabName}
                            </h3>
                          )}

                          <span className="time">
                            {TabName === 'Activity' && !loading
                              ? activityDate === 0
                                ? 'today'
                                : `${activityDate} days ago`
                              : updatedActivityDate === 0
                              ? 'today'
                              : `${updatedActivityDate} days ago`}
                          </span>
                        </div>
                      </div>
                    )
                  })}
            </div>
          )}
      </Fragment>
    )
  }

  onClickStatsButton = data => {
    this.setState(
      {
        userType: data === report.HOST_USER ? 'host' : 'participant',
        page: report.PAGE_NUMBER,
        pageSize: report.PAGE_SIZE,
        activeTabId: data === report.HOST_USER ? '8' : '7'
      },
      () => {
        const {
          page,
          pageSize,
          dateFrom,
          dateTo,
          workshopDateType
        } = this.state
        switch (data) {
          case 'hostUser':
            this.setState({
              loading: true,
              disAbleTabPane: true,
              userType: 'host'
            })
            return api.reports
              .getUserReports({
                userType: 'host',
                limit: pageSize,
                offset: this.createOffset(page, pageSize),
                date: workshopDateType === 'other' ? '' : workshopDateType,
                ...(dateFrom &&
                  dateTo && {
                    from: dateFrom,
                    to: dateTo
                  })
              })
              .then(res => {
                this.setState(
                  {
                    data: res.data,
                    loading: false,
                    disAbleTabPane: false
                  },
                  () => {
                    return this.onChange('activites', res.data.activites)
                  }
                )
              })
          case 'participantUser':
            this.setState({
              loading: true
            })
            return api.reports
              .getUserReports({
                userType: 'participant',
                limit: pageSize,
                offset: this.createOffset(page, pageSize),
                date: workshopDateType === 'other' ? '' : workshopDateType,
                ...(dateFrom &&
                  dateTo && {
                    from: dateFrom,
                    to: dateTo
                  })
              })
              .then(res => {
                this.setState(
                  {
                    data: res.data,
                    loading: false
                  },
                  () => {
                    return this.onChange('activites', res.data.activites)
                  }
                )
              })
          default:
        }
      }
    )
  }

  changeTab = (data, params) => {
    const { workshopDateType, dateFrom, dateTo, userType } = this.state

    const {
      list: { events, courses, users, skill_journeys, live_assessments }
    } = this.props.data.workshop

    const { list: mazes = {} } = this.props.data.reportMazes
    this.setState(
      {
        activeTabId: data,
        page: params && params.type === 'on_pagination_change' ? params.page : 1
      },
      () => {
        const { page, pageSize, hostSearch, participantSearch } = this.state

        if (hostSearch || participantSearch) {
          this.setState({ loading: true }, () => {
            this.fetchData()
          })
          return
        }

        switch (data) {
          case '0':
            this.onChange('TabName', 'WorkShop')
            break
          case '1':
            this.onChange('TabName', 'WorkShop')
            break
          case '2':
            this.onChange('TabName', 'Courses')
            return this.onChange('activites', courses.activites)
          case '3':
            this.onChange('TabName', 'Live Events')
            return this.onChange('activites', events.activites)
          case '4':
            this.onChange('TabName', 'Live Assessments')
            return this.onChange('activites', live_assessments.activites)
          case '5':
            this.onChange('TabName', 'Users')
            return this.onChange('activites', users.activites)
          case '6':
            this.onChange('TabName', 'Skill Journeys')
            return this.onChange('activites', skill_journeys.activites)
          case '7':
            this.onChange('TabName', 'Activity')
            this.setState({
              loading: true,
              disAbleTabPane: true,
              userType: 'participant'
            })
            return api.reports
              .getUserReports({
                userType: 'participant',
                limit: pageSize,
                offset: this.createOffset(page, pageSize),
                date: workshopDateType === 'other' ? '' : workshopDateType,
                ...(dateFrom &&
                  dateTo && {
                    from: dateFrom,
                    to: dateTo
                  })
              })
              .then(res => {
                this.setState(
                  {
                    data: res.data,
                    loading: false,
                    disAbleTabPane: false
                  },
                  () => {
                    return this.onChange(
                      'activites',
                      res.data && res.data.activites
                    )
                  }
                )
              })

          case '8':
            this.onChange('TabName', 'Activity')
            this.setState({
              loading: true,
              userType: userType
            })
            return api.reports
              .getUserReports({
                userType: userType,
                limit: pageSize,
                offset: this.createOffset(page, pageSize),
                date: workshopDateType === 'other' ? '' : workshopDateType,
                ...(dateFrom &&
                  dateTo && {
                    from: dateFrom,
                    to: dateTo
                  })
              })
              .then(res => {
                this.setState(
                  {
                    data: res.data,
                    loading: false
                  },
                  () => {
                    return this.onChange(
                      'activites',
                      res.data && res.data.activites
                    )
                  }
                )
              })
          case '9':
            this.onChange('TabName', 'marketplace')

            let totalCourseBuyers = 0
            let totalCoachingBuyers = 0
            let totalPublicConferenceBuyers = 0
            let totalChannelBuyers = 0
            let totalWebinerBuyers = 0
            let totalMazeBuyers = 0

            let totalMarketCourseUnit = 0
            let totalMarketCoachingUnit = 0
            let totalMarketPublicConferenceUnit = 0
            let totalMarketChannelUnit = 0
            let totalMarketWebinerUnit = 0
            let totalMarketMazeUnit = 0

            this.setState({
              loading: true,
              disAbleTabPane: true,
              userType: 'workshop'
            })
            return api.reports
              .getMarketplaceReport({
                userType: 'workshop',
                limit: pageSize,
                offset: this.createOffset(page, pageSize),
                date: workshopDateType,
                ...(dateFrom &&
                  dateTo && {
                    from: dateFrom,
                    to: dateTo
                  })
              })
              .then(res => {
                for (const buyersData of res.data.courses) {
                  totalCourseBuyers += buyersData.buyers
                }
                for (const buyersData of res.data.webinar) {
                  totalWebinerBuyers += buyersData.buyers
                }
                for (const buyersData of res.data.coaching_session) {
                  totalCoachingBuyers += buyersData.buyers
                }
                for (const buyersData of res.data.public_conference) {
                  totalPublicConferenceBuyers += buyersData.buyers
                }
                for (const buyersData of res.data.channels) {
                  totalChannelBuyers += buyersData.buyers
                }
                for (const buyersData of res.data.mazes) {
                  totalMazeBuyers += buyersData.buyers
                }

                // for unit_amount

                for (const unitAmountData of res.data.courses) {
                  totalMarketCourseUnit += unitAmountData.unit_amount
                }
                for (const unitAmountData of res.data.webinar) {
                  totalMarketWebinerUnit += unitAmountData.unit_amount
                }
                for (const unitAmountData of res.data.coaching_session) {
                  totalMarketCoachingUnit += unitAmountData.unit_amount
                }
                for (const unitAmountData of res.data.public_conference) {
                  totalMarketPublicConferenceUnit += unitAmountData.unit_amount
                }
                for (const unitAmountData of res.data.channels) {
                  totalMarketChannelUnit += unitAmountData.unit_amount
                }
                for (const unitAmountData of res.data.mazes) {
                  totalMarketMazeUnit += unitAmountData.unit_amount
                }

                this.setState({
                  totalMarketCourse: res.data.courses.length,
                  totalMarketCoaching: res.data.coaching_session.length,
                  totalMarketPublicConference:
                    res.data.public_conference.length,
                  totalMarketChannels: res.data.channels.length,
                  totalMarketWebiner: res.data.webinar.length,
                  totalMarketMaze: res.data.mazes.length,
                  totalMarketProduct:
                    res.data.courses.length +
                    res.data.coaching_session.length +
                    res.data.public_conference.length +
                    res.data.channels.length +
                    res.data.webinar.length +
                    res.data.mazes.length,
                  totalMarketHour: 0,
                  totalMarketCourseBuyers: totalCourseBuyers,
                  totalMarketCoachingBuyers: totalCoachingBuyers,
                  totalMarketWebinerBuyers: totalWebinerBuyers,
                  totalMarketPublicConferenceBuyers: totalPublicConferenceBuyers,
                  totalMarketMazeBuyers: totalMazeBuyers,
                  totalMarketChannelsBuyers: totalChannelBuyers,
                  totalMarketUser:
                    totalCourseBuyers +
                    totalCoachingBuyers +
                    totalWebinerBuyers +
                    totalPublicConferenceBuyers +
                    totalMazeBuyers +
                    totalChannelBuyers,

                  courseMarketData:
                    res.data.courses.length > 0
                      ? res.data.courses.map(buy => buy.buyers)
                      : [],
                  coachingMarketData:
                    res.data.coaching_session.length > 0
                      ? res.data.coaching_session.map(buy => buy.buyers)
                      : [],
                  webinarMarketData:
                    res.data.webinar.length > 0
                      ? res.data.webinar.map(buy => buy.buyers)
                      : [],
                  channelMarketData:
                    res.data.channels.length > 0
                      ? res.data.channels.map(buy => buy.buyers)
                      : [],
                  mazesMarketData:
                    res.data.mazes.length > 0
                      ? res.data.mazes.map(buy => buy.buyers)
                      : [],
                  publicConferenceMarketData:
                    res.data.public_conference.length > 0
                      ? res.data.public_conference.map(buy => buy.buyers)
                      : [],
                  totalMarketCourseUnit: totalMarketCourseUnit,
                  totalMarketCoachingUnit: totalMarketCoachingUnit,
                  totalMarketPublicConferenceUnit: totalMarketPublicConferenceUnit,
                  totalMarketChannelsUnit: totalMarketChannelUnit,
                  totalMarketWebinerUnit: totalMarketWebinerUnit,
                  totalMarketMazeUnit: totalMarketMazeUnit,
                  totalMarketUnit:
                    totalMarketCourseUnit +
                    totalMarketWebinerUnit +
                    totalMarketCoachingUnit +
                    totalMarketPublicConferenceUnit +
                    totalMarketChannelUnit +
                    totalMarketMazeUnit,
                  loadingMerket: true,

                  allCourseMarketData: res.data.courses || [],
                  allCoachingMarketData: res.data.coaching_session || [],
                  allWebinarMarketData: res.data.webinar || [],
                  allChannelMarketData: res.data.channels || [],
                  allMazesMarketData: res.data.mazes || [],
                  allPublicConferenceMarketData:
                    res.data.public_conference || []
                })
              })
              .catch(e =>
                this.setState({
                  loadingMerket: true
                })
              )

          case '10':
            this.onChange('TabName', 'Maze')
            return this.onChange('activites', mazes && mazes.activites)
          default:
            break
        }
      }
    )
  }

  converterToK(number) {
    if (typeof number === 'number' && !isNaN(number)) {
      return parseFloat(number)
    } else {
      return 0
    }
  }

  fetchData = () => {
    const {
      userType,
      page,
      pageSize,
      workshopDateType,
      dateFrom,
      dateTo
    } = this.state
    const searchValue =
      this.state.userType === 'host'
        ? this.state.hostSearch
        : this.state.participantSearch
    const searchType =
      this.state.userType === 'host'
        ? this.state.hostSearchType
        : this.state.participantSearchType

    if (searchValue) {
      return api.reports
        .searchUserData({
          userType,
          searchType,
          searchValue,
          limit: pageSize,
          offset: this.createOffset(page, pageSize),
          date: workshopDateType === 'other' ? '' : workshopDateType,
          ...(dateFrom &&
            dateTo && {
              from: dateFrom,
              to: dateTo
            })
        })
        .then(res => {
          this.setState({
            data: res.data,
            loading: false
          })
        })
    } else {
      this.onChangePagination(1)
    }
  }

  fetchGroups = () => {
    const {
      user: {
        info: { active_organization_id }
      }
    } = this.props
    api.organizations.get().then(res => {
      const activeOrg = res.data.rows.filter(
        item => item.id === active_organization_id
      )
      if (activeOrg && activeOrg[0]) {
        activeOrg[0].groups.push({ id: 'N/A', title: 'N/A' })
        this.setState({
          groups: activeOrg[0].groups
        })
      }
    })
  }

  handleSearchType = type => {
    if (
      type === 'group_name' ||
      this.state.hostSearchType === 'searchByGroup' ||
      this.state.participantSearchType === 'searchByGroup'
    ) {
      // Resetting both search states
      this.setState({
        hostSearch: '',
        participantSearch: ''
      })
    }

    let newState = {}
    if (this.state.userType === 'host') {
      newState.hostSearchType =
        type === 'name'
          ? 'searchByname'
          : type === 'id'
          ? 'searchById'
          : type === 'email'
          ? 'searchByemail'
          : type === 'group_name'
          ? 'searchByGroup'
          : 'search'
    } else {
      newState.participantSearchType =
        type === 'name'
          ? 'searchByname'
          : type === 'id'
          ? 'searchById'
          : type === 'email'
          ? 'searchByemail'
          : type === 'group_name'
          ? 'searchByGroup'
          : 'search'
    }
    this.setState(newState, () => {
      this.fetchData()
    })
  }

  handleGroupSearch = value => {
    const searchType =
      this.state.hostSearchType === 'searchByGroup'
        ? 'hostSearch'
        : this.state.participantSearchType === 'searchByGroup'
        ? 'participantSearch'
        : null
    this.onSearch(searchType, value)
  }

  onSearch = _.debounce(
    (type, value) =>
      this.setState(
        {
          [type]: value,
          page: 1,
          loading: true
        },
        () => {
          this.fetchData(true)
        }
      ),
    1000
  )
  componentDidMount() {
    const {
      user: {
        isFetchingUserAttributes,
        info: { active_organization_id }
      }
    } = this.props
    if (!isFetchingUserAttributes && !active_organization_id) {
      this.checkActiveUser()
      return
    }
    this.getReport()
    this.fetchGroups()
    if (history.location.state === 'user') {
      this.changeTab('8')
      this.setState({ defaultActiveKey: '8' })
      history.replace('/reports')
    } else if (history.location.state === 'marketplace') {
      this.changeTab('9')
      this.setState({ defaultActiveKey: '9' })
      history.replace('/reports')
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.checkActiveUser()
    }
  }

  checkActiveUser() {
    const { user } = this.props
    if (!user.isFetchingUserAttributes) {
      if (!user.info.active_organization_id) {
        const userId = user.info.id
        history.push(`/reports/user/participant/${userId}`)
      }
    }
  }

  getReport = params => {
    const { workshopDateType, dateFrom, dateTo } = this.state

    const {
      user: {
        info: { organization }
      }
    } = this.props
    this.props.getReport({
      type: 'workshop',
      offset: 0,
      limit: 6,
      ...{ date: workshopDateType === 'other' ? '' : workshopDateType },
      ...{ from: dateFrom },
      ...{ to: dateTo },
      ...(params && params.date && { date: params.date }),
      ...(params && params.from && { from: params.from }),
      ...(params && params.to && { to: params.to })
    })
    organization &&
      organization.enable_maze &&
      this.props.getMazeReport({
        mode: 'report',
        offset: 0,
        limit: '',
        searchByTitle: '',
        date:
          (params && params.from && params.to
            ? 'other'
            : params && params.date) || '',
        ...(params && params.from && { from: params.from }),
        ...(params && params.to && { to: params.to })
      })
  }

  xAxisData = x => {
    return range(x)
      .map(i =>
        moment()
          .subtract(i, 'months')
          .format('MMM')
      )
      .reverse()
  }

  downloadUserReport = async category => {
    const {
      userType,
      workshopDateType,
      dateFrom,
      dateTo,
      hostSearch,
      participantSearch,
      hostSearchType,
      participantSearchType
    } = this.state

    const searchValue = userType === 'host' ? hostSearch : participantSearch
    const searchType =
      userType === 'host' ? hostSearchType : participantSearchType

    this.setState({ downloadingReport: true })

    try {
      if (userType === 'host') {
        // Keep host logic unchanged since it doesn't have the payload size issue
        const response = await this.fetchReportDataPaginated({
          userType,
          searchType,
          searchValue,
          workshopDateType,
          dateFrom,
          dateTo
        })
        const reportData = response.data && response.data.users
        const { data: dataCSV, headers } = this.getReportDataForHost(reportData)
        this.downloadCSV(dataCSV, headers)
      } else {
        // New paginated logic for participants
        await this.downloadParticipantReport(category, {
          searchType,
          searchValue,
          workshopDateType,
          dateFrom,
          dateTo
        })
      }
    } catch (error) {
      console.error('Error downloading report:', error)
    } finally {
      this.setState({ downloadingReport: false, reportDownloadProgress: 0 })
    }
  }

  getReportDataForHost = reportData => {
    let headers = [
      'Employee ID',
      'Name',
      'Email',
      'Course',
      'Mazes',
      'Quizzes',
      'Skill Journey',
      'Live Events',
      'Live Assessments'
    ]

    let data = reportData.map(user => ({
      'Employee ID': user.employee_id || '',
      Name:
        user.first_name && user.last_name
          ? `${user.first_name} ${user.last_name}`
          : user.username,
      Email: user.email || '',
      Course: user.course || 0,
      Mazes: user.mazes || 0,
      Quizzes: user.quizzes || 0,
      'Skill Journey': user.skill_journey || 0,
      'Live Events': user.live_event || 0,
      'Live Assessments': user.live_assessments || 0
    }))

    return { data, headers }
  }

  downloadParticipantReport = async (category, params) => {
    const pageSize = 10
    let allData = []
    let headers = []

    try {
      // Make first request and use it for both initial data and total count
      const firstPageResponse = await this.fetchReportDataPaginated({
        ...params,
        userType: 'participant',
        category,
        page: 1,
        pageSize
      })

      if (!firstPageResponse || !firstPageResponse.data) {
        throw new Error('Failed to fetch initial report data')
      }

      const total = firstPageResponse.data.total
      const totalPages = Math.ceil(total / pageSize)

      // Process first page data
      const firstPageUsers = firstPageResponse.data.users || []
      if (firstPageUsers.length > 0) {
        const {
          data: firstPageData,
          headers: pageHeaders
        } = this.getReportDataForParticipant(firstPageUsers, category)
        headers = pageHeaders
        allData = firstPageData
      }

      // Update progress for first page
      this.setState({
        reportDownloadProgress: Math.round((1 / totalPages) * 100)
      })

      // Continue with remaining pages starting from page 2
      for (let currentPage = 2; currentPage <= totalPages; currentPage++) {
        try {
          const response = await this.fetchReportDataPaginated({
            ...params,
            userType: 'participant',
            category,
            page: currentPage,
            pageSize
          })

          if (!response || !response.data) {
            console.warn(`Failed to fetch data for page ${currentPage}`)
            continue
          }

          const pageUsers = response.data.users || []
          if (pageUsers.length > 0) {
            const { data: pageData } = this.getReportDataForParticipant(
              pageUsers,
              category
            )
            allData = [...allData, ...pageData]
          }

          // Update progress
          const progress = Math.round((currentPage / totalPages) * 100)
          this.setState({
            reportDownloadProgress: progress
          })
        } catch (pageError) {
          console.error(`Error fetching page ${currentPage}:`, pageError)
          // Optionally, you could break the loop or continue depending on your requirements
          // break;
        }
      }

      // Download complete CSV once all data is collected
      if (allData.length > 0) {
        this.downloadCSV(allData, headers)
      } else {
        // Handle case where no data was retrieved
        this.setState({
          reportDownloadProgress: 0
        })
        // Optionally show a user-friendly message
        console.warn('No data available for download')
      }
    } catch (error) {
      // Handle top-level errors
      console.error('Error in downloadParticipantReport:', error)

      // Reset progress
      this.setState({
        reportDownloadProgress: 0
      })

      // Optionally show error to user
      // this.showErrorNotification('Failed to download report')
    }
  }

  fetchReportDataPaginated = async ({
    userType,
    searchType,
    searchValue,
    workshopDateType,
    dateFrom,
    dateTo,
    category,
    page,
    pageSize
  }) => {
    const { data } = this.state
    const limit =
      userType === 'host'
        ? (data && data.users && data.users.length > 0 && data.total) || 0
        : userType === 'participant'
        ? pageSize
        : 0
    const offset = userType === 'host' ? 0 : (page - 1) * pageSize
    const baseParams = {
      userType,
      limit: limit,
      offset: offset,
      date: workshopDateType === 'other' ? '' : workshopDateType,
      from: dateFrom,
      to: dateTo,
      category
    }

    if (searchValue) {
      return api.reports.searchUserData({
        ...baseParams,
        searchType,
        searchValue
      })
    }

    return api.reports.getUserReports(baseParams)
  }

  getReportDataForParticipant = (reportData, category) => {
    // Base headers that are always included
    const baseHeaders = ['Employee ID', 'Name', 'Email']

    // Mapping configuration
    const mapping = {
      course: {
        'Department/Group': user => user.groups & user.groups.join(', '),
        'Enrolled Course': 'course_enroll',
        'Course In Progress': 'course_in_progres',
        'Completed Courses': 'completed_courses',
        'Failed Courses': 'failed_courses',
        'Passed Courses': 'passed_courses'
      },
      maze: {
        'Total Mazes': user => user.mazes && user.mazes.length,
        'Total Mazes Played': 'maze_played'
      },
      event: {
        'Total Live Events': 'live_event',
        'Event Viewed': user => (user.event_view ? user.event_view.length : 0)
      },
      live_assessment: {
        'Total Live Assessments': user =>
          (user.live_assessment && user.live_assessment.length) || 0,
        'Assessments Viewed': 'assessment_view'
      },
      quiz: {
        'Total Quiz': user => (user.quizzes && user.quizzes.length) || 0,
        'Total Quiz Attempted': 'submit_quiz'
      },
      trivia: {
        'Total Sharesphere Success': user =>
          user.trivia_quiz_attempt ? user.trivia_quiz_attempt.total_success : 0,
        'Total Sharesphere Quizzes': user =>
          user.trivia_quiz_attempt ? user.trivia_quiz_attempt.total_quizzes : 0,
        'ShareSphere Success Rate': user =>
          user.trivia_quiz_attempt ? user.trivia_quiz_attempt.success_rate : 0
      },
      skill: {
        'Skill Journey': user =>
          (user.skill_journey && user.skill_journey.length) || 0,
        'Skill Journey Enroll': 'skill_journey_enroll'
      }
    }

    const categoryMapping = mapping[category]

    if (!categoryMapping) {
      return { data: [], headers: baseHeaders }
    }

    // Get category-specific headers
    const categoryHeaders = Object.keys(categoryMapping)
    const headers = [...baseHeaders, ...categoryHeaders]

    // Process data
    const data = reportData.map(user => {
      // Build base user data
      const baseData = {
        'Employee ID': user.employee_id || '',
        Name:
          user.first_name && user.last_name
            ? `${user.first_name} ${user.last_name}`
            : user.username,
        Email: user.email || ''
      }

      // Build category-specific data
      const categoryData = Object.fromEntries(
        Object.entries(categoryMapping).map(([header, keyOrFn]) => [
          header,
          typeof keyOrFn === 'function' ? keyOrFn(user) : user[keyOrFn] || 0
        ])
      )

      return { ...baseData, ...categoryData }
    })

    return { data, headers }
  }

  // Helper method to download CSV - modified to handle grouped headers
  downloadCSV = (dataCSV, header) => {
    const csvContent = generateCSV(dataCSV, header)
    const encodedCsv = encodeURIComponent(csvContent)
    const downloadUrl = `data:text/csv;charset=utf-8,${encodedCsv}`

    const link = document.createElement('a')
    link.setAttribute('href', downloadUrl)
    link.setAttribute('download', 'user-report.csv')

    if (!isSafari) {
      document.body.appendChild(link)
    }

    link.click()

    if (!isSafari) {
      document.body.removeChild(link)
    }
  }

  onDataSelect = val => {
    const workshopDateType = Object.keys(workShopType).filter(function(key) {
      return workShopType[key] === val
    })[0]
    if (val === '2') {
      this.setState({
        workshopDateType,
        dateFilterValue: '2'
      })
    } else {
      this.setState(
        {
          workshopDateType,
          activeFilterValue: val,
          dateFrom: '',
          dateTo: '',
          dateFilterValue: val
        },
        () => {
          this.getReport({
            date: val === '2' ? '' : workshopDateType
          })
        }
      )
    }
  }

  onUserDateSelect = val => {
    const { page, pageSize, userType, activeTabId } = this.state
    const workshopDateType = Object.keys(workShopType).filter(function(key) {
      return workShopType[key] === val
    })[0]

    if (val === '2') {
      this.setState({
        workshopDateType,
        dateFilterValue: '2'
      })
    } else if (activeTabId === '9') {
      this.setState(
        {
          workshopDateType,
          dateFilterValue: val,
          activeFilterValue: val
        },
        () => {
          this.changeTab('9')
        }
      )
    } else {
      this.setState(
        {
          workshopDateType,
          activeFilterValue: val,
          dateFrom: '',
          dateTo: '',
          dateFilterValue: val,
          loading: true
        },
        () => {
          api.reports
            .getUserReports({
              userType: userType === 'host' ? 'host' : 'participant',
              limit: pageSize,
              offset: this.createOffset(page, pageSize),
              ...{ date: workshopDateType === 'other' ? '' : workshopDateType },
              ...{ from: '' },
              ...{ to: '' }
            })
            .then(res => {
              this.setState({
                data: res.data,
                loading: false
              })
            })
        }
      )
    }
  }

  onUserDateChange = params => {
    const { page, pageSize, userType, activeTabId } = this.state
    this.setState(
      {
        date: 'other',
        dateFrom: params[0],
        dateTo: params[1],
        loading: true
      },
      () => {
        if (activeTabId === '9') {
          this.changeTab('9')
          return
        }
        api.reports
          .getUserReports({
            userType: userType === 'host' ? 'host' : 'participant',
            limit: pageSize,
            offset: this.createOffset(page, pageSize),
            date: 'other',
            from: params[0],
            to: params[1]
          })
          .then(res => {
            this.setState({
              data: res.data,
              loading: false
            })
          })
      }
    )
  }

  onDateChange = params => {
    this.setState(
      {
        date: '',
        dateFrom: params[0],
        dateTo: params[1]
      },
      () => {
        this.getReport({
          date: '',
          from: params[0],
          to: params[1]
        })
      }
    )
  }

  onChangePagination = page => {
    this.changeTab(this.state.activeTabId, {
      page,
      type: 'on_pagination_change'
    })
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  getTotalTime = array =>
    moment
      .duration(
        array &&
          array.total &&
          array.total.reduce((acc, item) => acc + item.spending_time, 0),
        'seconds'
      )
      .asMinutes()

  getTotalParticipants = arr =>
    Number(
      arr.total && arr.total.reduce((acc, item) => acc + item.participants, 0)
    )

  getTotalTimeSpentHumanized = arr => {
    let time = moment
      .duration(
        arr.total &&
          arr.total.reduce((acc, item) => acc + item.spending_time, 0),
        'seconds'
      )
      .humanize()
    return time === 'Invalid date' ? '' : time
  }
  render() {
    const {
      startValue,
      endValue,
      activites,
      TabName,
      data,
      loading,
      hostSearchType,
      participantSearchType,
      groups,
      workshopDateType,
      page,
      pageSize,
      dateFrom,
      dateTo,
      activeFilterValue,
      dateFilterValue,
      userType,
      activeTabId,
      defaultActiveKey,
      downloadingReport,
      reportDownloadProgress
    } = this.state
    const {
      data: {
        workshop: {
          fetch,
          list: {
            events = [],
            courses = [],
            skill_journeys = [],
            live_assessments = [],
            trivia_quiz_categories = { total: [], participants: 0 },
            trivia_user_quiz_attempts_categories = { total: [] }
          }
        },
        CourseAnalytic: { fetch: courseFetching },
        userTimeSpentData: { fetch: timeFetching },
        workshopEvents: { fetch: eventsFetching },
        reportMazes: { fetch: mazeFetching, list: mazes }
      },
      t,
      user: {
        isFetchingUserAttributes,
        info: { organization }
      }
    } = this.props

    const total_participants = (mazes && mazes.total_participants) || 0
    const total_time_spent_in_min =
      (mazes && mazes.total_time_spent_in_min) || 0

    const dates = axisDate(Number(activeFilterValue))

    const showDates = axisShowData(Number(activeFilterValue))

    if (courses.total && courses.total.length > 0) {
      var courseGraphData = this.buildContentTab(courses.total)
    }

    if (events.total && events.total.length > 0) {
      var eventGraphData = this.buildContentTab(events.total)
    }

    if (mazes.total && mazes.total.length > 0) {
      var mazesData = this.buildContentTab(mazes.total)
    }

    if (skill_journeys.total && skill_journeys.total.length > 0) {
      var skillJourneysData = this.buildContentTab(skill_journeys.total)
    }

    if (live_assessments.total && live_assessments.total.length > 0) {
      var liveAssessmentsData = this.buildContentTab(live_assessments.total)
    }

    const lang = localStorage.getItem('i18nextLng')

    const reportLoading = fetch || mazeFetching

    const totalActivity =
      Number(courses.total && courses.total.length) +
      Number(events.total && events.total.length) +
      (organization && organization.enable_maze
        ? Number(mazes.total && mazes.total.length)
        : 0) +
      Number(skill_journeys.total && skill_journeys.total.length) +
      Number(live_assessments.total && live_assessments.total.length)

    const spendingTime =
      this.getTotalTime(courses) +
      this.getTotalTime(events) +
      this.getTotalTime(skill_journeys) +
      this.getTotalTime(live_assessments)

    const spendingCount =
      Number(spendingTime) + Number(total_time_spent_in_min) * 60 // all time in seconds

    const totalUsers = Number(data && data.total)

    const courseEventParticipants =
      this.getTotalParticipants(courses) +
      this.getTotalParticipants(events) +
      this.getTotalParticipants(skill_journeys) +
      this.getTotalParticipants(live_assessments)

    const totalParticipants =
      courseEventParticipants +
      total_participants +
      trivia_quiz_categories.participants

    const searchParams = {
      searchType: workshopDateType,
      dateFrom: dateFrom,
      dateTo: dateTo
    }

    const isParticipantUserReport =
      activeTabId === '7' || userType === 'participant'
    return (
      <Fragment>
        {isFetchingUserAttributes || fetch || mazeFetching ? (
          <Spinner height="90vh" />
        ) : (
          <div className="content-warp">
            <div
              className="report-warp sharelook-table"
              style={{ direction: 'ltr' }}
            >
              <HeaderTabs
                defaultActiveKey={defaultActiveKey}
                animated={false}
                onChange={this.changeTab}
                tabBarExtraContent={
                  <ReportHeader
                    workshopDateType={dateFilterValue}
                    dateChange={
                      activeTabId === '8' || activeTabId === '9'
                        ? this.onUserDateSelect
                        : this.onDataSelect
                    }
                    handleDate={(moment, str) =>
                      activeTabId === '8' || activeTabId === '9'
                        ? this.onUserDateChange(str)
                        : this.onDateChange(str)
                    }
                    backToDashboard={() => this.props.history.push('/')}
                    dateTo={dateTo}
                    dateFrom={dateFrom}
                    isSpecificItem={isParticipantUserReport}
                    download={
                      TabName === 'Activity'
                        ? this.downloadUserReport
                        : this.showDrawer
                    }
                    downloading={downloadingReport}
                    progress={downloadingReport ? reportDownloadProgress : 0}
                  />
                }
              >
                <TabPane tab={t('reports:workshop_report')} key="0">
                  <CustomTabPane>
                    <div
                      className="report-view-wrap"
                      style={{ marginBottom: '3rem' }}
                    >
                      <Col lg={7} sm={24} md={24}>
                        <ul className="report-view-item left-short-margin">
                          <li>{t('reports:overview').toUpperCase()}</li>
                        </ul>
                        <div className="report-overview">
                          {reportLoading && <Spinner />}
                          {!reportLoading && (
                            <>
                              <div className="overview-item">
                                <span>{totalActivity}</span>
                                <h3 className="overview-item-title">
                                  {t('reports:Activity').toUpperCase()}
                                </h3>
                              </div>
                              <div className="overview-item">
                                <span>{totalParticipants}</span>
                                <h3 className="overview-item-title">
                                  {t('reports:participants').toUpperCase()}
                                </h3>
                              </div>
                              <div className="overview-item">
                                <span>
                                  {spendingCount
                                    ? (
                                        spendingCount / totalParticipants
                                      ).toFixed(0)
                                    : 0}
                                </span>
                                <h2>{t('labels:minutes').toLowerCase()}</h2>
                                <h3 className="overview-item-title-average">
                                  {t('v2:average_spending_time').toUpperCase()}{' '}
                                </h3>
                              </div>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col lg={17} sm={24} md={24}>
                        <ul className="report-view-item left-margin">
                          <li>
                            {t('reports:organization_workshop').toUpperCase()}
                          </li>
                        </ul>
                        <Row
                          type="flex"
                          justify="space-around"
                          style={{ paddingLeft: '30px' }}
                          gutter={[16, 16]}
                        >
                          {/* Course */}
                          <ActivityCounter
                            route={'/reports/course/'}
                            searchParams={searchParams}
                            totalCount={_(courses.total).size()}
                            type={t('reports:course')}
                            participants={this.getTotalParticipants(courses)}
                            timeSpent={this.getTotalTimeSpentHumanized(courses)}
                            icon={CourseIcon}
                          />

                          {/* Events */}
                          <ActivityCounter
                            route={'/reports/events/'}
                            searchParams={searchParams}
                            totalCount={_(events.total).size()}
                            type={t('reports:event')}
                            participants={this.getTotalParticipants(events)}
                            timeSpent={this.getTotalTimeSpentHumanized(events)}
                            icon={EventIcon}
                          />

                          {/* Metamaze */}
                          {organization && organization.enable_maze && (
                            <ActivityCounter
                              route={'/reports/maze/'}
                              searchParams={searchParams}
                              totalCount={_(mazes.total).size()}
                              type={t('v4:maze')}
                              participants={mazes.total_participants || 0}
                              timeSpent={this.getTotalTimeSpentHumanized(mazes)}
                              icon={MazeIcon}
                            />
                          )}

                          {/* Live Assessment */}
                          <ActivityCounter
                            route={'/reports/live-assessments/'}
                            searchParams={searchParams}
                            totalCount={_(live_assessments.total).size()}
                            type={t('reports:live_assessments')}
                            participants={this.getTotalParticipants(
                              live_assessments
                            )}
                            timeSpent={this.getTotalTimeSpentHumanized(
                              live_assessments
                            )}
                            icon={LiveAssessmentsIcon}
                          />

                          {/* Skill Journey */}
                          <ActivityCounter
                            route={'/reports/skill-journey/'}
                            searchParams={searchParams}
                            totalCount={_(skill_journeys.total).size()}
                            type={t('v4:skill_journey')}
                            participants={this.getTotalParticipants(
                              skill_journeys
                            )}
                            timeSpent={this.getTotalTimeSpentHumanized(
                              skill_journeys
                            )}
                            icon={SkillJourneyIcon}
                          />

                          {/* SharSphere Journey */}
                          <ActivityCounter
                            route={'/reports/sharesphere/'}
                            searchParams={searchParams}
                            totalCount={_(trivia_quiz_categories.total).size()}
                            type={t('v3:share_sphere')}
                            participants={trivia_quiz_categories.participants}
                            timeSpent={this.getTotalTimeSpentHumanized(
                              trivia_quiz_categories
                            )}
                            icon={ShareSphereIcon}
                          />
                        </Row>
                      </Col>
                    </div>

                    <Row>
                      <div className="report-view-wrap">
                        <Col lg={7} sm={24}>
                          <ul className="report-view-item left-short-margin">
                            <li>{t('v2:activity_overview').toUpperCase()}</li>
                          </ul>
                          <OverviewChart
                            graphData={{
                              course: courses.total && courses.total.length,
                              events: events.total && events.total.length,
                              maze: mazes.total && mazes.total.length,
                              assessments:
                                live_assessments.total &&
                                live_assessments.total.length,
                              // users: users.total && users.total.length,
                              skillJourneys:
                                skill_journeys.total &&
                                skill_journeys.total.length
                              //  liveAssessments: live_assessments.total && live_assessments.length
                            }}
                            enableMaze={
                              organization && organization.enable_maze
                            }
                          />
                        </Col>
                        <Col lg={17} sm={24}>
                          <ul className="report-view-item left-margin">
                            <li>{t('reports:workShop_graph').toUpperCase()}</li>
                          </ul>
                          <OverviewGraph
                            monthXAxis={
                              activeFilterValue === 24 ? showDates : dates
                            }
                            eventsData={eventGraphData}
                            courseData={courseGraphData}
                            liveAssessmentData={liveAssessmentsData}
                            mazesData={mazesData}
                            // usersData={usersData}
                            skillJourneysData={skillJourneysData}
                            enableMaze={
                              organization && organization.enable_maze
                            }
                          />
                        </Col>
                      </div>
                    </Row>
                  </CustomTabPane>
                </TabPane>
                <TabPane
                  className="sharelook-user-report"
                  tab={t('reports:user_report')}
                  key="8"
                  style={{
                    direction:
                      lang === 'hebrew' || lang === 'arabic' ? 'rtl' : 'ltr'
                  }}
                >
                  <Row>
                    <Col span={8}>
                      <Row>
                        <ul className="report-view-item left-short-margin">
                          <li>{t('reports:overview').toUpperCase()}</li>
                        </ul>
                        <Row gutter={32}>
                          <ReportCountItem
                            loading={loading}
                            totalUsers={totalUsers}
                            onClick={() =>
                              this.onClickStatsButton(report.HOST_USER)
                            }
                            iconSrc={HostIcon}
                            title={t('reports:host')}
                            isBluishBackground={userType === 'host'}
                          />
                          <ReportCountItem
                            loading={loading}
                            totalUsers={totalUsers}
                            onClick={() =>
                              this.onClickStatsButton(report.PARTICIPANT_USER)
                            }
                            iconSrc={ParticipantsIcon}
                            title={t('reports:participants')}
                            isBluishBackground={userType !== 'host'}
                          />
                        </Row>
                      </Row>
                      <Row style={{ marginTop: '32px' }}>
                        {this.buildRightSidebar(
                          TabName === 'Activity' && !loading
                            ? data && data.activities
                            : activites
                        )}
                      </Row>
                    </Col>

                    <Col span={16}>
                      <UserReportTable
                        userType={this.state.userType}
                        title={
                          this.state.userType === 'participant'
                            ? t('reports:participants')
                            : t('reports:host')
                        }
                        columns={
                          this.state.userType === 'participant'
                            ? participantsColumn(t)
                            : HostColumn(t)
                        }
                        dataSource={data && data.users}
                        loading={loading}
                        totalUsers={totalUsers}
                        page={page}
                        pageSize={pageSize}
                        onChangePagination={this.onChangePagination}
                        onSearch={this.onSearch}
                        handleSearchType={this.handleSearchType}
                        handleGroupSearch={this.handleGroupSearch}
                        groups={groups}
                        history={this.props.history}
                        hostSearchType={hostSearchType}
                        participantSearchType={participantSearchType}
                      />
                    </Col>
                  </Row>
                </TabPane>
                {/*----------------------- market place ------------ */}
                <TabPane
                  className="sharelok-marketplace-report"
                  tab={t('v4:marketplace_report')}
                  key="9"
                  style={{
                    direction:
                      lang === 'hebrew' || lang === 'arabic' ? 'rtl' : 'ltr'
                  }}
                >
                  {!this.state.loadingMerket && (
                    <div className="market-loading">
                      <Spinner />
                    </div>
                  )}
                  <CustomTabPane>
                    <div
                      className="report-view-wrap"
                      style={{ marginBottom: '3rem' }}
                    >
                      <Col lg={7} sm={24} md={24}>
                        <ul className="report-view-item left-short-margin">
                          <li>{t('reports:overview').toUpperCase()}</li>
                        </ul>
                        <div className="report-overview">
                          {!reportLoading && (
                            <>
                              <div className="overview-item">
                                <span>
                                  {this.converterToK(
                                    this.state.totalMarketProduct
                                  )}
                                </span>
                                <h3 className="overview-item-title">
                                  {this.converterToK(
                                    this.state.totalMarketProduct
                                  ) > 1
                                    ? t('v4:reports:products').toUpperCase()
                                    : t('reports:product').toUpperCase()}
                                </h3>
                              </div>
                              <div
                                className="overview-item"
                                style={{
                                  paddingLeft: '10px',
                                  paddingRight: '10px'
                                }}
                              >
                                <span>
                                  {this.converterToK(
                                    this.state.totalMarketUnit
                                  ).toFixed(2)}
                                </span>

                                <h3 className="overview-item-title">
                                  {t('v3:marketplace_quantity').toUpperCase()}
                                </h3>
                              </div>
                              <div className="overview-item">
                                <span>
                                  {this.converterToK(
                                    this.state.totalMarketUser
                                  )}
                                </span>
                                <h3 className="overview-item-title">
                                  {this.converterToK(
                                    this.state.totalMarketUser
                                  ) > 1
                                    ? t('v4:reports:users').toUpperCase()
                                    : t('reports:user').toUpperCase()}
                                </h3>
                              </div>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col lg={17} sm={24} md={24}>
                        <ul className="report-view-item left-margin">
                          <li>{t('v4:organization_place').toUpperCase()}</li>
                        </ul>
                        <Row
                          type="flex"
                          justify="space-between"
                          style={{
                            marginLeft: '2rem'
                          }}
                          gutter={[8, 8]}
                        >
                          {/* Course */}
                          <ActivityMarketPlaceCounter
                            route={'/reports/market-place/course/'}
                            searchParams={searchParams}
                            totalCount={this.state.totalMarketCourse}
                            type={t('reports:course')}
                            buyers={this.state.totalMarketCourseBuyers}
                            quantity={this.state.totalMarketCourseUnit.toFixed(
                              2
                            )}
                            icon={CourseIcon}
                          />

                          {/* Webinar */}
                          <ActivityMarketPlaceCounter
                            route={'/reports/market-place/webinar/'}
                            searchParams={searchParams}
                            totalCount={this.state.totalMarketWebiner}
                            type={t('reports:webinar')}
                            buyers={this.state.totalMarketWebinerBuyers}
                            quantity={this.state.totalMarketWebinerUnit.toFixed(
                              2
                            )}
                            icon={WebinarIcon}
                          />

                          {/* Coaching */}
                          <ActivityMarketPlaceCounter
                            route={'/reports/market-place/coaching/'}
                            searchParams={searchParams}
                            totalCount={this.state.totalMarketCoaching}
                            type={t('reports:coaching')}
                            buyers={this.state.totalMarketCoachingBuyers}
                            quantity={this.state.totalMarketCoachingUnit.toFixed(
                              2
                            )}
                            icon={CoachingIcon}
                          />

                          {/* Metamaze */}
                          {organization && organization.enable_maze && (
                            <ActivityMarketPlaceCounter
                              route={'/reports/market-place/maze/'}
                              searchParams={searchParams}
                              totalCount={this.state.totalMarketMaze}
                              type={t('v4:maze')}
                              buyers={this.state.totalMarketMazeBuyers}
                              quantity={this.state.totalMarketMazeUnit.toFixed(
                                2
                              )}
                              icon={MazeIcon}
                            />
                          )}

                          {/* Live Assessment */}
                          <ActivityMarketPlaceCounter
                            route={'/reports/market-place/channel/'}
                            searchParams={searchParams}
                            totalCount={this.state.totalMarketChannels}
                            type={t('v3:channel')}
                            buyers={this.state.totalMarketChannelsBuyers}
                            quantity={this.state.totalMarketChannelsUnit.toFixed(
                              2
                            )}
                            icon={LiveAssessmentsIcon}
                          />

                          {/* Pubilc Conference */}
                          <ActivityMarketPlaceCounter
                            route={'/reports/market-place/public-conference/'}
                            searchParams={searchParams}
                            totalCount={this.state.totalMarketPublicConference}
                            type={t('v3:public_conference')}
                            buyers={
                              this.state.totalMarketPublicConferenceBuyers
                            }
                            quantity={this.state.totalMarketPublicConferenceUnit.toFixed(
                              2
                            )}
                            icon={PublicConferenceIcon}
                          />
                        </Row>
                      </Col>
                    </div>

                    <Row>
                      <div className="report-view-wrap">
                        <Col lg={7} sm={24}>
                          <ul className="report-view-item left-short-margin">
                            <li>{t('v2:activity_overview').toUpperCase()}</li>
                          </ul>
                          <OverviewMarketPlaceChart
                            graphData={{
                              course: this.state.totalMarketCourse,
                              webinar: this.state.totalMarketWebiner,
                              coaching: this.state.totalMarketCoaching,
                              maze: this.state.totalMarketMaze,
                              channels: this.state.totalMarketChannels,
                              publicConference: this.state
                                .totalMarketPublicConference
                            }}
                            enableMaze={
                              organization && organization.enable_maze
                            }
                          />
                        </Col>
                        <Col lg={17} sm={24}>
                          <ul className="report-view-item left-margin">
                            <li>{t('v4:marketPlace_graph').toUpperCase()}</li>
                          </ul>
                          <OverviewMarketGraph
                            monthXAxis={
                              activeFilterValue === 24 ? showDates : dates
                            }
                            courseMarketData={this.state.courseMarketData}
                            webinarMarketData={this.state.webinarMarketData}
                            coachingMarketData={this.state.coachingMarketData}
                            liveAssessmentMarketData={
                              this.state.liveAssessmentMarketData
                            }
                            mazesMarketData={this.state.mazesMarketData}
                            enableMaze={
                              organization && organization.enable_maze
                            }
                            publicConferenceMarketData={
                              this.state.publicConferenceMarketData
                            }
                            buyers={this.state.totalMarketUser}
                            Product={this.state.totalMarketProduct}
                          />
                        </Col>
                      </div>
                    </Row>
                  </CustomTabPane>
                </TabPane>
                {/* ------------------- close market place ---------- */}
              </HeaderTabs>
              <Drawer
                title={t('reports:report')}
                placement="right"
                closable
                onClose={this.onClose}
                visible={this.state.visible}
              >
                <CustomDatePicker
                  format="YYYY-MM-DD"
                  value={startValue}
                  placeholder={t('reports:from_date')}
                  onChange={this.onStartChange}
                />
                <CustomDatePicker
                  format="YYYY-MM-DD"
                  value={endValue}
                  placeholder={t('reports:to_date')}
                  onChange={this.onEndChange}
                />
                {activeTabId === '0' && (
                  <CustomSelect
                    className="report-type-select"
                    onChange={this.changeDownloadType}
                    defaultValue="1"
                  >
                    <Option value="1">{t('reports:all_workshop')}</Option>
                    <Option value="2">{t('reports:course')}</Option>
                    <Option value="3">{t('reports:live_event')}</Option>
                    <Option value="4">{t('reports:live_assessment')}</Option>
                    <Option value="5">{t('reports:users')}</Option>
                    <Option value="6">{t('v4:skill_journey')}</Option>
                    <Option value="7">{t('reports:time')}</Option>
                    <Option value="9">{t('v3:triviaReport')}</Option>
                  </CustomSelect>
                )}
                {activeTabId === '9' && (
                  <CustomSelect
                    className="report-type-select"
                    onChange={this.changeDownloadType}
                    defaultValue="1"
                  >
                    <Option value="1">{t('reports:all_marketplace')}</Option>
                    <Option value="2">{t('reports:course')}</Option>
                    <Option value="3">{t('v3:webinar')}</Option>
                    <Option value="4">{t('v3:coaching')}</Option>
                    <Option value="5">{t('v4:maze')}</Option>
                    <Option value="6">{t('v3:channels')}</Option>
                    <Option value="7">{t('v3:public_conference')}</Option>
                  </CustomSelect>
                )}
                <Button
                  type="primary"
                  style={{ marginTop: '20px' }}
                  loading={courseFetching || timeFetching || eventsFetching}
                  onClick={this.download}
                >
                  {t('reports:download')}
                </Button>
              </Drawer>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
export default withTranslation('report')(Report)
