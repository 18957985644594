import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Input, message, Icon } from 'antd'
import { api } from '../../../../services'
import RoleManagementTable from './RoleManagementTable'
import PermissionManagementDrawer from './PermissionManagementDrawer'
import UserManagementDrawer from './UserManagementDrawer'
import './Roles.scss'

const RolesManagement = ({ form }) => {
  const { getFieldDecorator } = form
  const [roles, setRoles] = useState([])
  const [allPermissions, setAllPermissions] = useState([])
  const [loading, setLoading] = useState(false)

  const [isModalVisible, setModalVisible] = useState(false)
  const [editingRole, setEditingRole] = useState(null)
  const [permissionsModalVisible, setPermissionsModalVisible] = useState(false)
  const [userManagementModalVisible, setUserManagementModalVisible] = useState(
    false
  )
  const [selectedRole, setSelectedRole] = useState(null)

  useEffect(() => {
    fetchRoles()
    fetchAllPermissions()
  }, [])

  const fetchRoles = async () => {
    setLoading(true)
    try {
      const response = await api.roles.getRoles('all')
      setRoles(response.data)
    } catch (error) {
      message.error('Failed to fetch roles')
    } finally {
      setLoading(false)
    }
  }

  const fetchAllPermissions = async () => {
    try {
      const response = await api.roles.getAllPermissions()
      setAllPermissions(response.data.data || [])
    } catch (error) {
      message.error('Failed to load permissions')
    }
  }

  const handleSubmit = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true)
        try {
          if (editingRole) {
            await api.roles.updateRole(editingRole.id, values.roleName)
            message.success('Role updated successfully')
          } else {
            await api.roles.createRole(values.roleName)
            message.success('Role created successfully')
          }
          fetchRoles()
          handleCancel()
        } catch (error) {
          message.error('Failed to process role')
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const handleCancel = () => {
    setModalVisible(false)
    form.resetFields()
    setEditingRole(null)
  }

  const handleOpenPermissionsModal = role => {
    setSelectedRole(role)
    setPermissionsModalVisible(true)
  }

  const handleOpenUserManagementModal = role => {
    setSelectedRole(role)
    setUserManagementModalVisible(true)
  }

  const handleCancelUserManagementModal = () => {
    setUserManagementModalVisible(false)
    setSelectedRole(null)
  }

  const handleCancelPermissionsModal = () => {
    setSelectedRole(null)
    setPermissionsModalVisible(false)
  }

  const handleUpdatePermissions = async (roleId, permissions) => {
    await api.roles.addRolePermissions(roleId, permissions)
    fetchRoles()
    handleCancelPermissionsModal()
  }

  const handleDeleteRole = async role => {
    Modal.confirm({
      title: 'Are you sure you want to delete this role?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk: async () => {
        setLoading(true)
        try {
          await api.roles.deleteRole(role.id)
          message.success('Role deleted successfully')
          fetchRoles()
        } catch (error) {
          message.error('Failed to delete role')
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const handleEditRole = role => {
    setEditingRole(role)
    setModalVisible(true)
  }

  const handleUpdateUsers = async () => {
    handleCancelUserManagementModal()
  }

  const validateRoleName = (rule, value, callback) => {
    if (value.toLowerCase() === 'admin' || value.toLowerCase() === 'user') {
      callback('Role name cannot be admin or user')
    } else {
      callback()
    }
  }

  return (
    <div className="roles-management">
      <div className="roles-management__header">
        <div className="title">
          <h1>
            <Icon type="team" style={{ marginRight: 8 }} /> Roles Management
          </h1>
          <p>Manage user roles and permissions</p>
        </div>
        <Button
          type="primary"
          onClick={() => setModalVisible(true)}
          style={{ marginBottom: 16 }}
        >
          <Icon type="plus" /> Create Role
        </Button>
      </div>

      <Modal
        title={editingRole ? 'Edit Role' : 'Create Role'}
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleSubmit}
        confirmLoading={loading}
        centered
      >
        <Form layout="vertical">
          <Form.Item label="Role Name">
            {getFieldDecorator('roleName', {
              initialValue: editingRole ? editingRole.name : '',
              rules: [
                { required: true, message: 'Please input a role name' },
                {
                  min: 3,
                  message: 'Role name must be at least 3 characters long'
                },
                { validator: validateRoleName }
              ]
            })(<Input placeholder="Enter role name" />)}
          </Form.Item>
        </Form>
      </Modal>

      <RoleManagementTable
        roles={roles}
        loading={loading}
        onEdit={handleEditRole}
        onDelete={handleDeleteRole}
        onManageUsers={handleOpenUserManagementModal}
        onManagePermissions={handleOpenPermissionsModal}
      />

      <PermissionManagementDrawer
        visible={permissionsModalVisible}
        onCancel={handleCancelPermissionsModal}
        role={selectedRole}
        allPermissions={allPermissions}
        onUpdatePermissions={handleUpdatePermissions}
      />

      <UserManagementDrawer
        visible={userManagementModalVisible}
        onCancel={handleCancelUserManagementModal}
        role={selectedRole}
        onUpdateUsers={handleUpdateUsers}
      />
    </div>
  )
}

const WrappedRolesManagement = Form.create()(RolesManagement)

export default WrappedRolesManagement
