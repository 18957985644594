import React, { useEffect, useState } from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import { Course } from '../../styled';
import moment from 'moment';
import { Empty, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseSquareOutlined } from '@ant-design/icons'
const dateFormat = 'D MMM YYYY, HH:mm';

const TrainingSequence = ({ type, loading, priorityLoad, seqList, setNewSequence }) => {
    const { t } = useTranslation();
    const [sequences, setSequences] = useState([]);
    useEffect(() => {
        if (seqList.length > 0) setSequences(seqList)
    }, [seqList])
    const onDrop = (dropResult) => {
        const { removedIndex, addedIndex } = dropResult;

        if (dropResult.payload) {
            if (addedIndex !== null) {
                const indexVal = sequences.findIndex(
                    item => item.id === dropResult.payload.id
                )
                if (indexVal >= 0) {
                    let newSequences = [...sequences];
                    const [removed] = newSequences.splice(indexVal, 1);
                    newSequences.splice(addedIndex, 0, removed);
                    setSequences(newSequences);
                    setNewSequence(newSequences);
                } else {
                    let newSequences = [...sequences, dropResult.payload];
                    const [removed] = newSequences.splice(newSequences.length - 1, 1);
                    newSequences.splice(addedIndex, 0, removed);
                    setSequences(newSequences);
                    setNewSequence(newSequences)
                }

            }
        } else if (dropResult) {
            if (removedIndex !== null && addedIndex !== null) {
                let newSequences = [...sequences];
                const [removed] = newSequences.splice(removedIndex, 1);
                newSequences.splice(addedIndex, 0, removed);
                setSequences(newSequences);
                setNewSequence(newSequences)
            }
        }
        return null
    };

    const removeData = (index) => {
        let newSequences = [...sequences];
        newSequences.splice(index, 1);
        setSequences(newSequences);
        setNewSequence(newSequences)
    }

    return (
        <Spin spinning={priorityLoad}>
            <div className='training-list-sequence'>
                <div className='trainning-title'>
                    <Tooltip
                        placement="topLeft"
                        title={`This ${type} list arrangement will be primary order for the list visible in traning page.`}
                        arrow
                    ><span>{"Sequence Order"}</span></Tooltip>
                </div>
                <Container onDrop={onDrop} groupName="1" className={"training-list-sequence-draggable smooth-dnd-container"}>
                    {sequences.map((data, i) => (
                        <Draggable key={i} draggable={!loading}>
                            <Course>
                                {data.cover && <Course.Cover src={data.cover.resizes ? data.cover.resizes.medium : data.cover.link} >
                                    <Course.Remove>
                                        <CloseSquareOutlined
                                            style={{
                                                color: '#eb5757',
                                                fontSize: 28,
                                                fontWeight: 700
                                            }}
                                            onClick={() => {
                                                removeData(i)
                                            }}
                                        /> </Course.Remove>
                                </Course.Cover>}
                                <Course.Details>
                                    <Course.Details.Top>
                                        <Tooltip title={`${data.title}`}>
                                            <Course.Title>
                                                {(i + 1) + "."}&nbsp;{data.title}
                                            </Course.Title>
                                        </Tooltip>
                                        {data.type === 'Course' && data.course_type !== 'scorm' && (
                                            <Course.Lessons>
                                                {data.lessons_count || 0} {t('general:lessons')}
                                            </Course.Lessons>
                                        )}
                                    </Course.Details.Top>
                                    <Course.Details.Bottom>
                                        <Course.DateCreated>
                                            {t('created')} {moment(data.created_at).format(dateFormat)}
                                        </Course.DateCreated>
                                    </Course.Details.Bottom>
                                </Course.Details>
                            </Course>
                        </Draggable>
                    ))}
                    {sequences.length === 0 &&
                        <Draggable key={1}>
                            {/* <Course> */}
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Empty description={"Sequence Not Set"} />
                            </div>
                            {/* </Course> */}
                        </Draggable>
                    }
                </Container>
            </div>
        </Spin>
    );
}

export default TrainingSequence;
