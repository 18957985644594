import React from 'react'
import { Table, Menu, Dropdown, Icon } from 'antd'

const RoleManagementTable = ({
  roles,
  loading,
  onEdit,
  onDelete,
  onManageUsers,
  onManagePermissions
}) => {
  const menu = role => (
    <Menu className="role-management-menu">
      <Menu.Item key="1" onClick={() => onManagePermissions(role)}>
        <Icon type="lock" /> Manage Permissions
      </Menu.Item>
      <Menu.Item key="2" onClick={() => onManageUsers(role)}>
        <Icon type="user" /> Manage Users
      </Menu.Item>
      <Menu.Item key="3" onClick={() => onEdit(role)}>
        <Icon type="edit" /> Edit
      </Menu.Item>
      <Menu.Item danger key="4" onClick={() => onDelete(role)}>
        <Icon type="delete" /> Delete
      </Menu.Item>
    </Menu>
  )

  const columns = [
    { title: 'Role Name', dataIndex: 'name', key: 'name' },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, role) => (
        <Dropdown
          overlay={menu(role)}
          trigger={['click']}
          placement="bottomRight"
        >
          <Icon type="more" style={{ fontSize: '18px', cursor: 'pointer' }} />
        </Dropdown>
      ),
      align: 'right'
    }
  ]

  return (
    <Table columns={columns} dataSource={roles} loading={loading} rowKey="id" />
  )
}

export default RoleManagementTable
